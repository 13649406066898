import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs/index';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class OptionsService {

  selected_project = null;
  season_subscriver = new Subject<number>();
  selected_season = 0;
  APIurl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  getOption(fd) {
    return this.http.post(environment.baseUrl + '/options/get_option_value.php', fd);
  }

  saveOption(fd) {
     return this.http.post(environment.baseUrl + '/options/set_option_value.php', fd);
  }


}
