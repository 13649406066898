import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SimulatorService {

  constructor(private http: HttpClient) { }

  private templates = [];

  getTemplates(){
    return this.http.get<any[]>(environment.baseUrl + '/templates/get_templates.php');
  }

  saveTemplate(fd){
    return this.http.post(environment.baseUrl + '/templates/create_template.php', fd);
  }
}
