import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  inAnimation = false;
  constructor() { }

  addToastr(message, type, modal = false){
    if(!this.inAnimation){
      this.inAnimation =  true;
      var div = "<div class='toastr "+type+" "+(modal ? 'modal' : '')+"'>"+
                  "<div class='toastr_message'>"+
                    message+
                  "</div>"+
                  "<div class='toastr_icon'>"+
                    "<i class='fas fa-times-circle'></i>"+
                  "</div>"+
                "</div>";
      $("html, body").append(div);
      setTimeout(() => {
        $(".toastr").addClass('show');
          setTimeout(() => {
            $(".toastr").removeClass('show');
            setTimeout(() => {
              $(".toastr").remove();
              this.inAnimation = false;
            }, 750);
          }, 3500);  
      }, 0);
    }
  }
}
