import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { StandingsService } from '../../services/standings.service';
import { ProjectsService } from "../../services/projects.service";
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-standingsmodal',
  templateUrl: './standingsmodal.component.html',
  styleUrls: ['./standingsmodal.component.scss']
})

export class StandingsmodalComponent implements OnInit {

  standingsData = [];
  constructor(
      public dataService: DataService,
      public standingsService: StandingsService,
      public projectsService: ProjectsService,
      public dialogRef: MatDialogRef<StandingsmodalComponent>
              ) { }

  @Input() maxRows: any;
  standingModal:MatDialogRef<StandingsmodalComponent>;

  ngOnInit() {
      let project = this.dataService.getSelectedProject();
      let season_id = project.seasons[0].project_season_id;
    this.standingsService.getTeamStandings(season_id).subscribe(data => {

        let indexOfTeam = data["data"]["standings"].findIndex(x=>x.team_id == this.dataService.getSelectedProject().project_team_id);
        let self = this;

        data["data"]["standings"].forEach(function(value, index){
                value["tablePosition"] = index + 1;
                self.standingsData.push(value);
                self.projectsService.getTeamById(value["team_id"]).subscribe(dataTeam => {
                    //value["teamLogo"] = dataTeam["data"]["img"];

                });

        });
     });
  }
  close(){
    this.dialogRef.close({ action: 'close' });
  }
}
