import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule, MatInputModule, MatTooltipModule, MatOptionModule, MatSelectModule, MatIconModule, MatTabsModule } from '@angular/material'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ProjectComponent } from './components/project/project.component';
import { ConfirmdeleteComponent } from './modals/confirmdelete/confirmdelete.component';
import { ExercisesComponent } from './components/exercises/exercises.component';
import { SquadComponent } from './components/squad/squad.component';
import { AppComponent } from './app.component';
import { SessionComponent } from './components/session/session.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HeadroomModule } from '@ctrl/ngx-headroom';
import { MatVideoModule } from 'mat-video';
import { PartialtrainingComponent } from './modals/partialtraining/partialtraining.component';
import { SimulatorComponent } from './components/simulator/simulator.component';
import { ChooseexerciseComponent } from './modals/chooseexercise/chooseexercise.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ActiondateComponent } from './modals/actiondate/actiondate.component';
import { SessionmodalComponent } from './modals/sessionmodal/sessionmodal.component';
import { GameComponent } from './modals/game/game.component';
import { PlayerComponent } from './modals/player/player.component';
import { TrainingResumeComponent } from './modals/training-resume/training-resume.component';
import { LandingpageComponent } from './components/landingpage/landingpage.component';
import { ProfileComponent } from './components/profile/profile.component';
import { GamesComponent } from './components/games/games.component';
import { AddseasonComponent } from './modals/addseason/addseason.component';
import { MicrocycleComponent } from './modals/microcycle/microcycle.component';
import { LoadComponent } from './modals/load/load.component';
import { KeypointsComponent } from './modals/keypoints/keypoints.component';
import { MicrocyclesComponent } from './components/microcycles/microcycles.component';
import { ChartsModule } from 'ng2-charts';
import { ExerciseComponent } from './modals/exercise/exercise.component';
import { SearchComponent } from './components/search/search.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingComponent } from './components/loading/loading.component';
import { StandingsComponent } from "./components/standings/standings.component";
import { StandingsmodalComponent } from './modals/standingsmodal/standingsmodal.component';
import { OptionsmodalComponent } from './modals/optionsmodal/optionsmodal.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    DashboardComponent,
    NavbarComponent,
    ProjectsComponent,
    ProjectComponent,
    ConfirmdeleteComponent,
    ExercisesComponent,
    SquadComponent,
    SessionComponent,
    PartialtrainingComponent,
    SimulatorComponent,
    ChooseexerciseComponent,
    ClickOutsideDirective,
    ActiondateComponent,
    SessionmodalComponent,
    GameComponent,
    PlayerComponent,
    TrainingResumeComponent,
    LandingpageComponent,
    ProfileComponent,
    GamesComponent,
    AddseasonComponent,
    MicrocycleComponent,
    LoadComponent,
    KeypointsComponent,
    MicrocyclesComponent,
    ExerciseComponent,
    SearchComponent,
    LoadingComponent,
    StandingsComponent,
    StandingsmodalComponent,
    OptionsmodalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatOptionModule, 
    MatSelectModule,
    MatTabsModule, 
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FullCalendarModule,
    MatDialogModule,
    SlickCarouselModule,
    MatTooltipModule,
    HeadroomModule,
    MatVideoModule,
    ChartsModule,
    MatProgressSpinnerModule
  ],
  entryComponents:[
    ConfirmdeleteComponent,
    PartialtrainingComponent,
    ChooseexerciseComponent,
    ActiondateComponent,
    SessionmodalComponent,
    GameComponent,
    PlayerComponent,
    TrainingResumeComponent,
    LoginComponent,
    ProfileComponent,
    AddseasonComponent,
    MicrocycleComponent,
    LoadComponent,
    KeypointsComponent,
    ExerciseComponent,
    StandingsmodalComponent,
    OptionsmodalComponent
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
