import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { environment } from '../../../environments/environment';
import { ToastrService } from '../../services/toastr.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  url = environment.baseUrl;
  userForm: FormGroup;
  credentialsForm: FormGroup;
  user_foto = null;
  user_base_64 = null;
  exercise_foto = null;

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ProfileComponent>,
    public toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.userForm = this.fb.group({
      name: [this.authService.getUser()['nome'], [Validators.required, Validators.minLength(4)]],
    });
    this.credentialsForm = this.fb.group({
      user: [this.authService.getUser()['user'], [Validators.required, Validators.minLength(4)]],
      pass: ['', [Validators.required]],
      pass_conf: ['', [Validators.required]],
    });
  }

  close(){
    this.dialogRef.close({ action: 'close' });
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
    this.user_foto = inputNode.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(this.user_foto); 
    reader.onload = (_event) => { 
      this.user_base_64 = reader.result; 
    }
  }
  
  updateUser(){
    let name = this.name.value;
    if(name.trim() == ""){
      this.toastrService.addToastr("Fill your name", "error");
      return;
    }
    let fd = new FormData();
    fd.append("id", this.authService.getUser()['id']);
    fd.append("name", name);
    if(this.user_foto != null){
      fd.append("user_photo", this.user_foto, this.user_foto.name);
    }
    this.authService.editUser(fd).subscribe(data => {
      if(data == "success"){
        let user = this.authService.getUser();
        user.nome = name;
        if(this.user_foto != null){
          user.foto = this.user_foto.name;
        }
        this.authService.updateUser(user);
        this.toastrService.addToastr("User successfully edited", "success");
      } else {
        this.toastrService.addToastr("Error while editing user", "error");
      }
    });
  }

  updateCredentials(){
    let user = this.user.value;
    let pass = this.pass.value;
    let pass_conf = this.pass_conf.value;
    if(user.trim() == ""){
      this.toastrService.addToastr("Fill your username", "error");
      return;
    }
    if(pass.trim() == ""){
      this.toastrService.addToastr("Fill your old password", "error");
      return;
    }
    if(pass_conf.trim() == ""){
      this.toastrService.addToastr("Fill your new password", "error");
      return;
    }
    let fd = new FormData();
    fd.append("id", this.authService.getUser()['id']);
    fd.append("user", user);
    fd.append("pass", pass);
    fd.append("pass_conf", pass_conf);
    this.authService.editCredentials(fd).subscribe(data => {
      if(data == 'success'){
        let finaluser = this.authService.getUser();
        finaluser.user = user;
        this.authService.updateUser(finaluser);
        this.toastrService.addToastr("Credentials successfully edited", "success");
      } else {
        this.toastrService.addToastr(data['message'], "error");
      return;
      }
    });
  }


  // GETTERS
  get name() {
    return this.userForm.get('name');
  }

  get user() {
    return this.credentialsForm.get('user');
  }
  get pass() {
    return this.credentialsForm.get('pass');
  }
  get pass_conf() {
    return this.credentialsForm.get('pass_conf');
  }
}
