import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ExercisesService } from '../../services/exercises.service';
import { ToastrService } from '../../services/toastr.service';

@Component({
  selector: 'app-keypoints',
  templateUrl: './keypoints.component.html',
  styleUrls: ['./keypoints.component.scss']
})
export class KeypointsComponent implements OnInit {

  circuit = null;
  exercise = null;
  constructor(public dialogRef: MatDialogRef<KeypointsComponent>,
              public toastrService: ToastrService,
              public exercisesService: ExercisesService) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close({ action: 'cancel' });
  }

  saveKeypoints(){
    let fd = new FormData();
    fd.append("c_id", this.circuit.id);
    fd.append("e_id", this.exercise.id);
    fd.append("keypoints", this.exercise.keypoints);
    this.exercisesService.saveKeypoints(fd).subscribe(data=>{
      if(data == "success"){
        this.toastrService.addToastr("Keypoints successfully saved", 'success');
        this.close();
      } else {
        this.toastrService.addToastr("Error while saving exercise's keypoints", 'error');
      }
    });
  }
}
