import { Component, OnInit } from '@angular/core';
import { ExercisesService } from '../../services/exercises.service';
import { MatDialogRef } from '@angular/material';
import { SessionService } from '../../services/session.service';
import { ToastrService } from '../../services/toastr.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-sessionmodal',
  templateUrl: './sessionmodal.component.html',
  styleUrls: ['./sessionmodal.component.scss']
})
export class SessionmodalComponent implements OnInit {

  data = null;
  session = null;
  step = null;
  season_id = null;
  categories;
  selectedCategory = null;
  loadingStep1 = true;
  constructor(public dataService: DataService,
              public exercisesService: ExercisesService,
              public dialogRef: MatDialogRef<SessionmodalComponent>,
              public sessionService: SessionService,
              public toastrService: ToastrService) { }

  ngOnInit() {
    this.getCategories();
  }

  chooseCategory(category){
    this.selectedCategory = category; 
    var fd = new FormData();
    fd.append('data', this.data);
    fd.append('category', this.selectedCategory.id);
    fd.append('project', this.dataService.selected_project.id);
    fd.append('session_id', this.season_id);
    this.sessionService.addPreSession(fd).subscribe(data=>{
      if(data == 'success'){
        this.action("success");
      } else {
        this.toastrService.addToastr("Error while creating session", "error", true);
      }
    });
  }
  
  getCategories(){
    if(this.exercisesService.mainCategories == null){
      this.categories = this.exercisesService.mainCategories;
      this.loadingStep1 = false;
    } else {
      this.exercisesService.getSessionCategories().subscribe(data=> {
        this.categories = data;
        this.loadingStep1 = false;
      });
    }
  }

  action(action){
    this.dialogRef.close({ action: action });
  }

  creation_answer(event){
    if(event.answer == "success"){
      this.action("success");
    }
  }
}
