import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-partialtraining',
  templateUrl: './partialtraining.component.html',
  styleUrls: ['./partialtraining.component.scss']
})
export class PartialtrainingComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PartialtrainingComponent>) { }
  nome = null;
  training_time = "00:00";
  ngOnInit() {
  }

  cancel(){
    this.dialogRef.close({ action: 'cancel' });
  }

  confirm(){
    if(this.training_time.trim() == ""){
      return;
    }
    this.dialogRef.close({ action: 'success', time: this.training_time});
  }

}
