import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DataService } from '../../services/data.service';
import { ExercisesService } from '../../services/exercises.service';
import { ConfirmdeleteComponent } from '../../modals/confirmdelete/confirmdelete.component';
import { ToastrService } from '../../services/toastr.service';
import { AuthService } from '../../services/auth.service';
import { ModalServiceService } from '../../services/modal-service.service';

@Component({
  selector: 'app-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.scss']
})
export class ExerciseComponent implements OnInit {

  constructor(public exercisesService: ExercisesService,
              private fb: FormBuilder,
              public dataService: DataService,
              public authService: AuthService,
              public toastrService: ToastrService,
              public dialogRef: MatDialogRef<ExerciseComponent>,
              public confirmDeleteModalComponent: MatDialog,
              public modalService: ModalServiceService) { }
              
  exercise = null;
  categories = null;
  exerciceForm: FormGroup;
  showtips = false;
  confirmDeleteModal: MatDialogRef<ConfirmdeleteComponent>;
  exercise_foto = null;

  ngOnInit() {
    this.exerciceForm = this.fb.group({
      exercise_name: [this.exercise.name, [Validators.required, Validators.minLength(4)]],
      exercise_category: [this.exercise.main_category_id, [Validators.required]],
      exercise_subcategory: [this.exercise.sub_category_id, [Validators.required]],
      exercise_subssubcategory: [this.exercise.sub_sub_category_name, []],
    });
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
    this.exercise_foto = inputNode.files[0];
  }

  editExercise(){
    let exercise_name = this.exerciceForm.get('exercise_name').value;
    let exercise_category = this.exerciceForm.get('exercise_category').value;
    let exercise_sub_category = this.exerciceForm.get('exercise_subcategory').value;
    let exercise_sub_sub_category = this.exerciceForm.get('exercise_subssubcategory').value;
    if(exercise_name.trim() == ""){
      this.toastrService.addToastr("Fill the exercises's name", "error");
      return;
    }
    if(exercise_category.trim() == ""){
      this.toastrService.addToastr("Fill the exercises's category", "error");
      return;
    }
    if(exercise_sub_category.trim() == ""){
      this.toastrService.addToastr("Fill the exercises's secondary category", "error");
      return;
    }
    let exercise = {
      user_id: this.authService.getUser()['id'],
      exercise_id: this.exercise.id,
      exercise_name: exercise_name, 
      exercise_category: exercise_category, 
      exercise_sub_category: exercise_sub_category, 
      exercise_sub_sub_category: exercise_sub_sub_category
    };

    let fd = new FormData();
    fd.append("exercise", JSON.stringify(exercise));
    if(this.exercise_foto != null){
      fd.append("exercise_photo", this.exercise_foto, this.exercise_foto.name);
    }
    this.exercisesService.editExercise(fd).subscribe(data => {
      if(data == "success"){
        this.dialogRef.close({action:'edit'});
      }
    });
  }

  close(){
    this.dialogRef.close({action:'close'});
  }

  deleteExercise(){
    const exercise = {
      user_id: this.authService.getUser()['id'],
      exercise_id: this.exercise.id,
      foto: this.exercise.photo
    };
    let fd = new FormData();
    fd.append("exercise", JSON.stringify(exercise));
    this.exercisesService.checkExerciseUsage(fd).subscribe((data: any[]) => {
      this.confirmDeleteModal = this.confirmDeleteModalComponent.open(ConfirmdeleteComponent, { disableClose: true });
      let instance = this.confirmDeleteModal.componentInstance;
      let title = "Warning";
      let message = "Are you sure you want to delete this exercise?";
      instance.title = title;
      instance.message = message;
      const sessions = data.length;
      if(sessions > 0){
        instance.submessage = "This exercise is being used in " + sessions + " sessions. If you delete this exercise, all the sessions where this exercise is used will be deleted.";
      } else {
        instance.submessage = "This exercise is not being used in any training session.";
      }
      this.confirmDeleteModal.afterClosed().subscribe((result) => {
        if(result.message == 'success'){
          this.exercisesService.deleteExercise(fd).subscribe(data=>{
            if(data == 'success'){
              this.dialogRef.close({action:'delete'});
            } else {
              this.toastrService.addToastr("Error while deleting exercise", "error");
              return;
            }
          }); 
        }
      });


    });
  }
  
  // GETTERS
  get exercise_name() {
    return this.exerciceForm.get('exercise_name');
  }
  get exercise_category() {
    return this.exerciceForm.get('exercise_category');
  }
  get exercise_subcategory() {
    return this.exerciceForm.get('exercise_subcategory');
  }
  get exercise_subssubcategory() {
    return this.exerciceForm.get('exercise_subssubcategory');
  }
}
