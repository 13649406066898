import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DataService } from '../../services/data.service';
import { SessionService } from '../../services/session.service';
import { SquadService } from '../../services/squad.service';
import { environment } from '../../../environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material';
import { PartialtrainingComponent } from '../../modals/partialtraining/partialtraining.component';
import { SimulatorService } from '../../services/simulator.service';
import { ToastrService } from '../../services/toastr.service';
import { ModalServiceService } from '../../services/modal-service.service';
import { ConfirmdeleteComponent } from '../../modals/confirmdelete/confirmdelete.component';
import { ProjectsService } from '../../services/projects.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit {

  squad = null;
  templates = null;
  session_time = "0";
  session_rpe = "";
  session_goals = [];
  new_goal = "";
  template_selected = null;
  set_manually = false;

  @Input() session: any;
  @Output() answer = new EventEmitter();

  confirmDeleteModal: MatDialogRef<ConfirmdeleteComponent>;

  constructor(public dataService: DataService,
              public dialogRef: MatDialogRef<SessionComponent>,
              public squadService: SquadService,
              public partialTrainingModalComponent: MatDialog,
              public toastrService: ToastrService,
              public simulatorService: SimulatorService,
              public sessionService: SessionService,
              public confirmDeleteModalComponent: MatDialog,
              public modalService: ModalServiceService,
              public projectsService: ProjectsService) { }

  tab = "session_players";       
  squad_config = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    draggable: false
  };

  training_players = [];
  active_recovery = [];
  injury_players = [];
  reserve_players = [];
  recovery_players = [];
  partial_players = [];
  international_players = [];
  
  allowDrop = null;
  draged_player = null;
  partialTrainingModal: MatDialogRef<PartialtrainingComponent>;

  defaultPlayer = environment.baseUrl +"/images/img-person-placeholder.jpg";

  ngOnInit() {
    this.getSquad();
    this.getTemplates();
  }

  action(action){
    this.dialogRef.close({ action: action });
  }

  getSquad(){
    let project = this.dataService.getSelectedProject();
    let season_id = this.getSeasonId(this.session);
    this.squadService.getSquad(project.project_team_id, season_id).subscribe(data=>{
      if(data.hasOwnProperty('data')){
        if(data['data'].hasOwnProperty('squad')){
          this.squad = data['data']['squad'];
        } else {
          this.squad = data['data'];
        }
        this.squad.forEach(player => {
          this.training_players.push(player);
        });
      } else {
        this.toastrService.addToastr("Error while getting squad","error");
      }
    });
  }

  getSeasonId(session){
    let target_season = null;
    for (let it = 0; it < this.dataService.getSelectedProject().seasons.length; it++) {
      const temp_season = this.dataService.getSelectedProject().seasons[it];
      if(temp_season.id == session.season_id){
        target_season = temp_season;
        break;
      }
    }
    return target_season.project_season_id;
  }

  // INICION DRAG AND DROP PLAYERS
  dragStart(event, player){
    this.draged_player = player;
  }

  onDrop(event, from) {
    event.preventDefault();
    event.stopPropagation();
    if(from == 'training_players'){
      let player_already_training_type = this.checkPlayerExistence(this.training_players, this.draged_player);
      if(!player_already_training_type){    
        this.removePlayerFromPlayerArrays(this.draged_player);
        this.training_players.push(this.draged_player);
        this.draged_player = null;
      }
    }
    if(from == 'active_recovery'){
      let player_already_training_type = this.checkPlayerExistence(this.active_recovery, this.draged_player);
      if(!player_already_training_type){    
        this.modalService.modalOpen = true;
        this.partialTrainingModal = this.partialTrainingModalComponent.open(PartialtrainingComponent, { disableClose: true });
        let instance = this.partialTrainingModal.componentInstance;
        instance.nome = this.draged_player.player.common_name;
        this.partialTrainingModal.afterClosed().subscribe((result) => {
          this.modalService.modalOpen = false;
          if(result.action !== 'cancel'){
            this.draged_player.duration = result.time;
            this.removePlayerFromPlayerArrays(this.draged_player);
            this.active_recovery.push(this.draged_player);
          }
          this.draged_player = null;
        });
      }
    }
    if(from == 'injury_players'){
      let player_already_training_type = this.checkPlayerExistence(this.injury_players, this.draged_player);
      if(!player_already_training_type){  
        this.removePlayerFromPlayerArrays(this.draged_player);  
        this.injury_players.push(this.draged_player);
        this.draged_player = null;
      }
    }
    if(from == 'reserve_players'){
      let player_already_training_type = this.checkPlayerExistence(this.reserve_players, this.draged_player);
      if(!player_already_training_type){  
        this.removePlayerFromPlayerArrays(this.draged_player);  
        this.reserve_players.push(this.draged_player);
        this.draged_player = null;
      }
    }
    if(from == 'recovery_players'){
      let player_already_training_type = this.checkPlayerExistence(this.recovery_players, this.draged_player);
      if(!player_already_training_type){   
        this.removePlayerFromPlayerArrays(this.draged_player); 
        this.recovery_players.push(this.draged_player);
        this.draged_player = null;
      }
    }
    if(from == 'partial_players'){
      let player_already_training_type = this.checkPlayerExistence(this.partial_players, this.draged_player);
      if(!player_already_training_type){    
        this.modalService.modalOpen = true;
        this.partialTrainingModal = this.partialTrainingModalComponent.open(PartialtrainingComponent, { disableClose: true });
        let instance = this.partialTrainingModal.componentInstance;
        instance.nome = this.draged_player.player.common_name;
        this.partialTrainingModal.afterClosed().subscribe((result) => {
          this.modalService.modalOpen = false;
          if(result.action !== 'cancel'){
            this.draged_player.duration = result.time;
            this.removePlayerFromPlayerArrays(this.draged_player);
            this.partial_players.push(this.draged_player);
          }
          this.draged_player = null;
        });
      }
    }
    if(from == 'international_players'){
      let player_already_training_type = this.checkPlayerExistence(this.international_players, this.draged_player);
      if(!player_already_training_type){    
        this.removePlayerFromPlayerArrays(this.draged_player);
        this.international_players.push(this.draged_player);
        this.draged_player = null;
      }
    }
    this.allowDrop = null;
  }

  onDragOver(event, from) {
    event.stopPropagation();
    event.preventDefault();
    this.allowDrop = from;
  }

  checkPlayerExistence(array, player){
    let found = false;
    for (let it = 0; it < array.length; it++) {
      const temp_player = array[it];
      if(temp_player.player.id == player.player.id){
        found = true;
        break;
      }
    }
    return found;
  }

  removePlayerFromPlayerArrays(player){
    for (let it = this.training_players.length-1; it >=0; it--) {
      const temp_player = this.training_players[it];
      if(temp_player.player.id == player.player.id){
        this.training_players.splice(it, 1);
        break;
      }
    }
    for (let it = this.active_recovery.length-1; it >=0; it--) {
      const temp_player = this.active_recovery[it];
      if(temp_player.player.id == player.player.id){
        this.active_recovery.splice(it, 1);
        break;
      }
    }
    for (let it = this.injury_players.length-1; it >=0; it--) {
      const temp_player = this.injury_players[it];
      if(temp_player.player.id == player.player.id){
        this.injury_players.splice(it, 1);
        break;
      }
    }
    for (let it = this.reserve_players.length-1; it >=0; it--) {
      const temp_player = this.reserve_players[it];
      if(temp_player.player.id == player.player.id){
        this.reserve_players.splice(it, 1);
        break;
      }
    }
    for (let it = this.recovery_players.length-1; it >=0; it--) {
      const temp_player = this.recovery_players[it];
      if(temp_player.player.id == player.player.id){
        this.recovery_players.splice(it, 1);
        break;
      }
    }
    for (let it = this.partial_players.length-1; it >=0; it--) {
      const temp_player = this.partial_players[it];
      if(temp_player.player.id == player.player.id){
        this.partial_players.splice(it, 1);
        break;
      }
    }
    for (let it = this.international_players.length-1; it >=0; it--) {
      const temp_player = this.international_players[it];
      if(temp_player.player.id == player.player.id){
        this.international_players.splice(it, 1);
        break;
      }
    }
  }

  getTrainigColor(player){
    let color = "transparent";
    if(this.checkPlayerExistence(this.training_players, player)){
      color = "#02f3a7";
    }
    if(this.checkPlayerExistence(this.active_recovery, player)){
      color = "#ffed58";
    }
    if(this.checkPlayerExistence(this.injury_players, player)){
      color = "#fd5c5c";
    }
    if(this.checkPlayerExistence(this.reserve_players, player)){
      color = "#f076f7";
    }
    if(this.checkPlayerExistence(this.recovery_players, player)){
      color = "#76f7f4";
    }
    if(this.checkPlayerExistence(this.partial_players, player)){
      color = "#ff9c5e";
    }
    if(this.checkPlayerExistence(this.international_players, player)){
      color = "#369afd";
    }
    return color;
  }
  // FIM DRAG AND DROP PLAYERS

  showTimeTooltip(player_id){
    $(".time_tooltip").show();
    $("#time_tooltip_"+player_id).show();
  }

  hideTimeTooltips(){
    $(".time_tooltip").show();
  }

  getTemplates(){
    this.simulatorService.getTemplates().subscribe(data=> {
      this.templates = data;
      this.templates.forEach(template => {
        template['expectable_time'] = 0;
        template.selected = false;
        var exercises_nr = 0;
        template.circuits.forEach(circuit => {
          exercises_nr += circuit.exercicios.length;
          circuit.open= true;
          circuit.options = {
            open: false,
            sub_options:{
              nr_exercises: false
            }
          };
          circuit.exercicios.forEach(exercicio => {
            // if(Number(exercicio.gps_vars.acc) !== NaN && exercicio.gps_vars.acc !== 0){
            //  exercicio.gps_vars.acc = this.getParameter(exercicio, 'acc');
            // }
            // if(Number(exercicio.gps_vars.decc) !== NaN && exercicio.gps_vars.decc !== 0){
            //   exercicio.gps_vars.decc = this.getParameter(exercicio, 'decc');
            //  }
            // if(Number(exercicio.gps_vars.hi_runs) !== NaN && exercicio.gps_vars.hi_runs !== 0){
            //   exercicio.gps_vars.hi_runs = this.getParameter(exercicio, 'hi_runs');
            // }
            // if(Number(exercicio.gps_vars.mts_sprint) !== NaN && exercicio.gps_vars.mts_sprint !== 0){
            //   exercicio.gps_vars.mts_sprint = this.getParameter(exercicio, 'mts_sprint');
            // }
            // if(Number(exercicio.gps_vars.nr_sprints) !== NaN && exercicio.gps_vars.nr_sprints !== 0){
            //   exercicio.gps_vars.nr_sprints = this.getParameter(exercicio, 'nr_sprints');
            // }
            // if(Number(exercicio.gps_vars.total_distance) !== NaN && exercicio.gps_vars.total_distance !== 0){
            //   exercicio.gps_vars.total_distance = this.getParameter(exercicio, 'total_distance');
            // }
            exercicio.gps_vars.acc = "";
            exercicio.gps_vars.decc = "";
            exercicio.gps_vars.hi_runs = "";
            exercicio.gps_vars.mts_sprint = "";
            exercicio.gps_vars.nr_sprints = "";
            exercicio.gps_vars.total_distance = "";
            template['expectable_time'] += (Number(exercicio.time) * Number(exercicio.reps)); 
            exercicio.time = "";
            exercicio.reps = "";
           });
        });
        template.exercise_nr = exercises_nr;
      });
    });
  }

  checkSubmission(){
    var allAarrays_length = this.training_players.length + this.active_recovery.length + this.injury_players.length + this.reserve_players.length + this.recovery_players.length + this.partial_players.length + this.international_players.length;
    if(allAarrays_length != this.squad.length){
      this.tab = "session_players";
      this.toastrService.addToastr("Some players don't have their training specified", "error", true);
      return;
    }
    if(this.session_time.trim() == '' || this.session_time == "0"){
      this.tab = "session_time";
      this.toastrService.addToastr("Session's time is invalid", "error", true);
      return;
    }
    if(this.session_rpe.trim() == '' ||  Number(this.session_rpe) > 10 || Number(this.session_rpe) < 0 ){
      this.tab = "rpe";
      this.toastrService.addToastr("Session's RPE is invalid", "error", true);
      return;
    }
    if(this.template_selected == null){
      this.tab = "template";
      this.toastrService.addToastr("Please select a template", "error", true);
      return;
    }
    var has_error = false;
    var circuit_with_error= null;
    var exercise_with_error= null;
    var param_with_error= null;
    let selectedTemplate = null;
    let manual_settings = {};
    let isManual = false;
    for (let it = 0; it < this.templates.length; it++) {
      const template = this.templates[it];
      if(template.id == this.template_selected){
        selectedTemplate = template;
        break;
      }
    }
    if(!this.set_manually){
      for (let itC = 0; itC < selectedTemplate.circuits.length; itC++) {
        const circuit = selectedTemplate.circuits[itC];
        for (let itE = 0; itE < circuit.exercicios.length; itE++) {
          const exercicio = circuit.exercicios[itE];
          if(exercicio.gps_vars.total_distance == ""){
            param_with_error = " Total Distance";
            exercise_with_error = exercicio;
            circuit_with_error = circuit;
            break;
          }
          if(exercicio.gps_vars.hi_runs == ""){
            param_with_error = " HI Runs";
            exercise_with_error = exercicio;
            circuit_with_error = circuit;
            break;
          }
          if(exercicio.gps_vars.acc == ""){
            param_with_error = " ACC";
            exercise_with_error = exercicio;
            circuit_with_error = circuit;
            break;
          }
          if(exercicio.gps_vars.decc == ""){
            param_with_error = " DCC";
            exercise_with_error = exercicio;
            circuit_with_error = circuit;
            break;
          }
          if(exercicio.gps_vars.nr_sprints == ""){
            param_with_error = " Number of Sprints";
            exercise_with_error = exercicio;
            circuit_with_error = circuit;
            break;
          }
          if(exercicio.gps_vars.mts_sprint == ""){
            param_with_error = " Meters of Sprints";
            exercise_with_error = exercicio;
            circuit_with_error = circuit;
            break;
          }
          if(exercicio.time == ""){
            param_with_error = " Time (minutes)";
            exercise_with_error = exercicio;
            circuit_with_error = circuit;
            break;
          }
          if(exercicio.reps == ""){
            param_with_error = " Number of Repetitions";
            exercise_with_error = exercicio;
            circuit_with_error = circuit;
            break;
          }
        }
        if(exercise_with_error != null){
          has_error = true;
          break;
        }
      }
      if(has_error){
        this.tab = "gps";
        this.toastrService.addToastr("The parameter"+param_with_error+" is not defined for exercise '"+exercise_with_error.name+"', in circuit '"+circuit_with_error.name+"'", "error", true);
        return;
      }
    } else {
      isManual = true;
      manual_settings = {
        total_distance: $("#total_distance_summary").val(),
        hi_runs: $("#hi_runs_summary").val(),
        acc: $("#acc_summary").val(),
        decc: $("#decc_summary").val(),
        nr_sprints: $("#nr_sprints_summary").val(),
        mts_sprint: $("#mts_sprints_summary").val()
      };
      console.log(manual_settings);
    }
    let players = {
      training_players: this.training_players,
      active_recovery: this.active_recovery,
      injury_players: this.injury_players,
      reserve_players: this.reserve_players,
      recovery_players: this.recovery_players,
      partial_players: this.partial_players,
      international_players: this.international_players,
    };

    let exercises_ids = "";
    selectedTemplate.circuits.forEach(circuit => {
      circuit.exercicios.forEach(exercicio => {
        exercises_ids+=exercicio.id+",";
      });
    });
    if(isManual){
      selectedTemplate.manual = manual_settings;
    }
    var fd = new FormData();
    fd.append('session_id', this.session.id);
    fd.append('players', JSON.stringify(players));
    fd.append('session_time', this.session_time);
    fd.append('session_goals', JSON.stringify(this.session_goals));
    fd.append('session_rpe', this.session_rpe);
    fd.append('template_selected', JSON.stringify(selectedTemplate));
    fd.append('exercises_ids', exercises_ids);
    fd.append('project', this.dataService.selected_project.id);
    this.sessionService.addSession(fd).subscribe(data=>{
      if(data == 'success'){
        this.answer.emit({"answer": "success"});
      } else {
        this.toastrService.addToastr("Error while creating session", "error", true);
      }
    });
  }

  selectTemplate(template){
    this.template_selected = template.id;
  }

  getParameter( exercise, param){
    if(Number(exercise.time) != NaN &&
        Number(exercise.reps) != NaN && 
        exercise['time'] != 0 &&
        exercise['reps'] != 0){
        let value_per_minute = exercise['gps_vars'][param] / exercise['gps_vars']['time'];
        return Number(value_per_minute * exercise.time * exercise.reps).toFixed(2);
    } else if((Number(exercise.reps) == NaN  || exercise['reps'] == 0)
              && (Number(exercise.time) != NaN && exercise['time'] != 0)){
      return Number(exercise['gps_vars'][param] / exercise['gps_vars']['time']).toFixed(2);
    } else {
      let value_per_minute = exercise['gps_vars'][param] / exercise['gps_vars']['time'];
      return Number(value_per_minute).toFixed(2);
    }
  }

  getSumaryParameter(parameter){
    let result = 0;
    this.templates[this.template_selected - 1].circuits.forEach(circuit => {
      circuit.exercicios.forEach(exercicio => {
        result += (Number(exercicio.gps_vars[parameter]));
      });      
    });
    return result.toFixed(2); 
  }


  deleteSession(){
    event.stopPropagation();
    this.modalService.modalOpen = true;
    this.confirmDeleteModal = this.confirmDeleteModalComponent.open(ConfirmdeleteComponent, { disableClose: true });
    let instance = this.confirmDeleteModal.componentInstance;
    let title = "Warning";
    let message = "Are you sure you want to delete this session?";
    let submessage = `If you delete this session, all the data inserted for each exercise and the respective training session will be permanently deleted.<br>
                      Moreover, this data will no longer be considered for calculating the exercise's average variables.<br>`;
    instance.title = title;
    instance.message = message;
    instance.submessage = submessage;
    this.confirmDeleteModal.afterClosed().subscribe((result) => {
      this.modalService.modalOpen = false;
      if(result.message == 'success'){
        let fd = new FormData();
        fd.append('id', this.session.id);
        this.projectsService.deleteSession(fd).subscribe(data=>{
          if(data == 'success'){
            this.toastrService.addToastr("Session deleted with success", "success");
            this.dialogRef.close({ action: 'delete' });
          } else {
            this.toastrService.addToastr("There was an error when deleting session", "error");
          }
        });
      }
    });
  }


  //Session goals handlers
  addSessionGoal() {
    if (this.new_goal != '') {
      this.session_goals.push(this.new_goal);
      this.new_goal = '';
    }
  }

  deleteSessionGoal(index) {
    this.session_goals.splice(index, 1);
  }

}
