import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from '../../services/toastr.service';
import { DataService } from '../../services/data.service';
import { MicrocyclesService } from '../../services/microcycles.service';
import { ConfirmdeleteComponent } from '../../modals/confirmdelete/confirmdelete.component';
import { ModalServiceService } from '../../services/modal-service.service';

@Component({
  selector: 'app-microcycle',
  templateUrl: './microcycle.component.html',
  styleUrls: ['./microcycle.component.scss']
})
export class MicrocycleComponent implements OnInit {

  micro = null;
  data = null;
  season_id = null;
  microForm: FormGroup;
  confirmDeleteModal: MatDialogRef<ConfirmdeleteComponent>;

  constructor(public dialogRef: MatDialogRef<MicrocycleComponent>,
              private fb: FormBuilder,
              public toastrService: ToastrService,
              public dataService: DataService,
              public microService: MicrocyclesService,
              public confirmDeleteModalComponent: MatDialog,
              public modalService: ModalServiceService) { }

  ngOnInit() {
    if(this.micro == null){
      this.microForm = this.fb.group({
        beginDate: [this.data, [Validators.required, Validators.minLength(4)]],
        endDate: ['', [Validators.required, Validators.minLength(4)]],
      });
    } else {
      this.microForm = this.fb.group({
        beginDate: [this.micro.date_begin, [Validators.required, Validators.minLength(4)]],
        endDate: [this.micro.date_end, [Validators.required, Validators.minLength(4)]],
      });
    }
  }
  
  action(action){
    this.dialogRef.close({ action: action });
  }

  createMicro(){
    let beginDate = this.beginDate.value;
    let endDate = this.endDate.value;
    if(beginDate.trim() == ""){
      this.toastrService.addToastr("Fill the begining date of the microcycle", "error");
      return;
    }
    if(endDate.trim() == ""){
      this.toastrService.addToastr("Fill the end date of the microcycle", "error");
      return;
    }
    let fd = new FormData();
    fd.append("beginDate", beginDate);
    fd.append("endDate", endDate);
    fd.append("season_id", this.season_id);
    fd.append("project_id", this.dataService.getSelectedProject()['id']);
    this.microService.addMicro(fd).subscribe(data=>{
      if(data == 'success'){
        this.dialogRef.close({ action: 'success' });
      } else if("overlaps"){
        this.toastrService.addToastr("Microcycle overlaps other microcycle", "error");
      }
      else{
        this.toastrService.addToastr("Error while creating microcycle", "error");
      }
    });
  }

  updateMicro(){
    let beginDate = this.beginDate.value;
    let endDate = this.endDate.value;
    if(beginDate.trim() == ""){
      this.toastrService.addToastr("Fill the begining date of the microcycle", "error");
      return;
    }
    if(endDate.trim() == ""){
      this.toastrService.addToastr("Fill the end date of the microcycle", "error");
      return;
    }
    let fd = new FormData();
    fd.append("beginDate", beginDate);
    fd.append("endDate", endDate);
    fd.append("season_id", this.season_id);
    fd.append("project_id", this.dataService.getSelectedProject()['id']);
    fd.append("micro_id", this.micro.id);
    this.microService.updateMicro(fd).subscribe(data=>{
      if(data == 'success'){
        this.dialogRef.close({ action: 'success' });
      } else if("overlaps"){
        this.toastrService.addToastr("Microcycle overlaps other microcycle", "error");
      }
      else{
        this.toastrService.addToastr("Error while creating microcycle", "error");
      }
    });
  }

  deleteMicro(){
    this.modalService.modalOpen = true;
    this.confirmDeleteModal = this.confirmDeleteModalComponent.open(ConfirmdeleteComponent, { disableClose: true });
    let instance = this.confirmDeleteModal.componentInstance;
    let title = "Warning";
    let message = "Are you sure you want to delete this microcycle?";
    instance.title = title;
    instance.message = message;
    instance.submessage = "If you delete this microcycle, you will no longer be able to compare with other microcycles.";
    this.confirmDeleteModal.afterClosed().subscribe((result) => {
      this.modalService.modalOpen = false;
      if(result.message == 'success'){
        let fd = new FormData();
        fd.append("micro_id", this.micro.id);
        this.microService.deleteMicro(fd).subscribe(data=>{
          if(data == 'success'){
            this.dialogRef.close({ action: 'success' });
          }
        });
      }
    });
  }

  // GETTERS
  get beginDate() {
    return this.microForm.get('beginDate');
  }
  get endDate() {
    return this.microForm.get('endDate');
  }
}
