import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExercisesService {

  mainCategories = [];
  constructor( private http: HttpClient) { }

  getMainCategories(){
    return this.http.get<any[]>(environment.baseUrl + '/categories/get_categories.php');
  }

  getExerciseCategories(){
    return this.http.get<any[]>(environment.baseUrl + '/categories/get_categories.php?type=exercise');
  }

  getSessionCategories(){
    return this.http.get<any[]>(environment.baseUrl + '/categories/get_categories.php?type=session');
  }

  createExercise(fd){
    return this.http.post(environment.baseUrl + '/exercises/create_exercise.php', fd);
  }

  editExercise(fd){
    return this.http.post(environment.baseUrl + '/exercises/edit_exercise.php', fd);
  }
  
  getExercisesByCat(fd){
    return this.http.post(environment.baseUrl + '/exercises/get_exercises_by_cat.php', fd);
  }

  saveKeypoints(fd){
    return this.http.post(environment.baseUrl + '/exercises/save_keypoints.php', fd);
  }

  searchExercise(fd){
    return this.http.post(environment.baseUrl + '/exercises/search_exercise.php', fd);
  }

  checkExerciseUsage(fd){
    return this.http.post(environment.baseUrl + '/exercises/check_exercise_usage.php', fd);
  }

  deleteExercise(fd){
    return this.http.post(environment.baseUrl + '/exercises/delete_exercise.php', fd);
  }
}
