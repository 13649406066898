import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor( private http: HttpClient) { }
  getSessions(data){
    return this.http.post(environment.baseUrl + '/sessions/get_sessions.php', data);
  }

  getRestDays(data){
    return this.http.post(environment.baseUrl + '/sessions/get_rests.php', data);
  }

  addSession(session){
    return this.http.post(environment.baseUrl + '/sessions/add_session.php', session);
  }

  addPreSession(session){
    return this.http.post(environment.baseUrl + '/sessions/add_pre_session.php', session);
  }

  addRestDay(rest){
    return this.http.post(environment.baseUrl + '/sessions/add_rest.php', rest);
  }

  deleteRestDay(obj){
    return this.http.post(environment.baseUrl + '/sessions/delete_rest.php', obj);
  }

  searchService(obj){
    return this.http.post(environment.baseUrl + '/sessions/search_session.php', obj);
  }
}
