import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MicrocyclesService {

  constructor(
    private http: HttpClient
  ) { }

  getMicro(fd){
    return this.http.post(environment.baseUrl + '/micros/get_micros.php', fd);
  }

  getMicrosAllInfo(fd){
    return this.http.post(environment.baseUrl + '/micros/get_micros_all_info.php', fd);
  }

  addMicro(fd){
    return this.http.post(environment.baseUrl + '/micros/add_micro.php', fd);
  }

  updateMicro(fd){
    return this.http.post(environment.baseUrl + '/micros/edit_micro.php', fd);
  }

  deleteMicro(fd){
    return this.http.post(environment.baseUrl + '/micros/delete_micro.php', fd);
  }
  
  getMicroSessions(fd){
    return this.http.post(environment.baseUrl + '/micros/get_micro_sessions.php', fd);
  }

  async asyncGetMicroSessions(fd){
    let data = await  this.http.post<Array<any>>(environment.baseUrl + '/micros/get_micro_sessions.php', fd).toPromise();
    return data;
  }

  async asyncGetMicroRests(fd){
    let data = await  this.http.post<Array<any>>(environment.baseUrl + '/micros/get_micro_rests.php', fd).toPromise();
    return data;
  }
}
