import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ChooseexerciseComponent } from '../../modals/chooseexercise/chooseexercise.component';
import { DataService } from '../../services/data.service';
import { ToastrService } from '../../services/toastr.service';
import { SimulatorService } from '../../services/simulator.service';
import { LoadComponent } from '../../modals/load/load.component';
import { KeypointsComponent } from '../../modals/keypoints/keypoints.component';
import { ModalServiceService } from '../../services/modal-service.service';
import { LoadingService } from '../../services/loading.service';

import * as $ from 'jquery';
import jsPDF from 'jspdf';
import {ExercisesService} from "../../services/exercises.service";
import {OptionsService} from "../../services/options.service";
import {AuthService} from "../../services/auth.service";


@Component({
  selector: 'app-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss']
})
export class SimulatorComponent implements OnInit {

  tab = "template1";
  categories = [];
  optionIdealLoadValues = [];
  template_one = {
    open: false,
    main_cat_id: 0,
    circuits: []
  };
  template_two = {
    open: false,
    main_cat_id: 0,
    circuits: []
  };
  template_three = {
    open: false,
    main_cat_id: 0,
    circuits: []
  };

  drag_controller ={
    is_dragging : false,
    template : null,
    ic: null
  };

  nr_of_groups = [];
  
  chooseExerciseModal: MatDialogRef<ChooseexerciseComponent>;
  loadModal : MatDialogRef<LoadComponent>;
  keyPointsModal : MatDialogRef<KeypointsComponent>;

  constructor(public chooseExerciseModalComponent: MatDialog,
              public loadModalComponent: MatDialog,
              public keyPointsModalComponent: MatDialog,
              public dataService: DataService,
              public toastrService: ToastrService,
              public simulatorService: SimulatorService,
              public modalService: ModalServiceService,
              public exercisesService: ExercisesService,
              public optionsService: OptionsService,
              public authService: AuthService,
              public loadingService: LoadingService) { }
              

  ngOnInit() {
    this.getTemplates();
  }

  getTemplates(){
    this.loadingService.loading = true;
    this.exercisesService.getExerciseCategories().subscribe(data=> {
      this.categories = data;
      let fd = new FormData();
      fd.append('option_name', 'session_ideals');
      fd.append('user_id', this.authService.getUser()['id']);
      this.optionsService.getOption(fd).subscribe(dataOpt => {
        if(dataOpt["answer"] == 'success') {
          this.optionIdealLoadValues = JSON.parse(dataOpt["options"][0].option_values);
        }
        else {
          this.toastrService.addToastr("Error while retrieving options", "error", true);
        }
        this.simulatorService.getTemplates().subscribe(data=> {
          this.loadingService.loading = false;
          let templates = data;
          templates.forEach(template => {
            template.open = false;
            template.circuits.forEach(circuit => {
              circuit.open= true;
              circuit.options = {
                open: false,
                sub_options:{
                  nr_exercises: false
                }
              };
              if(circuit.exercicios.length > this.nr_of_groups.length){
                this.nr_of_groups = [];
                circuit.exercicios.forEach(exercicios => {
                  this.nr_of_groups.push("Grupo " + (this.nr_of_groups.length + 1));
                });
              }
            });
          });
          this.template_one = templates[0];
          this.template_two = templates[1];
          this.template_three = templates[2];
        });
      });

    })

  }

  addSession(template, circuits){
    let exercicios_array =[];
    for (let index = 1; index <= circuits; index++) {
      exercicios_array.push({
        name: "Exercise "+ index
      });
      
    }
    template.circuits.push({
      id: (template.circuits.length + 1),
      name: "Circuit " +(template.circuits.length +1),
      exercicios: exercicios_array,
      open: true,
      options: {
        open: false,
        sub_options:{
          nr_exercises: false
        }
      }
    });
  
    this.checkGroupNumber(template);
  }

  checkGroupNumber(template){
    this.nr_of_groups = [];
    template.circuits.forEach(circuit => {
      if(circuit.exercicios.length > this.nr_of_groups.length){
        this.nr_of_groups = [];
        circuit.exercicios.forEach(exercicio => {
         this.nr_of_groups.push("Grupo " + (this.nr_of_groups.length + 1));
        });
      }
    });
  }

  addExercise(template, iC, iE){
    this.modalService.modalOpen = true;
    this.chooseExerciseModal = this.chooseExerciseModalComponent.open(ChooseexerciseComponent, { disableClose: true });
    this.chooseExerciseModal.afterClosed().subscribe((result) => {
      this.modalService.modalOpen = false;
      if(result.action !== 'cancel'){
        template.circuits[iC].exercicios[iE] = result.exercicio;
        template.circuits[iC].exercicios[iE].time = 0;
        template.circuits[iC].exercicios[iE].reps = 0;
        template.circuits[iC].exercicios[iE].who = 0;
        this.checkGroupNumber(template);
      } 
    });
  }
  
  resetTemplate(which){
    if(which == 1){
      this.template_one = {
        open: false,
        circuits: [],
        main_cat_id: 1
      };
      this.checkGroupNumber(this.template_one);
    } else if(which == 2){
      this.template_two = {
        open: false,
        circuits: [],
        main_cat_id: 1
      };
      this.checkGroupNumber(this.template_two);
    } else {
      this.template_three = {
        open: false,
        circuits: [],
        main_cat_id: 1
      };
      this.checkGroupNumber(this.template_three);
    }
  }

  changeExerciseNr(nrOfExercises, iC, template){
    if(template.circuits[iC].exercicios.length > nrOfExercises){
      let exercisesToRemove = template.circuits[iC].exercicios.length - nrOfExercises;
      for(let i=1; i<=exercisesToRemove; i++){
        template.circuits[iC].exercicios.splice(-1,1);
      }
    } else {
      let exercisesToAdd = nrOfExercises - template.circuits[iC].exercicios.length;
      for(let i=1; i<=exercisesToAdd; i++){
        template.circuits[iC].exercicios.push({
          name: "Exercise "+ i
        });
      }
    }
    this.checkGroupNumber(template);
  }

  saveTemplate(template, template_nr){
    // if(template.circuits.length == 0){
    //   this.toastrService.addToastr("Insert, at least, one circuit to the template", "error");
    //   return;
    // }
    let circuit_with_exercise_left = null;
    let exercise_left = null;
    for (let itC = 0; itC < template.circuits.length; itC++) {
      const circuit = template.circuits[itC];
      exercise_left = null;
      for (let itE = 0; itE < circuit.exercicios.length; itE++) {
        const exercicio = circuit.exercicios[itE];
        if(!exercicio.hasOwnProperty('id')){
          circuit_with_exercise_left = circuit.name;
          exercise_left = exercicio.name;
          break;
        } 
      }
      if(exercise_left != null){
        break;
      }
    }
    if(circuit_with_exercise_left != null){
      this.toastrService.addToastr("Please define the "+ exercise_left+ " from the "+circuit_with_exercise_left, "error");
      return;
    }
    let fd = new FormData();
    fd.append('template', JSON.stringify(template));
    fd.append('template_id', template_nr);
    this.loadingService.loading = true;
    this.simulatorService.saveTemplate(fd).subscribe(data => {
      this.loadingService.loading = false;
      if(data == "success"){
        this.getTemplates();
        this.toastrService.addToastr("Template "+template_nr + " successfully updated" , 'success');
      } else {
        this.toastrService.addToastr("An error ocurred while saving template", 'error');
      }
    });
  }

  deleteCircuit(iC, template){
    template.circuits.splice(iC, 1);
    template.circuits.forEach((circuit, it) => {
      circuit.name = "Circuit "+ (it + 1)
    });
    this.checkGroupNumber(template);
  }

  expected_load(template, ideal_loads){
    this.loadModal = this.loadModalComponent.open(LoadComponent, { disableClose: true });
    let instance = this.loadModal.componentInstance;
    instance.template = template;
    instance.ideal_loads = ideal_loads;
    instance.nr_of_groups = this.nr_of_groups.length;
  }

  ///////////// STRAT DRAG & DROP /////////////
  dragStart(event, template, iC){
    this.drag_controller.is_dragging = true;
    this.drag_controller.template = template.id;
    this.drag_controller.ic = iC;
  }

  onDragOver(event){
    event.preventDefault();
    var target = event.target || event.srcElement || event.currentTarget;
    target.style.background = "rgba(255, 255, 255, 0.38)";
  }

  onDragOut(event){
    var target = event.target || event.srcElement || event.currentTarget;
    target.style.background = "transparent";
  }

  dragEnd(event){
    this.drag_controller.is_dragging = false;
    this.drag_controller.template = null;
    this.drag_controller.ic = null;
  }

  onDrop(template, iC){
    var circuit_ini = template.circuits[this.drag_controller.ic];
    var circuit_fim = template.circuits[iC];
    template.circuits[iC] = circuit_ini;
    template.circuits[this.drag_controller.ic] = circuit_fim;
    this.drag_controller.is_dragging = false;
    this.drag_controller.template = null;
    this.drag_controller.ic = null;
    template.circuits.forEach((circuit, it) => {
      circuit.name = "Circuit "+(it+1);
    });
  }
  ///////////// END DRAG & DROP /////////////

  checkIfSelected(exercise, i){
    return exercise.who.toString().indexOf(i.toString()) !== -1;
  }

  checkGroup(exercise, i, id){
    if(exercise.who == '0'){
      exercise.who = i;
    } else {
      setTimeout(() => {
        if($("#"+id).is(":checked")){
          exercise.who += ","+i;
          if(exercise.who.split(",").length == this.nr_of_groups.length){
            exercise.who = "0";
            $("#"+id).prop('checked',false);
          }
        } else {
          if(!exercise.who.toString().includes(",")){
            exercise.who = "0";
          } else {
            let groups_Array = exercise.who.split(",");
            let string = "";
            groups_Array.forEach(group => {
              if(group !=  i){
                string+= group + ","; 
              }
            });
            string = string.slice(0, -1);
            exercise.who = string;
          }
        }  
      }, 0);
    }
  }

  addKeyPoints(circuit, exercise){
    this.keyPointsModal = this.keyPointsModalComponent.open(KeypointsComponent, { disableClose: true });
    let instance = this.keyPointsModal.componentInstance;
    instance.circuit = circuit;
    instance.exercise = exercise;
  }

  getParam(exercise, param){
    if(exercise['gps_vars'][param] == 0 && exercise['gps_vars']['time'] == 0){
      return "No previous values";
    }
    if(Number(exercise.time) != NaN &&
       Number(exercise.reps) != NaN && 
       exercise['time'] != 0 &&
       exercise['reps'] != 0){
        let value_per_minute = exercise['gps_vars'][param] / exercise['gps_vars']['time'];
        return (value_per_minute * exercise.time * exercise.reps).toFixed(2);
    } 
    else {
      let value_per_minute = exercise['gps_vars'][param] / exercise['gps_vars']['time'];
      if(isNaN(value_per_minute)){
        return "No previous values";
      }
      return value_per_minute.toFixed(2) + " / min";
    }
  }

  toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          if(callback != null){
            callback(reader.result);
          } else {
            return reader.result;
          }
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  getGroups(template){
    let groups = [];
    let isAllGroup = true;
    for (let itC = 0; itC < template.circuits.length; itC++) {
      const circuit = template.circuits[itC];
      for (let itE = 0; itE < circuit.exercicios.length; itE++) {
        const exercicio = circuit.exercicios[itE];
        if(exercicio.who != 0){
          isAllGroup = false;
          break;
        }
      }
      if(!isAllGroup){
        break;
      }
    }
    if(isAllGroup){
      let total_distance = 0;
      let hi_runs = 0;
      let acc = 0;
      let decc = 0;
      let nr_sprints = 0;
      let mts_nr_sprints = 0;

      template.circuits.forEach(circuit => {
        circuit.exercicios.forEach(exercicio => {
          let temp_total_distance = Number(this.getParamLoad(exercicio, 'total_distance')); 
          let temp_hi_runs = Number(this.getParamLoad(exercicio, 'hi_runs')); 
          let temp_acc = Number(this.getParamLoad(exercicio, 'acc'));
          let temp_decc = Number(this.getParamLoad(exercicio, 'decc'));  
          let temp_nr_sprints = Number(this.getParamLoad(exercicio, 'nr_sprints'));
          let temp_mts_nr_sprints = Number(this.getParamLoad(exercicio, 'mts_sprint'));        
          total_distance += temp_total_distance;
          hi_runs += temp_hi_runs;
          acc += temp_acc;
          decc += temp_decc;
          nr_sprints += temp_nr_sprints;
          mts_nr_sprints += temp_mts_nr_sprints; 
        });
      });

      groups.push({
        name: "All Group",
        total_distance: total_distance.toFixed(2),
        hi_runs: hi_runs.toFixed(2),
        acc: acc.toFixed(2),
        decc: decc.toFixed(2),
        nr_sprints: nr_sprints.toFixed(2),
        mts_nr_sprints: mts_nr_sprints.toFixed(2)
      });
    } else {
      let add_group_1 = false;
      let add_group_2 = false;
      let add_group_3 = false;
      template.circuits.forEach(circuit => {
        circuit.exercicios.forEach(exercicio => {
          if(exercicio.who.toString().indexOf("1") !== -1){
            add_group_1 = true;
          }
          if(exercicio.who.toString().indexOf("2") !== -1){
            add_group_2 = true;
          }
          if(exercicio.who.toString().indexOf("3") !== -1){
            add_group_3 = true;
          }
        });
      });
      if(add_group_1){
        let total_distance = 0;
        let hi_runs = 0;
        let acc = 0;
        let decc = 0;
        let nr_sprints = 0;
        let mts_nr_sprints = 0;
        template.circuits.forEach(circuit => {
          circuit.exercicios.forEach(exercicio => {
            if(exercicio.who.toString().indexOf("1") !== -1 || exercicio.who.toString() == "0"){
              total_distance += Number(this.getParamLoad(exercicio, 'total_distance'));
              hi_runs += Number(this.getParamLoad(exercicio, 'hi_runs'));
              acc += Number(this.getParamLoad(exercicio, 'acc'));
              decc += Number(this.getParamLoad(exercicio, 'decc'));
              nr_sprints += Number(this.getParamLoad(exercicio, 'nr_sprints'));
              mts_nr_sprints += Number(this.getParamLoad(exercicio, 'mts_sprint'));
            }
          });
        });
        groups.push({
          name: "Group 1",
          total_distance: total_distance.toFixed(2),
          hi_runs: hi_runs.toFixed(2),
          acc: acc.toFixed(2),
          decc: decc.toFixed(2),
          nr_sprints: nr_sprints.toFixed(2),
          mts_nr_sprints: mts_nr_sprints.toFixed(2)
        });
        if(!add_group_2 && !add_group_3){
          let total_distance = 0;
          let hi_runs = 0;
          let acc = 0;
          let decc = 0;
          let nr_sprints = 0;
          let mts_nr_sprints = 0;
          template.circuits.forEach(circuit => {
            circuit.exercicios.forEach(exercicio => {
              if(exercicio.who.toString() == "0"){
                add_group_1 = true;
                total_distance += Number(this.getParamLoad(exercicio, 'total_distance'));
                hi_runs += Number(this.getParamLoad(exercicio, 'hi_runs'));
                acc += Number(this.getParamLoad(exercicio, 'acc'));
                decc += Number(this.getParamLoad(exercicio, 'decc'));
                nr_sprints += Number(this.getParamLoad(exercicio, 'nr_sprints'));
                mts_nr_sprints +=  Number(this.getParamLoad(exercicio, 'mts_sprint'));
              }
            });
          });
          groups.push({
            name: "Rest of Group",
            total_distance: total_distance.toFixed(2),
            hi_runs: hi_runs.toFixed(2),
            acc: acc.toFixed(2),
            decc: decc.toFixed(2),
            nr_sprints: nr_sprints.toFixed(2),
            mts_nr_sprints: mts_nr_sprints.toFixed(2)
          });
        }
      }
      if(add_group_2){
        let total_distance = 0;
        let hi_runs = 0;
        let acc = 0;
        let decc = 0;
        let nr_sprints = 0;
        let mts_nr_sprints = 0;
        template.circuits.forEach(circuit => {
          circuit.exercicios.forEach(exercicio => {
            if(exercicio.who.toString().indexOf("2") !== -1 || exercicio.who.toString() == "0"){
              total_distance +=  Number(this.getParamLoad(exercicio, 'total_distance'));
              hi_runs +=  Number(this.getParamLoad(exercicio, 'hi_runs'));
              acc +=  Number(this.getParamLoad(exercicio, 'acc'));
              decc +=  Number(this.getParamLoad(exercicio, 'decc'));
              nr_sprints +=  Number(this.getParamLoad(exercicio, 'nr_sprints'));
              mts_nr_sprints +=  Number(this.getParamLoad(exercicio, 'mts_sprint'));
            }
          });
        });
        groups.push({
          name: "Group 2",
          total_distance: total_distance.toFixed(2),
          hi_runs: hi_runs.toFixed(2),
          acc: acc.toFixed(2),
          decc: decc.toFixed(2),
          nr_sprints: nr_sprints.toFixed(2),
          mts_nr_sprints: mts_nr_sprints.toFixed(2)
        });
        if(!add_group_1 && !add_group_3){
          let total_distance = 0;
          let hi_runs = 0;
          let acc = 0;
          let decc = 0;
          let nr_sprints = 0;
          let mts_nr_sprints = 0;
          template.circuits.forEach(circuit => {
            circuit.exercicios.forEach(exercicio => {
              if(exercicio.who.toString() == "0"){
                total_distance +=  Number(this.getParamLoad(exercicio, 'total_distance'));
                hi_runs +=  Number(this.getParamLoad(exercicio, 'hi_runs'));
                acc +=  Number(this.getParamLoad(exercicio, 'acc'));
                decc +=  Number(this.getParamLoad(exercicio, 'decc'));
                nr_sprints +=  Number(this.getParamLoad(exercicio, 'nr_sprints'));
                mts_nr_sprints +=  Number(this.getParamLoad(exercicio, 'mts_sprint'));
              }
            });
          });
          groups.push({
            name: "Rest of Group",
            total_distance: total_distance.toFixed(2),
            hi_runs: hi_runs.toFixed(2),
            acc: acc.toFixed(2),
            decc: decc.toFixed(2),
            nr_sprints: nr_sprints.toFixed(2),
            mts_nr_sprints: mts_nr_sprints.toFixed(2)
          });
        }
      }
      if(add_group_3){
        let total_distance = 0;
        let hi_runs = 0;
        let acc = 0;
        let decc = 0;
        let nr_sprints = 0;
        let mts_nr_sprints = 0;
        template.circuits.forEach(circuit => {
          circuit.exercicios.forEach(exercicio => {
            if(exercicio.who.toString().indexOf("3") !== -1 || exercicio.who.toString() == "0"){
              total_distance += Number(this.getParamLoad(exercicio, 'total_distance'));
              hi_runs += Number(this.getParamLoad(exercicio, 'hi_runs'));
              acc += Number(this.getParamLoad(exercicio, 'acc'));
              decc += Number(this.getParamLoad(exercicio, 'decc'));
              nr_sprints += Number(this.getParamLoad(exercicio, 'nr_sprints'));
              mts_nr_sprints += Number(this.getParamLoad(exercicio, 'mts_sprint'));
            }
          });
        });
        groups.push({
          name: "Group 3",
          total_distance: total_distance.toFixed(2),
          hi_runs: hi_runs.toFixed(2),
          acc: acc.toFixed(2),
          decc: decc.toFixed(2),
          nr_sprints: nr_sprints.toFixed(2),
          mts_nr_sprints: mts_nr_sprints.toFixed(2)
        });
        if(!add_group_1 && !add_group_2){
          let total_distance = 0;
          let hi_runs = 0;
          let acc = 0;
          let decc = 0;
          let nr_sprints = 0;
          let mts_nr_sprints = 0;
          template.circuits.forEach(circuit => {
            circuit.exercicios.forEach(exercicio => {
              if(exercicio.who.toString() == "0"){
                total_distance +=  Number(this.getParamLoad(exercicio, 'total_distance'));
                hi_runs +=  Number(this.getParamLoad(exercicio, 'hi_runs'));
                acc +=  Number(this.getParamLoad(exercicio, 'acc'));
                decc +=  Number(this.getParamLoad(exercicio, 'decc'));
                nr_sprints +=  Number(this.getParamLoad(exercicio, 'nr_sprints'));
                mts_nr_sprints +=  Number(this.getParamLoad(exercicio, 'mts_sprint'));
              }
            });
          });
          groups.push({
            name: "Rest of Group",
            total_distance: total_distance.toFixed(2),
            hi_runs: hi_runs.toFixed(2),
            acc: acc.toFixed(2),
            decc: decc.toFixed(2),
            nr_sprints: nr_sprints.toFixed(2),
            mts_nr_sprints: mts_nr_sprints.toFixed(2)
          });
        }
      }
    }
    return groups;
  }

  getParamLoad(exercise, param){
    if(exercise['gps_vars'][param] == 0){
      return 0;
    }
    if(Number(exercise.time) != NaN &&
       Number(exercise.reps) != NaN && 
       exercise['time'] != 0 &&
       exercise['reps'] != 0){
        let value_per_minute = exercise['gps_vars'][param] / exercise['gps_vars']['time'];
        return Number(value_per_minute * exercise.time * exercise.reps).toFixed(2);
    } else {
     return 0;
    }
  }

  async exportPDF(template){
    var color = "";
    var logo = "../../assets/images/white_logo.png";
    var self = this;
    let actual_height = 0;
    let players_height = 0;
    let max_height = 297;
    const doc = new jsPDF();
    doc.setFillColor(32, 32, 32);
    doc.rect(0,0,69,21, 'F');
    doc.setFont("helvetica");
    doc.setFontStyle('bold');
    doc.setFontSize(14);
    doc.setTextColor(255, 255, 255);
    doc.text("Simulated Session", 22.29, 8.5); 
    doc.setFont("helvetica");
    doc.setFontStyle('normal');
    doc.setTextColor(98, 98, 98);
    doc.text(template.name, 22.29, 16.5);
    self.toDataUrl(logo, function(logo64) {
      doc.addImage(logo64, 'PNG', 4.145, 4.145, 14, 12.71);
      self.toDataUrl(self.dataService.selected_project.project_team_logo, function(team64) {
        doc.addImage(team64, 'PNG', 190, 4.145, 14, 12.71);
        actual_height = 30;
        let tempo_simulador = 0;
        template.circuits.forEach( async (circuit, it1) => { 
          doc.setFillColor(20, 20, 20);
          doc.rect(0, actual_height, 210, 7, 'F');
          doc.setFontStyle('bold');
          doc.setFontSize(10);
          doc.setTextColor(255,255,255);
          doc.text("EXERCISE " + (it1+1), 96, actual_height + 5); 
          doc.setTextColor(0,0,0);
          doc.setFontSize(8);
          actual_height += 11;
          circuit.exercicios.forEach(async (exercise, it) => {
            let inc = 80 * it;
            if(inc > 80){
              inc = 0;
              actual_height += 35;
            } 
            const label = "Exercise "+(it + 1);
            doc.setFontStyle('normal');
            doc.text(label, 35 + inc, actual_height); 
            doc.setFontStyle('bold');
            doc.text(" - " +exercise.name, 48 + inc, actual_height); 
            var img = document.getElementById('exercise-'+exercise.id);
            doc.addImage(img, 35 + inc, actual_height + 2.5, 40, 25);
            let textBeginX = 35 + inc + 42;
            doc.setFontStyle('normal');
            doc.text("TD: " + exercise.gps_vars.total_distance, textBeginX, actual_height+5);
            doc.text("Hi Runs: " + exercise.gps_vars.hi_runs, textBeginX, actual_height+9.5);
            doc.text("ACC: " + exercise.gps_vars.acc, textBeginX, actual_height+14);
            doc.text("DECC: " + exercise.gps_vars.decc, textBeginX, actual_height+18.5);
            doc.text("Sprints: " + exercise.gps_vars.nr_sprints, textBeginX, actual_height+23); 
            doc.text("Mts. Sprint: " + exercise.gps_vars.mts_sprint, textBeginX, actual_height+27.5); 
            tempo_simulador += Number(exercise.time);
          }); 
          actual_height += 35;
          let inc = circuit.exercicios.length == 3 ? 100 : 60;
          if(actual_height + inc > max_height){
            doc.addPage();       
            doc.addImage(logo64, 'PNG', 4.145, 4.145, 14, 12.71);
            doc.addImage(team64, 'PNG', 190, 4.145, 14, 12.71);
            doc.setFillColor(32, 32, 32);
            doc.rect(0,0,69,21, 'F');
            doc.setFont("helvetica");
            doc.setFontStyle('bold');
            doc.setFontSize(14);
            doc.setTextColor(255, 255, 255);
            doc.text("Simulated Session", 22.29, 8.5); 
            doc.setFont("helvetica");
            doc.setFontStyle('normal');
            doc.setTextColor(98, 98, 98);
            doc.text(template.name, 22.29, 16.5);
            actual_height = 30;
          }
        });
        let nr_of_pages = doc.internal.getNumberOfPages();
        let pages = doc.internal.pages;
        for (let it = 0; it < pages.length; it++) {
          doc.setPage(it);
          doc.setFontStyle('bold');
          doc.setFontSize(12);
          doc.setFillColor(255,255,255);
          doc.rect(90,280,50,15, 'F');
          doc.text(it+"/"+nr_of_pages, 100, 285);
          doc.setFontStyle('bold');
          doc.text("Session Time (min)", 90, 8.5);
          doc.setFontStyle('normal');
          const sim = " " + tempo_simulador;
          doc.text(sim, 102, 15);
        }
        actual_height += 5;
        doc.setFontStyle('bold');
        doc.setFontSize(11);
        doc.text("GROUP", 10, actual_height);
        doc.text("Total Distance (m)", 35, actual_height);
        doc.text("Meters Sprint", 80, actual_height);
        doc.text("Hi Runs (m)", 115, actual_height);
        doc.text("ACC", 145, actual_height);
        doc.text("DCC", 160, actual_height);
        doc.text("N.º Sprints", 180, actual_height);
        doc.setFillColor(0, 0,0);
        doc.rect(5, actual_height+3, 200, 0.3, 'F');
        actual_height+=4;
        let groups = self.getGroups(template);
        doc.setFontStyle('normal');
        groups.forEach(group => {
          actual_height+=4;
          doc.text(group.name, 10, actual_height);
          doc.text(group.total_distance, 35, actual_height);
          doc.text(group.mts_nr_sprints, 80, actual_height);
          doc.text(group.hi_runs, 115, actual_height);
          doc.text(group.acc, 145, actual_height);
          doc.text(group.decc, 160, actual_height);
          doc.text(group.nr_sprints, 180, actual_height);
        });
        setTimeout(() => {
          let doc_name = template.name+".pdf";
          doc.save(doc_name); 
        }, 1000);
      });
    });
  }
}
