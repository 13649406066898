import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class SquadService {

  squad = null;
  constructor(private http: HttpClient) { }

  getSquad(team_id, season){
    return this.http.get("https://api.soccersapi.com/v2.2/teams/?user=nuno&token=6ef325183ef9cb2b665b758d21fa861d&t=squad&id="+team_id+"&season_id="+season);
  }
}
