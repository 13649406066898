import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  tab = 'schedule';
  constructor(
    private http: HttpClient
  ) { }


  getProjects(fd){
    return this.http.post(environment.baseUrl + '/projects/get_projects.php', fd);
  }

  getProjectsCountries(){
    return this.http.get("https://api.soccersapi.com/v2.2/countries/?user=nuno&token=6ef325183ef9cb2b665b758d21fa861d&t=list");
  }

  getCountryLeagues(id){
    return this.http.get("https://api.soccersapi.com/v2.2/leagues/?user=nuno&token=6ef325183ef9cb2b665b758d21fa861d&t=list&country_id="+id);
  }

  getLeagueById(id){
    return this.http.get("https://api.soccersapi.com/v2.2/leagues/?user=nuno&token=6ef325183ef9cb2b665b758d21fa861d&t=info&id="+id);
  }

  getTeamSeasons(id){
    return this.http.get("https://api.soccersapi.com/v2.2/teams/?user=nuno&token=6ef325183ef9cb2b665b758d21fa861d&t=byseason&season_id="+id);
  }
  
  getTeamById(id){
    return this.http.get(" https://api.soccersapi.com/v2.2/teams/?user=nuno&token=6ef325183ef9cb2b665b758d21fa861d&t=info&id="+id);
  }

  createProject(team){
    return this.http.post(environment.baseUrl + '/projects/create_projects.php', team);
  }

  addSeason(fd){
    return this.http.post(environment.baseUrl + '/projects/add_season.php', fd);
  }

  getFixtures(season, team_id){
    return this.http.get("https://api.soccersapi.com/v2.2/fixtures/?user=nuno&token=6ef325183ef9cb2b665b758d21fa861d&t=season&season_id="+season+"&team_id="+team_id);
  }

  deleteProject(fd){
    return this.http.post(environment.baseUrl + '/projects/delete_projects.php', fd);
  }

  deleteSession(fd){
    return this.http.post(environment.baseUrl + '/sessions/delete_session.php', fd); 
  }

  async getLeagueForTeam(league_id){
    let data = await this.http.get("https://api.soccersapi.com/v2.2/leagues/?user=nuno&token=6ef325183ef9cb2b665b758d21fa861d&t=info&id="+league_id).toPromise();
    return data;
  }

  async getOhterCompetition_fictures(season_id, team_id){
    let data = await  this.http.get("https://api.soccersapi.com/v2.2/fixtures/?user=nuno&token=6ef325183ef9cb2b665b758d21fa861d&t=season&season_id="+season_id+"&team_id="+team_id).toPromise();
    return data;
  }
}
