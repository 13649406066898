import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../services/data.service';
import { OptionsService } from '../../services/options.service';
import { MatDialogRef } from '@angular/material';
import { ExercisesService } from "../../services/exercises.service";
import { LoadingService } from "../../services/loading.service";
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import {AuthService} from "../../services/auth.service";
import {ToastrService} from "../../services/toastr.service";

@Component({
  selector: 'app-optionsmodal',
  templateUrl: './optionsmodal.component.html',
  styleUrls: ['./optionsmodal.component.scss']
})



export class OptionsmodalComponent implements OnInit {

  categories = [];
  opt_cat_goals = {};

  option_goals_selected_cat = 0;

  total_distance_min    = 0.0;
  total_distance_max    = 0.0;
  hi_distance_min       = 0.0;
  hi_distance_max       = 0.0;
  n_sprints_min         = 0.0;
  n_sprints_max         = 0.0;
  distance_sprint_min   = 0.0;
  distance_sprint_max   = 0.0;
  acc_min               = 0.0;
  acc_max               = 0.0;
  decc_min              = 0.0;
  decc_max              = 0.0;

  @Output() answer = new EventEmitter();

  constructor(
      public dataService: DataService,
      private fb: FormBuilder,
      public optionsService: OptionsService,
      public loadingService: LoadingService,
      public exercisesService: ExercisesService,
      public authService: AuthService,
      public toastrService: ToastrService,
      public dialogRef: MatDialogRef<OptionsmodalComponent>
              ) { }

  optionsModal:MatDialogRef<OptionsmodalComponent>;

  ngOnInit() {
      this.loadingService.loading = true;
      this.exercisesService.getExerciseCategories().subscribe(data=> {
          this.categories = data;
          this.option_goals_selected_cat = this.categories["main"][0]["id"];

          let fd = new FormData();
          fd.append('option_name', 'session_ideals');
          fd.append('user_id', this.authService.getUser()['id']);
          this.optionsService.getOption(fd).subscribe(dataOpt => {

              if(dataOpt["answer"] == 'success'){
                  let optionValues = JSON.parse(dataOpt["options"][0].option_values);

                  this.categories["main"].forEach(cat => {
                      this.opt_cat_goals[cat["id"]] = {
                          "total_distance_min" : optionValues[cat["id"]].total_distance_min,
                          "total_distance_max" : optionValues[cat["id"]].total_distance_max,
                          "hi_distance_min" : optionValues[cat["id"]].hi_distance_min,
                          "hi_distance_max" : optionValues[cat["id"]].hi_distance_max,
                          "n_sprints_min" : optionValues[cat["id"]].n_sprints_min,
                          "n_sprints_max" : optionValues[cat["id"]].n_sprints_max,
                          "distance_sprint_min" : optionValues[cat["id"]].distance_sprint_min,
                          "distance_sprint_max" : optionValues[cat["id"]].distance_sprint_max,
                          "acc_min" : optionValues[cat["id"]].acc_min,
                          "acc_max" : optionValues[cat["id"]].acc_max,
                          "decc_min" : optionValues[cat["id"]].decc_min,
                          "decc_max" : optionValues[cat["id"]].decc_max
                      };

                      if(cat["id"] == this.option_goals_selected_cat){
                          this.total_distance_min    = optionValues[cat["id"]].total_distance_min;
                          this.total_distance_max    = optionValues[cat["id"]].total_distance_max;
                          this.hi_distance_min       = optionValues[cat["id"]].hi_distance_min;
                          this.hi_distance_max       = optionValues[cat["id"]].hi_distance_max;
                          this.n_sprints_min         = optionValues[cat["id"]].n_sprints_min;
                          this.n_sprints_max         = optionValues[cat["id"]].n_sprints_max;
                          this.distance_sprint_min   = optionValues[cat["id"]].distance_sprint_min;
                          this.distance_sprint_max   = optionValues[cat["id"]].distance_sprint_max;
                          this.acc_min               = optionValues[cat["id"]].acc_min;
                          this.acc_max               = optionValues[cat["id"]].acc_max;
                          this.decc_min              = optionValues[cat["id"]].decc_min;
                          this.decc_max              = optionValues[cat["id"]].decc_max;
                      }
                  });
              } else {
                  this.toastrService.addToastr("Error while retrieving options", "error", true);
              }
              this.loadingService.loading = false;
          });

      });






  }

  changeSelect(){
      this.loadingService.loading = true;

      this.total_distance_min = this.opt_cat_goals[this.option_goals_selected_cat]["total_distance_min"];
      this.total_distance_max = this.opt_cat_goals[this.option_goals_selected_cat]["total_distance_max"];
      this.hi_distance_min = this.opt_cat_goals[this.option_goals_selected_cat]["hi_distance_min"];
      this.hi_distance_max = this.opt_cat_goals[this.option_goals_selected_cat]["hi_distance_max"];
      this.n_sprints_min = this.opt_cat_goals[this.option_goals_selected_cat]["n_sprints_min"];
      this.n_sprints_max = this.opt_cat_goals[this.option_goals_selected_cat]["n_sprints_max"];
      this.distance_sprint_min = this.opt_cat_goals[this.option_goals_selected_cat]["distance_sprint_min"];
      this.distance_sprint_max = this.opt_cat_goals[this.option_goals_selected_cat]["distance_sprint_max"];
      this.acc_min = this.opt_cat_goals[this.option_goals_selected_cat]["acc_min"];
      this.acc_max = this.opt_cat_goals[this.option_goals_selected_cat]["acc_max"];
      this.decc_min = this.opt_cat_goals[this.option_goals_selected_cat]["decc_min"];
      this.decc_max = this.opt_cat_goals[this.option_goals_selected_cat]["decc_max"];

      this.loadingService.loading = false;
  }

  save_cat_goals(){
      this.loadingService.loading = true;

      this.opt_cat_goals[this.option_goals_selected_cat]["total_distance_min"] = Number(this.total_distance_min);
      this.opt_cat_goals[this.option_goals_selected_cat]["total_distance_max"] = Number(this.total_distance_max);
      this.opt_cat_goals[this.option_goals_selected_cat]["hi_distance_min"] = Number(this.hi_distance_min);
      this.opt_cat_goals[this.option_goals_selected_cat]["hi_distance_max"] = Number(this.hi_distance_max);
      this.opt_cat_goals[this.option_goals_selected_cat]["n_sprints_min"] = Number(this.n_sprints_min);
      this.opt_cat_goals[this.option_goals_selected_cat]["n_sprints_max"] = Number(this.n_sprints_max);
      this.opt_cat_goals[this.option_goals_selected_cat]["distance_sprint_min"] = Number(this.distance_sprint_min);
      this.opt_cat_goals[this.option_goals_selected_cat]["distance_sprint_max"] = Number(this.distance_sprint_max);
      this.opt_cat_goals[this.option_goals_selected_cat]["acc_min"] = Number(this.acc_min);
      this.opt_cat_goals[this.option_goals_selected_cat]["acc_max"] = Number(this.acc_max);
      this.opt_cat_goals[this.option_goals_selected_cat]["decc_min"] = Number(this.decc_min);
      this.opt_cat_goals[this.option_goals_selected_cat]["decc_max"] = Number(this.decc_max);


      let fd = new FormData();
      fd.append('option_values', JSON.stringify(this.opt_cat_goals));
      fd.append('option_name', 'session_ideals');
      fd.append('user_id', this.authService.getUser()['id']);
      this.optionsService.saveOption(fd).subscribe(data=>{
          if(data["answer"] == 'success'){
              this.toastrService.addToastr("Options saved with success", "success", true);
          } else {
              this.toastrService.addToastr("Error while saving options", "error", true);
          }
          this.loadingService.loading = false;
      });


  }

  close(){
    this.dialogRef.close({ action: 'close' });
  }





}