import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { NavService } from '../../services/nav.service';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ProfileComponent } from '../../components/profile/profile.component';
import { ProjectsService } from '../../services/projects.service';
import { OptionsmodalComponent } from '../../modals/optionsmodal/optionsmodal.component';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  url = null;
  user_settings = false;
  season_picker = false;
  profileModal: MatDialogRef<ProfileComponent>;
  optionsModal: MatDialogRef<OptionsmodalComponent>;

  
  constructor(
    public dataService: DataService,
    public authService: AuthService,
    public navService: NavService,
    public projectsService: ProjectsService,
    public profileModalComponent: MatDialog,
    public optionsModalComponent: MatDialog,
    private router: Router,

    ) { }

  ngOnInit() {
    this.url = environment.baseUrl;
  }

  logout(){
    this.dataService.removeSelectedProject();
    this.authService.logout();
    this.router.navigate(['']);
  }

  changeProject(){
    this.dataService.removeSelectedProject();
    this.router.navigate(['']);
  }

  openProfileModal(){
    this.profileModal = this.profileModalComponent.open(ProfileComponent, { disableClose: true });
  }

  openOptionsModal(){
    this.optionsModal = this.optionsModalComponent.open(OptionsmodalComponent, { disableClose: true });
  }
  
  goToSearch(){
    this.projectsService.tab = "search";
  }


 

}
