import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DataService } from '../../services/data.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as $ from 'jquery';
import { environment } from '../../../environments/environment';
import { image } from 'html2canvas/dist/types/css/types/image';
import { ConfirmdeleteComponent } from '../../modals/confirmdelete/confirmdelete.component';
import { ProjectsService } from '../../services/projects.service';
import { ToastrService } from '../../services/toastr.service';
import { ModalServiceService } from '../../services/modal-service.service';


@Component({
  selector: 'app-training-resume',
  templateUrl: './training-resume.component.html',
  styleUrls: ['./training-resume.component.scss']
})
export class TrainingResumeComponent implements OnInit {

  session = null;
  evento = null;
  project = null;
  template = null;
  players;

  acc = 0;
  decc = 0;
  hi_runs = 0;
  mts_sprint = 0;
  nr_of_reps = 0;
  nr_sprints = 0;
  time = 0;
  total_distance = 0;

  active_recovery = [];
  injury_players = [];
  international_players = [];
  partial_players = [];
  recovery_players = [];
  reserve_players = [];
  training_players = [];
  final_training_players = [
    {
      label: 'Goalkeepers',
      players: []
    },
    {
      label: 'Defenders',
      players: []
    },
    {
      label: 'Midfielders',
      players: []
    },
    {
      label: 'Forwards',
      players: []
    }
  ];
  manual = false;

  confirmDeleteModal: MatDialogRef<ConfirmdeleteComponent>;
  constructor(public dataService: DataService,
              public dialogRef: MatDialogRef<TrainingResumeComponent>,
              public confirmDeleteModalComponent: MatDialog,
              public projectsService: ProjectsService,
              public toastrService: ToastrService,
              public modalService: ModalServiceService) { }

  ngOnInit() {
    this.project = this.dataService.getSelectedProject();
    this.template = JSON.parse(this.session.template_selected);
    if(this.template.hasOwnProperty('manual')){
      console.log(this.template);
      this.acc += parseInt(this.template.manual.acc);
      this.decc += parseInt(this.template.manual.decc);
      this.hi_runs += parseInt(this.template.manual.hi_runs);
      this.mts_sprint += parseInt(this.template.manual.mts_sprint);
      this.nr_sprints += parseInt(this.template.manual.nr_sprints);
      this.total_distance += parseInt(this.template.manual.total_distance);
      this.nr_of_reps = this.session.session_rpe;
      this.time = this.session.session_time;
      this.manual = true;
    } else {
      this.template.circuits.forEach(circuit => {
        circuit.exercicios.forEach(exercicio => {
          var vars = exercicio.gps_vars;
          this.acc += Math.round(vars.acc*exercicio.time*exercicio.reps);
          
          this.decc += parseInt(vars.decc);
          this.hi_runs += Math.round(vars.hi_runs*parseInt(exercicio.time)*parseInt(exercicio.reps)*10)/10;
          this.mts_sprint += Math.round(vars.mts_sprint*parseInt(exercicio.time)*parseInt(exercicio.reps)*10)/10;
          this.nr_sprints += Math.round(vars.nr_sprints*parseInt(exercicio.time)*parseInt(exercicio.reps)*10)/10;
          this.total_distance += (parseInt(vars.total_distance)*parseInt(exercicio.time)*parseInt(exercicio.reps));
        });
      });
      console.log(this.acc);
      this.nr_of_reps += parseInt(this.session.session_rpe);
      this.time += parseInt(this.session.session_time);
    }
   
    this.players = JSON.parse(this.session.players);
    this.active_recovery = this.players['active_recovery'];
    this.injury_players = this.players['injury_players'];
    this.international_players = this.players['international_players'];
    this.partial_players = this.players['partial_players'];
    this.recovery_players = this.players['recovery_players'];
    this.reserve_players = this.players['reserve_players'];
    this.training_players = this.players['training_players'];
    this.training_players.forEach(player => {
      if(player.position == "G"){
        this.final_training_players[0].players.push(player);
      }
      if(player.position == "D"){
        this.final_training_players[1].players.push(player);
      }
      if(player.position == "M"){
        this.final_training_players[2].players.push(player);
      }
      if(player.position == "F"){
        this.final_training_players[3].players.push(player);
      }
    });
  }

  close(){
    this.dialogRef.close({ action: 'close' });
  }

  getColsNumber(players){
    return 1;
    //return Math.ceil(players.length / 10);
  }

  toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          if(callback != null){
            callback(reader.result);
          } else {
            return reader.result;
          }
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
  
  round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}
  getRGBArray(cor){
    let cor_final = cor.substring(4);
    cor_final = cor_final.substring(0, cor_final.length - 1);
    let array_string = cor_final.split(",");
    let array = [];
    array_string.forEach(val => {
      array.push(parseInt(val));
    });
    return array;
  }

  async exportPDF(){
    var color = "";
    var logo = "../../assets/images/blacklogo.png";
    var self = this;
    let actual_height = 0;
    let players_height = 0;
    let max_height = 297;
    const doc = new jsPDF();
    let rgbArray = this.getRGBArray(self.evento.extendedProps.cor);
    doc.setFillColor(rgbArray[0],rgbArray[1],rgbArray[2]);
    doc.rect(0,0,69,21, 'F');
    doc.setFont("helvetica");
    doc.setFontStyle('bold');
    doc.setFontSize(14);
    doc.text("Session sheet", 22.29, 8.5); 
    doc.setFont("helvetica");
    doc.setFontStyle('normal');
    doc.text(self.evento.title, 22.29, 16.5);
    self.toDataUrl(logo, function(logo64) {
      doc.addImage(logo64, 'PNG', 4.145, 4.145, 14, 12.71);
      self.toDataUrl(self.project.project_team_logo, function(team64) {
        doc.addImage(team64, 'PNG', 190, 4.145, 14, 12.71);
        doc.setFontStyle('bold');
        doc.setFontSize(14);
        doc.text(self.session.data, 160, 12.5);
        doc.setFontStyle('bold');
        doc.setFontSize(8);
        var width = doc.getTextDimensions("RPE").w  * .35;
        var posX = ((16 - width)/2) + 4.145;
        doc.text("RPE", posX, 30.2);
        var width = doc.getTextDimensions("Session Time (min)").w  * .35;
        var posX = ((36 - width)/2) + 20.145;
        doc.text("Session Time (min)", posX, 30.2);
        var width = doc.getTextDimensions("Total Distance (m)").w  * .35;
        var posX = ((34 - width)/2) + 56.145;
        doc.text("Total Distance (m)", posX, 30.2); 
        var width = doc.getTextDimensions("Meters of Sprint (m)").w  * .35;
        var posX = ((34 - width)/2) + 90.145;
        doc.text("Meters of Sprint (m)", posX, 30.2); 
        var width = doc.getTextDimensions("HI Runs (m)").w  * .35;
        var posX = ((25 - width)/2) + 124.145;
        doc.text("HI Runs (m)", posX, 30.2); 
        var width = doc.getTextDimensions("ACC").w  * .35;
        var posX = ((16 - width)/2) + 149.145;
        doc.text("ACC", posX, 30.2); 
        var width = doc.getTextDimensions("DECC").w  * .35;
        var posX = ((16 - width)/2) + 165.145;
        doc.text("DECC", posX, 30.2); 
        var width = doc.getTextDimensions("Nº Sprints").w  * .35;
        var posX = ((25 - width)/2) + 181.145;
        doc.text("Nº Sprints", posX, 30.2); 
        doc.setFontStyle('normal');
        var width = doc.getTextDimensions(self.nr_of_reps.toString()).w  * .35;
        var posX = ((16 - width)/2) + 4.145;
        doc.text(self.nr_of_reps.toString(), posX, 35);
        var width = doc.getTextDimensions(self.time.toString()).w  * .35;
        var posX = ((36 - width)/2) + 20.145;
        doc.text(self.time.toString(), posX, 35);
        var width = doc.getTextDimensions(self.total_distance.toString()).w  * .35;
        var posX = ((34 - width)/2) + 56.145;
        doc.text(self.total_distance.toString(), posX, 35);
        var width = doc.getTextDimensions(self.mts_sprint.toString()).w  * .35;
        var posX = ((34 - width)/2) + 90.145;
        doc.text(self.mts_sprint.toString(), posX, 35);
        var width = doc.getTextDimensions(self.hi_runs.toString()).w  * .35;
        var posX = ((25 - width)/2) + 124.145;
        doc.text(self.hi_runs.toString(), posX, 35);
        var width = doc.getTextDimensions(self.acc.toString()).w  * .35;
        var posX = ((16 - width)/2) + 149.145;
        doc.text(self.acc.toString(), posX, 35); 
        var width = doc.getTextDimensions(self.decc.toString()).w  * .35;
        var posX = ((16 - width)/2) + 165.145;
        doc.text(self.decc.toString(), posX, 35); 
        var width = doc.getTextDimensions(self.nr_sprints.toString()).w  * .35;
        var posX = ((25 - width)/2) + 181.145;
        doc.text(self.nr_sprints.toString(), posX, 35); 
        actual_height = 40;
        players_height = 40; 
        if(self.training_players.length >0){
          doc.setFillColor(2, 243, 167);
          doc.rect(4.145, players_height, 35, 4, 'F');
          doc.setFontStyle('bold');
          doc.text("Training Players", 6.145, players_height+3); 
          doc.setFontStyle('normal');
          players_height+=4;
          self.training_players.forEach(player => {
            doc.text(player.player.common_name, 4.145, players_height+4); 
            players_height+=4;
          });
        }
        if(self.active_recovery.length >0){
          players_height+=5;
          doc.setFillColor(255, 237, 88);
          doc.rect(4.145, players_height, 35, 4, 'F');
          doc.setFontStyle('bold');
          doc.text("Active Recovery", 6.145, players_height+3); 
          doc.setFontStyle('normal');
          players_height+=4;
          self.active_recovery.forEach(player => {
            doc.text(player.player.common_name, 4.145, players_height+4); 
            players_height+=4;
          });
        }
        if(self.injury_players.length >0){
          players_height+=5;
          doc.setFillColor(253, 92, 92);
          doc.rect(4.145, players_height, 35, 4, 'F');
          doc.setFontStyle('bold');
          doc.text("Injury Players", 6.145, players_height+3); 
          doc.setFontStyle('normal');
          players_height+=4;
          self.injury_players.forEach(player => {
            doc.text(player.player.common_name, 4.145, players_height+4); 
            players_height+=4;
          });
        }
        if(self.international_players.length >0){
          players_height+=5;
          doc.setFillColor(54, 154, 253);
          doc.rect(4.145, players_height, 35, 4, 'F');
          doc.setFontStyle('bold');
          doc.text("International Duties", 6.145, players_height+3); 
          doc.setFontStyle('normal');
          players_height+=4;
          self.international_players.forEach(player => {
            doc.text(player.player.common_name, 4.145, players_height+4); 
            players_height+=4;
          });
        }
        if(self.partial_players.length >0){
          players_height+=5;
          doc.setFillColor(255, 156, 94);
          doc.rect(4.145, players_height, 35, 4, 'F');
          doc.setFontStyle('bold');
          doc.text("Partial Training", 6.145, players_height+3); 
          doc.setFontStyle('normal');
          players_height+=4;
          self.partial_players.forEach(player => {
            doc.text(player.player.common_name, 4.145, players_height+4); 
            players_height+=4;
          });
        }
        if(self.recovery_players.length >0){
          players_height+=5;
          doc.setFillColor(255, 156, 94);
          doc.rect(4.145, players_height, 35, 4, 'F');
          doc.setFontStyle('bold');
          doc.text("Recovery Players", 6.145, players_height+3); 
          doc.setFontStyle('normal');
          players_height+=4;
          self.recovery_players.forEach(player => {
            doc.text(player.player.common_name, 4.145, players_height+4); 
            players_height+=4;
          });
        }
        if(self.reserve_players.length >0){
          players_height+=5;
          doc.setFillColor(240, 118, 247);
          doc.rect(4.145, players_height, 35, 4, 'F');
          doc.setFontStyle('bold');
          doc.text("Reserve Players", 6.145, players_height+3); 
          doc.setFontStyle('normal');
          players_height+=4;
          self.reserve_players.forEach(player => {
            doc.text(player.player.common_name, 4.145, players_height+4); 
            players_height+=4;
          });
        }
        self.template.circuits.forEach( async (circuit, it1) => { 
          doc.setFillColor(20, 20, 20);
          doc.rect(50, actual_height, 160, 7, 'F');
          doc.setFontStyle('bold');
          doc.setFontSize(10);
          doc.setTextColor(255,255,255);
          doc.text("EXERCISE " + (it1+1), 123, actual_height + 5); 
          doc.setTextColor(0,0,0);
          doc.setFontSize(8);
          actual_height += 11;
          circuit.exercicios.forEach(async (exercise, it) => {
            let inc = 80 * it;
            if(inc > 80){
              inc = 0;
              actual_height += 35;
            } 
            const label = "Exercise "+(it + 1);
            doc.setFontStyle('normal');
            doc.text(label, 50 + inc, actual_height); 
            doc.setFontStyle('bold');
            doc.text(" - " +exercise.name, 63 + inc, actual_height); 
            var img = document.getElementById('exercise-'+exercise.id);
            doc.addImage(img, 50 + inc, actual_height + 2.5, 40, 25);
            let textBeginX = 50 + inc + 42;
            doc.setFontStyle('normal');
            doc.text("TD: " + exercise.gps_vars.total_distance, textBeginX, actual_height+5);
            doc.text("Hi Runs: " + exercise.gps_vars.hi_runs, textBeginX, actual_height+9.5);
            doc.text("ACC: " + exercise.gps_vars.acc, textBeginX, actual_height+14);
            doc.text("DECC: " + exercise.gps_vars.decc, textBeginX, actual_height+18.5);
            doc.text("Sprints: " + exercise.gps_vars.nr_sprints, textBeginX, actual_height+23); 
            doc.text("Mts. Sprint: " + exercise.gps_vars.mts_sprint, textBeginX, actual_height+27.5); 
          }); 
          actual_height += 35;
          let inc = circuit.exercicios.length == 3 ? 80 : 40;
          if(actual_height + inc > max_height){
            doc.addPage();
            doc.setFillColor(rgbArray[0],rgbArray[1],rgbArray[2]);
            doc.rect(0,0,69,21, 'F');
            doc.setFont("helvetica");
            doc.setFontStyle('bold');
            doc.setFontSize(14);
            doc.text("Session sheet", 22.29, 8.5); 
            doc.setFont("helvetica");
            doc.setFontStyle('normal');
            doc.text(self.evento.title, 22.29, 16.5);
            doc.addImage(logo64, 'PNG', 4.145, 4.145, 14, 12.71);
            doc.addImage(team64, 'PNG', 190, 4.145, 14, 12.71);
            doc.setFontStyle('bold');
            doc.setFontSize(14);
            doc.text(self.session.data, 160, 12.5);
            actual_height = 30;
          }
        }); 
        let nr_of_pages = doc.internal.getNumberOfPages();
        let pages = doc.internal.pages;
        for (let it = 0; it < pages.length; it++) {
          doc.setPage(it);
          doc.setFontStyle('bold');
          doc.setFontSize(12);
          doc.setFillColor(255,255,255);
          doc.rect(90,280,50,15, 'F');
          doc.text(it+"/"+nr_of_pages, 100, 285);
        }
        setTimeout(() => {
          let doc_name = self.project.project_team+ " - "+ self.session.data + " - " + self.session.category_name.name+".pdf";
          doc.save(doc_name); 
        }, 1000);
      });
    });
    
  }

  
  addImageProcess(src){
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve(img)
      img.onerror = reject
      img.src = src
    })
  }

  deleteSession(){
    event.stopPropagation();
    this.modalService.modalOpen = true;
    this.confirmDeleteModal = this.confirmDeleteModalComponent.open(ConfirmdeleteComponent, { disableClose: true });
    let instance = this.confirmDeleteModal.componentInstance;
    let title = "Warning";
    let message = "Are you sure you want to delete this session?";
    let submessage = `If you delete this session, all the data inserted for each exercise and the respective training session will be permanently deleted.<br>
                      Moreover, this data will no longer be considered for calculating the exercise's average variables.<br>`;
    instance.title = title;
    instance.message = message;
    instance.submessage = submessage;
    this.confirmDeleteModal.afterClosed().subscribe((result) => {
      this.modalService.modalOpen = false;
      if(result.message == 'success'){
        let fd = new FormData();
        fd.append('id', this.session.id);
        this.projectsService.deleteSession(fd).subscribe(data=>{
          if(data == 'success'){
            this.toastrService.addToastr("Session deleted with success", "success");
            this.dialogRef.close({ action: 'delete' });
          } else {
            this.toastrService.addToastr("There was an error when deleting session", "error");
          }
        });
      }
    });
  }
}
