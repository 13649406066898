import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ProjectsService } from '../../services/projects.service';
import { ToastrService } from '../../services/toastr.service';
import { DataService } from '../../services/data.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ConfirmdeleteComponent } from '../../modals/confirmdelete/confirmdelete.component';
import { AddseasonComponent } from '../../modals/addseason/addseason.component';
import { ModalServiceService } from '../../services/modal-service.service';
import { LoadingService } from '../../services/loading.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  loading = true;
  projects = [];
  projectForm: FormGroup;
  countries = [];
  leagues = [];
  seasons = [];
  teams = [];
  team = null;
  confirmDeleteModal: MatDialogRef<ConfirmdeleteComponent>;
  addSeasonModal: MatDialogRef<AddseasonComponent>;


  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    public projectsService: ProjectsService,
    public toastrService: ToastrService,
    public dataService: DataService,
    public router: Router,
    public confirmDeleteModalComponent: MatDialog,
    public addSeasonModalComponent: MatDialog,
    public modalService: ModalServiceService,
    public loadingService:LoadingService
    ) { }

  ngOnInit() {
    this.getProjects();
    this.projectForm = this.fb.group({
      project_name: ['', [Validators.required, Validators.minLength(4)]],
      project_country: ['', [Validators.required]],
      project_league: ['', [Validators.required]],
      project_season: ['', [Validators.required]],
      project_team: ['', [Validators.required]]
    });
    this.getCountries();
  }

  getProjects(){
    this.loadingService.loading = true;
    let fd = new FormData();
    fd.append('id', this.authService.getUser()['id']);
    this.projectsService.getProjects(fd).subscribe(data => {
      if(data['answer'] == 'error'){
        this.toastrService.addToastr(data['message'], data['answer']);
      } else {
        this.projects = data['projects'];
      }
      this.loadingService.loading = false;
      this.loading = false;
    });
  }

  getCountries(){
    this.projectsService.getProjectsCountries().subscribe(data => {
      this.countries = data['data'];
    });
  }

  getLeagues(){
    let id = this.projectForm.get('project_country').value;
    this.projectsService.getCountryLeagues(id).subscribe(data =>{
      this.leagues = data['data'];
    });
  }

  getSeasons(){
    let id = this.projectForm.get('project_league').value;
    for (let it = 0; it < this.leagues.length; it++) {
      const league = this.leagues[it];
      if(id == league.id){
        this.seasons = league.seasons;
        break;
      } 
    }
  }

  getTeams(){
    let id = this.projectForm.get('project_season').value;
    this.projectsService.getTeamSeasons(id).subscribe(data =>{
      this.teams = data['data'];
    });
  }

  geTeamById(){
    let id = this.projectForm.get('project_team').value;
    this.projectsService.getTeamById(id).subscribe(data=>{
      this.team = data['data'];
    });
  }

  goToNewProject(){
    $("#projects_all_container .projects_page").css({
      transform: "translateX(-100vw)"
    });
  }

  goToProjects(){
    $("#projects_all_container .projects_page").css({
      transform: "translateX(0)"
    });
    this.projectForm.patchValue({
      project_name: '',
      project_country: '',
      project_league: '',
      project_season: '',
      project_team: ''
    });
  }

  createProject(){
    let project_name = this.projectForm.get('project_name').value;
    let project_country = this.getCountryName(this.projectForm.get('project_country').value);
    let project_country_id = this.projectForm.get('project_country').value;
    let project_league = this.getLeagueName(this.projectForm.get('project_league').value);
    let project_league_id = this.projectForm.get('project_league').value;
    let project_league_logo = this.getLeagueLogo(this.projectForm.get('project_league').value);
    let project_team = this.getTeamName(this.projectForm.get('project_team').value);
    let project_team_id = this.projectForm.get('project_team').value;
    let project_team_logo = this.team != null ? this.team.img : null;
    let project_season = this.getSeasonName(this.projectForm.get('project_season').value);
    let project_season_id = this.projectForm.get('project_season').value;
    let project_year_start = this.getSeasonName(this.projectForm.get('project_season').value).split("/")[0];
    let project_year_end = this.getSeasonName(this.projectForm.get('project_season').value).split("/")[1];
 

    if(project_name.trim() == ""){
      this.toastrService.addToastr("Fill the project's name", "error");
      return;
    }
    if(project_name.length < 4){
      this.toastrService.addToastr("The project's name must have, at least 4 characters", "error");
      return;
    }
    if(project_country.trim() == ""){
      this.toastrService.addToastr("Fill the project's country", "error");
      return;
    }
    if(project_league.trim() == ""){
      this.toastrService.addToastr("Fill the project's league", "error");
      return;
    }
    if(project_team.trim() == ""){
      this.toastrService.addToastr("Fill the project's team", "error");
      return;
    }
    if(project_season.trim() == ""){
      this.toastrService.addToastr("Fill the project's season", "error");
      return;
    }
    let project = {
      project_name: project_name,
      project_country: project_country,
      project_country_id: project_country_id,
      project_league: project_league,
      project_league_id: project_league_id,
      project_league_logo: project_league_logo,
      project_team: project_team,
      project_team_id: project_team_id,
      project_team_logo: project_team_logo,
      project_season: project_season,
      project_season_id: project_season_id,
      project_year_start: project_year_start,
      project_year_end: project_year_end,
      project_owner: this.authService.getUser()['id']
    };
    let fd = new FormData();
    fd.append("project", JSON.stringify(project));
    this.projectsService.createProject(fd).subscribe(data => {
      if(data['answer'] == "error"){
        this.toastrService.addToastr(data['message'], data['answer']);
      } else {
        this.projectForm.patchValue({
          project_name: '',
          project_country: '',
          project_league: '',
          project_season: '',
          project_team: ''
        });
        this.toastrService.addToastr(data['message'], data['answer']);
        this.goToProjects();
        this.getProjects();
      }
    });
  }

  returnProjectNumber(number){
    return (number < 10 ? '0' + number : number);
  }

  selectProject(project){
    this.dataService.setSelectedProject(project);
    this.dataService.setPage('project');
  }

  deleteProject(project, event){
    event.stopPropagation();
    this.modalService.modalOpen = true;
    this.confirmDeleteModal = this.confirmDeleteModalComponent.open(ConfirmdeleteComponent, { disableClose: true });
    let instance = this.confirmDeleteModal.componentInstance;
    let title = "Warning";
    let message = "Are you sure you want to delete '"+project.project_name+"´s' project?";
    let submessage = `If you delete this project, you will lose all information stored in training and game sheets.<br>
                      Your saved exercise information will not be affected. <br>`;
    instance.title = title;
    instance.message = message;
    this.confirmDeleteModal.afterClosed().subscribe((result) => {
      this.modalService.modalOpen = false;
      if(result.message == 'success'){
        this.loading = true;
        let fd = new FormData();
        fd.append('id', project.id);
        this.projectsService.deleteProject(fd).subscribe(data=>{
          if(data == 'success'){
            this.toastrService.addToastr("Project deleted with success", "success");
          } else {
            console.log(data);
            this.toastrService.addToastr("There was an error when deleting project", "error");
          }
          this.getProjects();
        });
      }
    });
  }

  addSeason(project, event){
    event.stopPropagation();
    this.modalService.modalOpen = true;
    this.addSeasonModal = this.addSeasonModalComponent.open(AddseasonComponent, {disableClose: true});
    let instance = this.addSeasonModal.componentInstance;
    instance.project = project; 
    this.addSeasonModal.afterClosed().subscribe((result) => {
      this.modalService.modalOpen = false;
      this.getProjects();
    });
  }

  // GETTERS
  get project_name() {
    return this.projectForm.get('project_name');
  }
  get project_country() {
    return this.projectForm.get('project_country');
  }
  get project_league() {
    return this.projectForm.get('project_league');
  }
  get project_season() {
    return this.projectForm.get('project_season');
  }
  get project_team() {
    return this.projectForm.get('project_team');
  }
  getCountryName(id){
    let countryName = "";
    for (let it = 0; it < this.countries.length; it++) {
      const country = this.countries[it];
      if(country.id == id){
        countryName = country.name;
        break;
      }
    }
    return countryName;
  }
  getLeagueName(id){
    let leagueName = "";
    for (let it = 0; it < this.leagues.length; it++) {
      const league = this.leagues[it];
      if(league.id == id){
        leagueName = league.name;
        break;
      }
    }
    return leagueName;
  }
  getLeagueLogo(id){
    let leagueLogo = "";
    for (let it = 0; it < this.leagues.length; it++) {
      const league = this.leagues[it];
      if(league.id == id){
        leagueLogo = league.img;
        break;
      }
    }
    return leagueLogo;
  }
  getSeasonName(id){
    let seasonName = "";
    for (let it = 0; it < this.seasons.length; it++) {
      const season = this.seasons[it];
      if(season.id == id){
        seasonName = season.name;
        break;
      }
    }
    return seasonName;
  }
  getTeamName(id){
    let teamName = "";
    for (let it = 0; it < this.teams.length; it++) {
      const team = this.teams[it];
      if(team.id == id){
        teamName = team.name;
        break;
      }
    }
    return teamName;
  }
  getTeamNameLogo(id){
    let teamLogo = "";
    for (let it = 0; it < this.teams.length; it++) {
      const team = this.teams[it];
      if(team.id == id){
        break;
      }
    }
    return teamLogo;
  }
}