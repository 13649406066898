import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  constructor(private http: HttpClient) { }

  getPlayerStats(player_id, season_id){
    return this.http.get("https://api.soccersapi.com/v2.2/stats?user=nuno&token=6ef325183ef9cb2b665b758d21fa861d&t=player&id="+player_id+"&season_id="+season_id.toString());
  }
}
