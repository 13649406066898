import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs/index';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  selected_project = null;
  season_subscriver = new Subject<number>();
  selected_season = 0;
  APIurl = environment.baseUrl;
  page = this.selected_project == null ? 'dashboard' : 'project';
  constructor() { }

  getSelectedProject() {
    return this.selected_project;
  }

  setSelectedProject(project) {
    this.selected_project = project;
  }
  
  getPage() {
    return this.page;
  }

  removeSelectedProject(){
    this.selected_project = null;
    this.page = 'dashboard';
  }

  setPage(page){
    this.page = page;
  }

  changeSeason(it){
    this.selected_season=it;
    this.season_subscriver.next(this.selected_season);
  }

  getSeason() {
    return this.season_subscriver;
  }

  getBigImg(path){
    let finalPath = path;
    if(!finalPath.includes('avatar.png'))
    {
      finalPath = finalPath.split("/soccer/players/");
      finalPath = finalPath[0]+"/soccer/players/100"+finalPath[1].substring(2);
    }
    return finalPath;

  }
  s

  getImg(player){
    player.player.img = 'assets/images/avatar.png';
  }


 


}
