import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from '../../services/toastr.service';
import { MatDialog, MatDialogRef } from '@angular/material';

import * as $ from 'jquery';
import * as sal from 'sal.js';
import { LoginComponent } from '../login/login.component';


@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss']
})

export class LandingpageComponent implements OnInit {
  user = '';
  pass = '';
  login: MatDialogRef<LoginComponent>;

  constructor(public authService: AuthService,
    public toastrService: ToastrService,
    public loginModalComponent: MatDialog
    ) { }

  ngOnInit() {
    sal({});
    $(window).scroll(function (event) {
      var scroll = $(window).scrollTop();
      if(scroll > 120){
        $( "#barra-nav" ).removeClass('altura-grande');
        $( "#barra-nav" ).addClass('altura-pequena');
      }else if(scroll == 0){
        $( "#barra-nav" ).removeClass('altura-pequena');
        $( "#barra-nav" ).addClass('altura-grande');
      }
    });
    $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
        && 
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top
          }, 1000, function() {
            // Callback after animation
            // Must change focus!
            var $target = $(target);
            $target.focus();
            if ($target.is(":focus")) { // Checking if the target was focused
              return false;
            } else {
              $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
              $target.focus(); // Set focus again
            };
          });
        }
      }
    });
  
    $('nav ul li a:not(:only-child)').click(function(e) {
      $(this).siblings('.nav-dropdown').toggle();
      // Close one dropdown when selecting another
      $('.nav-dropdown').not($(this).siblings()).hide();
      e.stopPropagation();
    });
    // Clicking away from dropdown will remove the dropdown class
    $('html').click(function() {
      $('.nav-dropdown').hide();
    });
    // Toggle open and close nav styles on click
    $('#nav-toggle').click(function() {
      $('nav ul').slideToggle();
    });
    // Hamburger to X toggle
    $('#nav-toggle').on('click', function() {
      this.classList.toggle('active');
    });
  }
 


 dologin(){
    this.login = this.loginModalComponent.open(LoginComponent, { disableClose: true });
  }

}
