import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirmdelete',
  templateUrl: './confirmdelete.component.html',
  styleUrls: ['./confirmdelete.component.scss']
})
export class ConfirmdeleteComponent implements OnInit {
  title = null;
  message = null;
  submessage = null;
  type="delete";
  text_button = "Confirm";
  constructor(public dialogRef: MatDialogRef<ConfirmdeleteComponent>
              ) { }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close({ action: 'cancel' });
  }

  confirm() {
    this.dialogRef.close({ message: 'success' });
  }

}
