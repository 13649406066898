import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NavService } from '../services/nav.service';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user = localStorage.getItem('user') == null ? null : JSON.parse(localStorage.getItem('user'));

  constructor(
    private http: HttpClient,
    public navService: NavService) { 
  }

  getUser(){
    return this.user;
  }

  updateUser(user){
    this.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  }

  tryLogin(fd){
    return this.http.post(environment.baseUrl + '/auth/login.php', fd);
  }

  editUser(fd){
    return this.http.post(environment.baseUrl + '/auth/edit_user.php', fd);
  }

  editCredentials(fd){
    return this.http.post(environment.baseUrl + '/auth/edit_credentials.php', fd);
  }

  logout(){
    this.navService.open = false;
    localStorage.removeItem('user');
    this.user = null;
  }

}
