import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ProjectsService } from '../../services/projects.service';
import { ToastrService } from '../../services/toastr.service';

@Component({
  selector: 'app-addseason',
  templateUrl: './addseason.component.html',
  styleUrls: ['./addseason.component.scss']
})
export class AddseasonComponent implements OnInit {

  project = null;
  loading = true;
  seasons = [];
  constructor(public dialogRef: MatDialogRef<AddseasonComponent>,
              public projectsService: ProjectsService,
              public toastrService: ToastrService) { }

  ngOnInit() {
    this.getLeagueSeasons();
  }

  
  getLeagueSeasons(){
    this.projectsService.getLeagueById(this.project.project_league_id).subscribe(data => {
      this.seasons = data['data']['seasons'];
      this.loading = false;
    });
  }

  seasonStillAvailable(season){
    let visible = true;
    for (let it = 0; it < this.project.seasons.length; it++) {
      const temp_season = this.project.seasons[it];
      if(temp_season.project_season_id == season.id){
        visible = false;
        break;
      }
    }
    return visible;
  }

  getSeasonName(season){
    let season_name = season.name;
    let first_year = season_name.split("/")[0];
    let last_year = season_name.split("/")[1];
    return '20'+first_year + "/20"+last_year;
  }
  
  addSeason(season){
    let first_year = "20"+season.name.split("/")[0];
    let last_year = "20"+season.name.split("/")[1];    
    let obj = {
      id_project: this.project.id,
      project_season: season.name,
      project_season_id: season.id,
      project_year_start: first_year,
      project_year_end: last_year,      
    };
    var fd = new FormData();
    fd.append("id_project", this.project.id);
    fd.append("project_season", season.name);
    fd.append("project_season_id", season.id);
    fd.append("project_year_start", first_year);
    fd.append("project_year_end", last_year);
    this.projectsService.addSeason(fd).subscribe(data=>{
      if(data == 'success'){
        this.project.seasons.push(obj);
        this.toastrService.addToastr("Season successfully added", "success");
      }else{
        this.toastrService.addToastr("", "error");
      }
    });
  }

  close(){
    this.dialogRef.close({ action: 'close' });
  }

}
