import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  constructor(private http: HttpClient) { }

  getGameStats(fixture_id){
    return this.http.get("https://api.soccersapi.com/v2.2/stats/?user=nuno&token=6ef325183ef9cb2b665b758d21fa861d&t=match&id="+fixture_id);
  }

  getGameLineUps(fixture_id){
    return this.http.get("https://api.soccersapi.com/v2.2/fixtures/?user=nuno&token=6ef325183ef9cb2b665b758d21fa861d&t=match_lineups&id="+fixture_id);
  }

  getGameEvents(fixture_id){
    return this.http.get("https://api.soccersapi.com/v2.2/fixtures/?user=nuno&token=6ef325183ef9cb2b665b758d21fa861d&t=match_events&id="+fixture_id);
  }

  getGameVars(game_id){
    return this.http.post(environment.baseUrl + '/games/getgamevars.php', game_id);
  }

  async asyncGetGameVars(game_id){
    let data = await this.http.post(environment.baseUrl + '/games/getgamevars.php', game_id).toPromise();
    return data;
  }

  setGameVars(vars){
    return this.http.post(environment.baseUrl + '/games/setgamevars.php', vars);
  }

  getGameVarsFromSeasonAndTeam(fd){
    return this.http.post(environment.baseUrl + '/games/getSeaonsTeamVars.php', fd);
  }
}
