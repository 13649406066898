import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ExercisesService } from '../../services/exercises.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from '../../services/toastr.service';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import * as $ from 'jquery';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ModalServiceService } from '../../services/modal-service.service';
import { ExerciseComponent } from '../../modals/exercise/exercise.component';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-exercises',
  templateUrl: './exercises.component.html',
  styleUrls: ['./exercises.component.scss']
})
export class ExercisesComponent implements OnInit {

  constructor(public exercisesService: ExercisesService,
              private fb: FormBuilder,
              public toastrService: ToastrService,
              public authService: AuthService,
              public dataService: DataService,
              public modalService: ModalServiceService,
              public operateExerciseModalComponent: MatDialog,
              public loadingService: LoadingService) { }

  tab = "Database";
  database_tab = "";
  database_sub_cat = "";
  database_sub_sub_cat = "";
  database_exercise_tab = "info";
  selectedExercise = null;
  categories = [];
  exerciceForm: FormGroup;
  exercise_foto = null;
  exercises;
  showtips = false;
  @Input() isModal: Boolean;
  @Output() exercise_to_emit = new EventEmitter();
  searchSubscriber = null;
  results;

  operateExerciseModal: MatDialogRef<ExerciseComponent>;

  searchString = "";
  selectedSearchExercise = null;

  ngOnInit() {
    if(this.isModal){
      $(".cdk-overlay-pane").addClass('full');
    }
    this.getCategories();
    this.exerciceForm = this.fb.group({
      exercise_name: ['', [Validators.required, Validators.minLength(4)]],
      exercise_category: ['', [Validators.required]],
      exercise_subcategory: ['', [Validators.required]],
      exercise_subssubcategory: ['', []],
    });
  }

  getCategories(){
    if(this.exercisesService.mainCategories == null){
      this.categories = this.exercisesService.mainCategories;
      this.database_tab = this.categories['main'][0].name;
      this.getExercises();
    } else {
      this.loadingService.loading = true;
      this.exercisesService.getExerciseCategories().subscribe(data=> {
        this.loadingService.loading = false;
        this.categories = data;
        this.database_tab = this.categories['main'][0].name;
        this.getExercises();
      });
    }
  }

  anyTip(){
    let show = true;
    for (let it = 0; it < this.categories['sub_sub'].length; it++) {
      const cat_name = this.categories['sub_sub'][it].name;
      const val = this.exerciceForm.get('exercise_subssubcategory').value;
      if(cat_name.toUpperCase().includes(val.toUpperCase())){
        show = false;
        break;
      }
    }
    return show;
  }

  checkSubSubCategory(sub_sub_cat){
    let visible = false;
    if(sub_sub_cat.name.toUpperCase().includes(this.exercise_subssubcategory.value.toUpperCase()) 
       && sub_sub_cat.sub_category == this.exercise_subcategory.value

      //  && this.exercise_subssubcategory.value.toUpperCase().length > 0
       )
       {
      visible = true;
    }
    return visible;
  } 

  hideTips(){  
    setTimeout(() => {
      this.showtips = false;  
    }, 200);
  }

  setTipValue(value, event){
    event.stopPropagation();
    this.exerciceForm.patchValue({
      exercise_subssubcategory: value
    });
  }

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
    this.exercise_foto = inputNode.files[0];
  }
  
  createExercise(){
    let exercise_name = this.exerciceForm.get('exercise_name').value;
    let exercise_category = this.exerciceForm.get('exercise_category').value;
    let exercise_sub_category = this.exerciceForm.get('exercise_subcategory').value;
    let exercise_sub_sub_category = this.exerciceForm.get('exercise_subssubcategory').value;
    if(exercise_name.trim() == ""){
      this.toastrService.addToastr("Fill the exercises's name", "error");
      return;
    }
    if(exercise_category.trim() == ""){
      this.toastrService.addToastr("Fill the exercises's category", "error");
      return;
    }
    if(exercise_sub_category.trim() == ""){
      this.toastrService.addToastr("Fill the exercises's secondary category", "error");
      return;
    }
    if(this.exercise_foto == null){
      this.toastrService.addToastr("Define the exercise's photo", "error");
      return;
    }
    let exercise = {
      user_id: this.authService.getUser()['id'],
      exercise_name: exercise_name, 
      exercise_category: exercise_category, 
      exercise_sub_category: exercise_sub_category, 
      exercise_sub_sub_category: exercise_sub_sub_category
    };
    let fd = new FormData();
    fd.append("exercise", JSON.stringify(exercise));
    fd.append("exercise_photo", this.exercise_foto, this.exercise_foto.name);
    this.loadingService.loading = true;
    this.exercisesService.createExercise(fd).subscribe(data => {
      this.loadingService.loading = false;
      if(data == "success"){
        this.toastrService.addToastr("Exercise successfully created", 'success');
        this.exerciceForm.patchValue({
          exercise_name: '',
          exercise_category: '',
          exercise_subcategory: '',
          exercise_subssubcategory: ''
        });
        this.exercise_foto = null;
        this.getCategories();
      } else {
        this.toastrService.addToastr("An error ocurred while creating the exercise", 'error');
      }
    });
  }

  getExercises(){
    this.loadingService.loading = true;
    let fd = new FormData();
    fd.append('user_id', this.authService.getUser()['id']);
    fd.append('tab', this.database_tab);
    this.exercisesService.getExercisesByCat(fd).subscribe(data=> {
      this.exercises = data;
      this.loadingService.loading = false;
    });
  }

  exercise_is_visible_without_cat(exercise){
    let visible = false;
    if(this.database_tab == exercise.main_category_name 
       && this.database_sub_cat == exercise.sub_category_id
       && exercise.sub_sub_category_id == null){
         visible = true;
       }
      return visible;
  }

  exercise_is_visible_with_cat(exercise){
    let visible = false;
    if(this.database_tab == exercise.main_category_name 
       && this.database_sub_cat == exercise.sub_category_id
       && this.database_sub_sub_cat == exercise.sub_sub_category_id){
         visible = true;
       }
      return visible;
  }

  cancelar() {
    this.exercise_to_emit.emit({"action": "cancelar"});
  }

  onTabChanged(evt){
    this.tab = evt.tab.textLabel;
  }

  escolher() {
    if(this.tab == 'Database'){
      this.exercise_to_emit.emit({"action": "escolher", "exercicio":this.selectedExercise});
    } else {
      this.exercise_to_emit.emit({"action": "escolher", "exercicio":this.selectedSearchExercise});
    }
  }

  searchExercises(){
    if(this.searchString.length > 2){
      let fd = new FormData();
      fd.append('string', this.searchString);
      fd.append('user', this.authService.getUser()['id']);
      if (this.searchSubscriber !== null) {
        this.searchSubscriber.unsubscribe();
      }
      this.loadingService.loading = true;
      this.searchSubscriber = this.exercisesService.searchExercise(fd).subscribe(data=>{
        this.loadingService.loading = false;
        this.results = data;
        this.searchSubscriber = null;
      });
    }
  }

  openSettings(selectedExercise){
    this.operateExerciseModal = this.operateExerciseModalComponent.open(ExerciseComponent, { disableClose: true });
    this.modalService.modalOpen = true;
    let instance = this.operateExerciseModal.componentInstance;
    instance.exercise = selectedExercise;
    instance.categories = this.categories;
    this.operateExerciseModal.afterClosed().subscribe((result) => {
      this.modalService.modalOpen = false;
      if(result.action == "edit"){
        this.toastrService.addToastr("Exercise edited successfully", "success");
        this.selectedExercise = null;
        this.selectedSearchExercise = null;
        this.searchExercises();
        this.ngOnInit();
      } else if(result.action == "delete"){
        this.toastrService.addToastr("Exercise deleted successfully", "success");
        this.selectedExercise = null;
        this.selectedSearchExercise = null;
        this.searchExercises();
        this.ngOnInit();
      }
    });
  }

  // GETTERS
  get exercise_name() {
    return this.exerciceForm.get('exercise_name');
  }
  get exercise_category() {
    return this.exerciceForm.get('exercise_category');
  }
  get exercise_subcategory() {
    return this.exerciceForm.get('exercise_subcategory');
  }
  get exercise_subssubcategory() {
    return this.exerciceForm.get('exercise_subssubcategory');
  }
}
