import { Component, OnInit } from '@angular/core';
import { ExercisesService } from '../../services/exercises.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from '../../services/toastr.service';
import { SessionService } from '../../services/session.service';
import { DataService } from '../../services/data.service';
import { ModalServiceService } from '../../services/modal-service.service';
import { TrainingResumeComponent } from '../../modals/training-resume/training-resume.component';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  constructor(public exercisesService: ExercisesService,
              public toastrService: ToastrService,
              public sessionService: SessionService,
              private fb: FormBuilder,
              public modalService: ModalServiceService,
              public trainingModalComponent: MatDialog,
              public dataService: DataService) { }
 
  searchForm: FormGroup;
  loading_cats = true;
  loading = false;
  search_categories = null;
  sessions;
  trainingModal: MatDialogRef<TrainingResumeComponent>;

  ngOnInit() {
    this.getCategories();
    this.searchForm = this.fb.group({
      dateFrom: ['', []],
      dateUntil: ['', []],
      mainCat: ['', []],
      distanceFrom: ['', []],
      distanceUntil: ['', []],
      hiRunsFrom: ['', []],
      hiRunsUntil: ['', []],
      nrSprintsFrom: ['', []],
      nrSprintsUntil: ['', []],
      mtsSprintFrom: ['', []],
      mtsSprintUntil: ['', []],
      RPEFrom: ['', []],
      RPEUntil: ['', []]
    });
  }

  getCategories(){
    if(this.exercisesService.mainCategories == null){
      this.search_categories = this.exercisesService.mainCategories['main'];
      this.search_categories.push({id:0, name:"Not selected"});
      this.loading_cats = false;
    } else {
      this.exercisesService.getMainCategories().subscribe(data=> {
        this.search_categories = data['main'];
        this.search_categories.push({id:0, name:"Not selected"});
        this.loading_cats = false;
      });
    }
  }

  search(){
    const obj  = this.searchForm.getRawValue();
    if(obj.dateFrom.replace(/ /g, '') != "" 
       && obj.dateUntil.replace(/ /g, '') != "" 
       && obj.dateUntil <= obj.dateFrom){
        this.toastrService.addToastr("The until date must be grater than from date", "error");
        return;
    }
    if(obj.distanceFrom != "" && obj.distanceFrom != null
       && obj.distanceUntil != "" && obj.distanceUntil != null
       && obj.distanceUntil <= obj.distanceFrom){
       this.toastrService.addToastr("The until distance must be grater than from distance", "error");
       return;
    }
    if(obj.hiRunsFrom != "" && obj.hiRunsFrom != null 
       && obj.hiRunsUntil != "" && obj.hiRunsUntil != null 
       && obj.hiRunsUntil <= obj.hiRunsFrom){
       this.toastrService.addToastr("The until HI Runs must be grater than from HI Runs", "error");
       return;
    }
    if(obj.nrSprintsFrom != "" && obj.nrSprintsFrom != null 
       && obj.nrSprintsUntil != "" && obj.nrSprintsUntil != null 
       && obj.nrSprintsUntil <= obj.nrSprintsFrom){
       this.toastrService.addToastr("The until Number of Sprints must be grater than from Number of Sprints", "error");
       return;
    }
    if(obj.mtsSprintFrom != "" && obj.mtsSprintFrom != null 
       && obj.mtsSprintUntil != "" && obj.mtsSprintUntil != null 
       && obj.mtsSprintUntil <= obj.mtsSprintFrom){
       this.toastrService.addToastr("The until meters of Sprints must be grater than from meters of Sprints", "error");
       return;
    }
    if(obj.RPEFrom != "" && obj.RPEFrom != null 
       && obj.RPEUntil != "" && obj.RPEUntil != null 
       && obj.RPEUntil <= obj.RPEFrom){
       this.toastrService.addToastr("The until RPE must be grater than from RPE", "error");
       return;
    }
   
    let fd = new FormData();
    for (var [key, value] of Object.entries(obj)) {
      if((key == "dateFrom" || key == "dateUntil") 
          && obj[key].replace(/ /g, '') !== ""){
        fd.append(key, obj[key]);
      } else if(key !== "dateFrom" && key !== "dateUntil" && obj[key] !== "" && obj[key] !== null){
        fd.append(key, obj[key]);
      }
    }

    fd.append("p_id", this.dataService.getSelectedProject()['id']);
    this.loading = true;
    this.sessionService.searchService(fd).subscribe(data => {
      this.loading = false;
      this.sessions = data;
      this.sessions.forEach(session => {
        let category = this.getMainCategoryById(session.category);
        session.category_name = category;
      });
    });
  }

  getMainCategoryById(id){
    let category;
    for (let it = 0; it < this.search_categories.length; it++) {
      const cat = this.search_categories[it];
      if(id == cat.id){
        category = cat;
        break;
      }
    }
    return category;
  }
  
  openSession(session){
    this.modalService.modalOpen = true;
    this.trainingModal = this.trainingModalComponent.open(TrainingResumeComponent, { disableClose: true });
    let instance = this.trainingModal.componentInstance;
    instance.session = session;
    instance.evento = null;
    this.trainingModal.afterClosed().subscribe((result) => {
      this.modalService.modalOpen = false;
    });
  }

  // GETTERS
  get dateFrom() {
    return this.searchForm.get('dateFrom');
  }
  get dateUntil() {
    return this.searchForm.get('dateUntil');
  }
  get mainCat() {
    return this.searchForm.get('mainCat');
  }
  get distanceFrom() {
    return this.searchForm.get('distanceFrom');
  }
  get distanceUntil() {
    return this.searchForm.get('distanceUntil');
  }
  get nrSprintsFrom() {
    return this.searchForm.get('nrSprintsFrom');
  }
  get nrSprintsUntil() {
    return this.searchForm.get('nrSprintsUntil');
  }
  get mtsSprintFrom() {
    return this.searchForm.get('mtsSprintFrom');
  }
  get mtsSprintUntil() {
    return this.searchForm.get('mtsSprintUntil');
  }
  get RPEFrom() {
    return this.searchForm.get('RPEFrom');
  }
  get RPEUntil() {
    return this.searchForm.get('RPEUntil');
  }
}
