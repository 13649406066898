import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { PlayerService } from '../../services/player.service';
import { MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit {
  player = null;
  allData;
  constructor(public dataService: DataService,
              public playerService: PlayerService,
              public dialogRef: MatDialogRef<PlayerComponent>) { }

  ngOnInit() {
    let project = this.dataService.getSelectedProject();
    let player_id = this.player.player.id;
    let season_id = project.seasons[0].project_season_id;
    this.playerService.getPlayerStats(player_id, season_id).subscribe(data=>{
      this.allData = data['data'];
      console.log(this.allData);
    });
  }



  close(){
    this.dialogRef.close({ action: 'close' });
  }
}
