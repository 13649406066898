import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { SquadService } from '../../services/squad.service';
import { element } from 'protractor';
import { MatDialog, MatDialogRef } from '@angular/material';
import { PlayerComponent } from '../../modals/player/player.component';
import { ToastrService } from '../../services/toastr.service';
import { SessionService } from '../../services/session.service';
import * as moment from 'moment';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-squad',
  templateUrl: './squad.component.html',
  styleUrls: ['./squad.component.scss']
})
export class SquadComponent implements OnInit {

  constructor(public dataService: DataService,
              public playerModalComponent: MatDialog,
              public squadService: SquadService,
              public toastrService: ToastrService,
              public sessionService: SessionService,
              public loadingService: LoadingService) { }
  
  squad = [];
  goalkeepers = [];
  defensors = [];
  middlefielders = [];
  attackers = [];
  @Input() season: any;

  playerModal:MatDialogRef<PlayerComponent>;

  ngOnInit() {
    // this.getSquad(this.season); 
  }

  ngOnChanges(changes) {  
    this.getSquad(this.season);
  }

  getSquad(season){
    this.loadingService.loading = true;
    let project = this.dataService.getSelectedProject();
    this.squadService.getSquad(project.project_team_id, project.seasons[season].project_season_id).subscribe(data=>{
      this.squad = [];
      if(data.hasOwnProperty('data')){
        if(data['data'].hasOwnProperty('squad')){
          this.squad = data['data']['squad'];
        } else {
          this.squad = data['data'];
        }
      } else {
        this.toastrService.addToastr("Error while getting squad","error");
      }
      this.processSquad();
    });
  }
  
  processSquad(){
    var fd = new FormData();
    fd.append('id', this.dataService.getSelectedProject().id);
    fd.append('season_id', this.dataService.selected_project.seasons[this.season].id);
    this.sessionService.getSessions(fd).subscribe(data=>{
      let allTime = 0;
      let sessions = data as Array<any>;
      sessions.forEach(session => {
        allTime += Number(session.session_time);
        let players = session.players != null ? JSON.parse(session.players) : null;
        if(players != null){
          for (let it1 = 0; it1 < this.squad.length; it1++) {
            let squad_element = this.squad[it1];
            let isFound = false;
            for (let it = 0; it < players.active_recovery.length; it++) {
              const active_recover = players.active_recovery[it];
              if(active_recover.player.id == squad_element.player.id){
                const str = active_recover.duration;
                const m = moment(str, "H:mm");
                var minutes = (m.hour()*60) + m.minute();
                if(squad_element.hasOwnProperty('sessions_played_minutes')){
                  squad_element['sessions_played_minutes'] += Number(minutes);
                } else {
                  squad_element['sessions_played_minutes'] = Number(minutes);
                }
                if(squad_element.hasOwnProperty('sessions_played')){
                  squad_element['sessions_played'] += 1;
                } else {
                  squad_element['sessions_played'] = 1;
                }
                isFound = true;
                break;
              } 
            }
            if(isFound){
              break;
            } else {
              for (let it = 0; it < players.partial_players.length; it++) {
                const partial_player = players.partial_players[it];
                if(partial_player.player.id == squad_element.player.id){
                  const str = partial_player.duration;
                  const m = moment(str, "H:mm");
                  var minutes = (m.hour()*60) + m.minute();
                  if(squad_element.hasOwnProperty('sessions_played_minutes')){
                    squad_element['sessions_played_minutes'] += Number(minutes);
                  } else {
                    squad_element['sessions_played_minutes'] = Number(minutes);
                  }
                  if(squad_element.hasOwnProperty('sessions_played')){
                    squad_element['sessions_played'] += 1;
                  } else {
                    squad_element['sessions_played'] = 1;
                  }
                  isFound = true;
                  break;
                } 
              }
              if(isFound){
                break;
              } else {
                for (let it = 0; it < players.training_players.length; it++) {
                  const training_player = players.training_players[it];
                  if(training_player.player.id == squad_element.player.id){
                    if(squad_element.hasOwnProperty('sessions_played_minutes')){
                      squad_element['sessions_played_minutes'] += Number(session.session_time);
                    } else {
                      squad_element['sessions_played_minutes'] = Number(session.session_time);
                    }
                    if(squad_element.hasOwnProperty('sessions_played')){
                      squad_element['sessions_played'] += 1;
                    } else {
                      squad_element['sessions_played'] = 1;
                    }
                    isFound = true;
                    break;
                  } 
                }
              }
            }
          }
        }
      });
      this.goalkeepers = [];
      this.defensors = [];
      this.middlefielders = [];
      this.attackers = [];
      this.squad.forEach(element => {
        if(!element.hasOwnProperty('sessions_played')){
          element['sessions_played'] = 0 + "/" + sessions.length;
        } else {
          element['sessions_played'] += '/'+ sessions.length;
        }
        if(!element.hasOwnProperty('sessions_played_minutes')){
          element['sessions_played'] = 0;
        }
        if(element.position == 'G'){
          this.goalkeepers.push(element);
        }
        if(element.position == 'D'){
          this.defensors.push(element);
        }
        if(element.position == 'M'){
          this.middlefielders.push(element);
        }
        if(element.position == 'F'){
          this.attackers.push(element);
        }
      });
      this.loadingService.loading = false;
    });
  }

  openPlayer(player){
    this.playerModal = this.playerModalComponent.open(PlayerComponent, { disableClose: true });
    let instance = this.playerModal.componentInstance;
    instance.player = player;
  }

}
