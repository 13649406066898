import { Component, ViewChild, OnInit, HostListener } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ExercisesService } from '../../services/exercises.service';
import { ProjectsService } from '../../services/projects.service';
import { NavService } from '../../services/nav.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import { SessionService } from '../../services/session.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ActiondateComponent } from '../../modals/actiondate/actiondate.component';
import { SessionmodalComponent } from '../../modals/sessionmodal/sessionmodal.component';
import { GameComponent } from '../../modals/game/game.component';
import { TrainingResumeComponent } from '../../modals/training-resume/training-resume.component';
import { Calendar } from '@fullcalendar/core';
import { FullCalendarComponent } from "@fullcalendar/angular";
import { MicrocycleComponent } from '../../modals/microcycle/microcycle.component';
import { MicrocyclesService } from '../../services/microcycles.service';
import { ModalServiceService } from '../../services/modal-service.service';
import * as $ from 'jquery';
import * as moment from 'moment';
import { ConfirmdeleteComponent } from '../../modals/confirmdelete/confirmdelete.component';
import { LoadingService } from '../../services/loading.service';


@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],

})
export class ProjectComponent implements OnInit {

  fixtures = [];  
  calendarPlugins = [dayGridPlugin]; 
  loadingSchedule =  true;
  events = [];
  sessions;
  rests;
  micros;
  selectedData = null;
  categories;
  season_picker = false;

  actiondateModal: MatDialogRef<ActiondateComponent>;
  sessionModal: MatDialogRef<SessionmodalComponent>;
  gameModal: MatDialogRef<GameComponent>;
  trainingModal: MatDialogRef<TrainingResumeComponent>;
  microModal: MatDialogRef<MicrocycleComponent>; 
  confirmDeleteModal: MatDialogRef<ConfirmdeleteComponent>;

  @ViewChild("fullcalendar", { static: false}) calendarComponent: FullCalendarComponent;
  calendarApi: Calendar;

  constructor(
    public dataService: DataService,
    public projectsService: ProjectsService,
    public navService: NavService,
    public sessionService: SessionService,
    public actiondateModalComponent: MatDialog,
    public sessionModalComponent: MatDialog,
    public gameModalComponent: MatDialog,
    public exercisesService: ExercisesService,
    public trainingModalComponent: MatDialog,
    public microModalComponent: MatDialog,
    public confirmDeleteModalComponent: MatDialog,
    public microService: MicrocyclesService,
    public modalService: ModalServiceService,
    public loadingService:LoadingService
    ) { }
  

  @HostListener('document:click', ['$event'])
  onClick(evt) {
    if(this.projectsService.tab == 'schedule' && !this.modalService.modalOpen){
      var element = evt.target as HTMLElement;
      var parent = element.parentNode as HTMLElement;
      if(parent.classList.contains('fc-day-top')){
        this.selectedData = parent.getAttribute('data-date');
        this.addEvent(this.selectedData);
      }
    }
  } 

  ngOnInit() {
    this.loadingService.loading = true;
    this.projectsService.getTeamById(this.dataService.selected_project.project_team_id).subscribe(data=>{
      this.dataService.selected_project['leagues'] = data['data']['leagues'];
      if(this.dataService.getSelectedProject().seasons.length > 0){
        this.getCategories();
        this.dataService.getSeason().subscribe(data=> {
          this.getSchedule(this.dataService.selected_season,true);
        });
      }
    });
  }

  getCategories(){
    if(this.exercisesService.mainCategories == null){
      this.categories = this.exercisesService.mainCategories;
      this.getSchedule(this.dataService.selected_season);
    } else {
      this.exercisesService.getMainCategories().subscribe(data=> {
        this.categories = data;
        this.getSchedule(this.dataService.selected_season);
      });
    }
  }
  
  async getSchedule(season, change = false){
    let my_season = this.dataService.getSelectedProject().seasons[season];
    let season_id = this.dataService.getSelectedProject().seasons[season].project_season_id;
    let team_id = this.dataService.getSelectedProject().project_team_id;
    let final_leagues = [];
    for (let it = 0; it < this.dataService.selected_project['leagues'].length; it++) {
      const league = this.dataService.selected_project['leagues'][it];
      let final_league = await this.projectsService.getLeagueForTeam(league.id);
      if(final_league.hasOwnProperty('data')){
        final_leagues.push(final_league['data']);
      }
    }
    this.dataService.getSelectedProject().seasons.forEach(season => {
      const data_season = season.project_season;
      season.competitions = [];
      let competition = null;
      for (let it = 0; it < final_leagues.length; it++) {
        const final_league = final_leagues[it];
        for (let it2 = 0; it2 < final_league.seasons.length; it2++) {
          const league_season = final_league.seasons[it2];
          if(league_season.name == data_season ){
            if(season.project_season_id == league_season.id){
              season['img'] = final_league.img;
              season['name'] = final_league.name;
            } else {
              competition = {
                id: league_season.id,
                date : league_season.name,
                img: final_league.img,
                name: final_league.name
              };
              season.competitions.push(competition);
            }
          }
        }
      } 
    });
    this.fixtures = [];
    this.projectsService.getFixtures(season_id, team_id).subscribe(data=>{
      data['data'].forEach(fixture => {
        this.fixtures.push(fixture);
      });
     this.getInternatinal(my_season, team_id, change, season);
    });
  }

  async getInternatinal(my_season, team_id, change, season){
    for (let it = 0; it < my_season.competitions.length; it++) {
      const competition =  my_season.competitions[it];
      let otherCompetition_fixtures = await this.projectsService.getOhterCompetition_fictures(competition.id, team_id);
      if(otherCompetition_fixtures.hasOwnProperty('data')){
        otherCompetition_fixtures['data'].forEach(fixture => {
          this.fixtures.push(fixture);
        });
      }
    }
    this.dataService.getSelectedProject().seasons[season].games = this.fixtures.sort(this.sortFunction);
    this.getSessions(change);
  }

  sortFunction(a,b){  
    var dateA = new Date(a.time.date).getTime();
    var dateB = new Date(b.time.date).getTime();
    return dateA > dateB ? 1 : -1;  
  }; 

  getSessions(change){
    var fd = new FormData();
    fd.append('id', this.dataService.getSelectedProject().id);
    fd.append('season_id', this.dataService.selected_project.seasons[this.dataService.selected_season].id);
    this.sessionService.getSessions(fd).subscribe(data=>{
      this.sessions = data;
      this.sessionService.getRestDays(fd).subscribe(data=>{
        this.rests = data;
        this.getMicros(change);
      })
    });
  }

  getMicros(change){
    var fd = new FormData();
    fd.append('id', this.dataService.getSelectedProject().id);
    fd.append('season_id', this.dataService.selected_project.seasons[this.dataService.selected_season].id);
    this.microService.getMicro(fd).subscribe(data=>{
      this.micros = data;
      this.setEvents(change);
    });
  }

  getLeagueIMG(fixture){
    if(fixture.season_id == this.dataService.selected_project.seasons[this.dataService.selected_season].project_season_id){
      return [this.dataService.selected_project.seasons[this.dataService.selected_season].img, this.dataService.selected_project.seasons[this.dataService.selected_season].name];
    } else {
      for (let index = 0; index < this.dataService.selected_project.seasons[this.dataService.selected_season].competitions.length; index++) {
        const competition = this.dataService.selected_project.seasons[this.dataService.selected_season].competitions[index];
        if(fixture.season_id == competition.id){
          return [competition.img, competition.name];
        }
      }
      return["",""];
    }
    return["",""];
  }

  setEvents(change){
    this.events = [];
    this.loadingSchedule = false;   
    let team_id = this.dataService.getSelectedProject().project_team_id;
    this.fixtures.forEach(fixture => {
      let url = fixture.teams.home.id == team_id ? fixture.teams.away.img : fixture.teams.home.img;
      let team_name = fixture.teams.home.id == team_id ? fixture.teams.away.name : fixture.teams.home.name;
      let where = fixture.teams.home.id == team_id ? '(H)' : '(A)';
      if(fixture.status_name !== 'Postponed'){
        let event = {
          id : fixture.id,
          title: team_name,
          date: fixture.time.date,
          imgUrl: url,
          league_img: this.getLeagueIMG(fixture)[0],
          league_name: this.getLeagueIMG(fixture)[1],
          type: 'game',
          where: where,
          result: fixture.status_name == 'Finished' ? fixture.scores.ft_score :  fixture.status_name == 'Finished with pen.' ? fixture.scores.ps_score  :  '?-?',
          winner: ((fixture.status_name == 'Finished' || fixture.status_name == 'Finished with pen.')  && fixture.winner_team_id == null ? 'draw' : (fixture.status_name == 'Finished' || fixture.status_name == 'Finished with pen.') && fixture.winner_team_id == team_id ? 'win' : (fixture.status_name == 'Finished' || fixture.status_name == 'Finished with pen.') && fixture.winner_team_id != team_id ? 'loose' : 'to_happen')
        };
        this.events.push(event);
      }
    });
    this.sessions.forEach(session => {
      let category = this.getMainCategoryById(session.category);
      session.category_name = category;
      let event = {
        id : session.id,
        title: category.name,
        date: session.data,
        type: 'session',
        cor: category.color,
        session_goals: JSON.parse(session.session_goals)
      };
      if(session.players == null){
        event['hasSummary'] = false;
      } else {
        event['hasSummary'] = true;
      }
      this.events.push(event);
    });
    this.rests.forEach(rest => {
      let event = {
        id : rest.id,
        title: "Rest Day",
        date: rest.date,
        type: 'rest'
      };
      this.events.push(event);
    });
    this.micros.forEach((micro, it) => {
      var currDate = moment(micro.date_begin).startOf('day');
      var lastDate = moment(micro.date_end).startOf('day');
      var micro_days = [currDate.format("YYYY-MM-DD")];
      while(currDate.add(1, 'days').diff(lastDate) < 0) {
        micro_days.push(currDate.format("YYYY-MM-DD"));
      }
      micro_days.push(lastDate.format("YYYY-MM-DD"));
      for (let itD = 0; itD < micro_days.length; itD++) {
        const day = micro_days[itD];
        let exists = false;
        for (let itE = 0; itE < this.events.length; itE++) {
          const event = this.events[itE];
          if(day == event.date){
            event.micro = "M"+(it+1);
            event.micro_id = micro.id;
            exists = true;
          }
        }
        if(!exists){
          let event = {
            id: micro.id,
            micro_id: micro.id,
            micro:'M'+(it+1),
            date: day,
            type: 'micro'
          };
          this.events.push(event);
        }
      }
    });
    this.loadingService.loading = false;
    setTimeout(() => {
      if(change){
        if(this.calendarComponent != undefined){
          this.calendarApi = this.calendarComponent.getApi();
          this.calendarApi.gotoDate(this.events[0].date);
        }
      }
    }, 10);
  }
 
  addEvent(date){
    this.actiondateModal = this.actiondateModalComponent.open(ActiondateComponent, { disableClose: true });
    this.modalService.modalOpen = true;
    this.actiondateModal.afterClosed().subscribe((result) => {
      this.modalService.modalOpen = false;
      if(result.action == 'session'){
        this.modalService.modalOpen = true;
        this.sessionModal = this.sessionModalComponent.open(SessionmodalComponent, { disableClose: true });
        let instance = this.sessionModal.componentInstance;
        instance.data = date;
        instance.step = 1;
        instance.season_id = this.dataService.selected_project.seasons[this.dataService.selected_season].id;
        this.sessionModal.afterClosed().subscribe((result) => {
          this.modalService.modalOpen = false;
          if(result.action == 'success'){
            this.getSessions(false);
          }
        });
      } 
      if(result.action == 'micro'){
        this.modalService.modalOpen = true;
        this.microModal = this.microModalComponent.open(MicrocycleComponent, { disableClose: true });
        let instance = this.microModal.componentInstance;
        instance.data = date;
        instance.season_id = this.dataService.selected_project.seasons[this.dataService.selected_season].id;
        this.microModal.afterClosed().subscribe((result) => {
          this.modalService.modalOpen = false;
          if(result.action == 'success'){
            this.getSessions(false);
          }
        });
      } 
      if(result.action == 'rest'){
        var fd = new FormData();
        fd.append('data', date);
        fd.append('project', this.dataService.selected_project.id);
        fd.append('season_id', this.dataService.selected_project.seasons[this.dataService.selected_season].id);
        this.sessionService.addRestDay(fd).subscribe(data=>{
          this.getSessions(false);
        });
      } 
    });
  }

  getMainCategoryById(id){
    let category;
    for (let it = 0; it < this.categories['main'].length; it++) {
      const cat = this.categories['main'][it];
      if(id == cat.id){
        category = cat;
        break;
      }
    }
    return category;
  }

  render(evt){
    var element = evt.el;
    var props = evt.event.extendedProps;
    element.style.cssText = "";
    element.classList += " calendar_unit"; 
    if(evt.event.extendedProps.type == 'micro'){
      var div =  `<span class='micro' data-id='`+evt.event.extendedProps.micro_id+`'>`+evt.event.extendedProps.micro+`</span>`;
      element.innerHTML = div;
    }
    else if(evt.event.extendedProps.type == 'game'){
      var where = evt.event.extendedProps.where;
      var result = evt.event.extendedProps.result;
      var div = `<div  id='game-`+evt.event.id+`' class='game subunit' style='background-image:url(`+props.imgUrl+`)'>
                </div>`; 
          div += `<span class='where'>`+where+`</span><span class='result'>`+result+`</span>`; 
          div += "<img class='league_img' src='"+props.league_img+"' matTooltip='"+props.league_name+"' title='"+props.league_name+"'>"
      if(props.winner != 'to_happen'){
        div += `<span class='winner `+props.winner+`'><span class='letter'>`+(props.winner == 'win' ? 'W' : props.winner == 'draw' ? 'D' : 'L')+`</span></span>`;
      }   
      if(evt.event.extendedProps.hasOwnProperty('micro')){
        div +=  `<span class='micro' data-id='`+evt.event.extendedProps.micro_id+`'>`+evt.event.extendedProps.micro+`</span>`;
      }
      element.innerHTML = div;
    } 
    else if(evt.event.extendedProps.type == 'session'){
      var div = `<div id='session`+evt.event.id+`' class='session subunit' style=' border: 1px solid `+evt.event.extendedProps.cor+`;  `+(evt.event.extendedProps.hasSummary ? `color:black; background:`+evt.event.extendedProps.cor +`;`: `background: transparent;`)+`'>`+evt.event.title+`</div>`;

      if(evt.event.extendedProps.hasOwnProperty('micro')){
        div +=  `<span class='micro' data-id='`+evt.event.extendedProps.micro_id+`'>`+evt.event.extendedProps.micro+`</span>`;
      }

      if(evt.event.extendedProps.hasOwnProperty('session_goals')){
        if(evt.event.extendedProps.session_goals != null){
          div += `<div class="session_goal_calendar_wrapper">`;

          evt.event.extendedProps.session_goals.forEach( (currentValue, index) => {
            if(index < 3){
              div += `<div class="session_goal_calendar_it">`+currentValue+`</div>`;
            }
            
          });

          div += `</div>`;
        }

      }

      element.innerHTML = div;
    } else if(evt.event.extendedProps.type == 'rest'){
      element.classList += " rest";
      element.innerHTML = `<div class='rest subunit'>`+evt.event.title+`</div>`;
      if(evt.event.extendedProps.hasOwnProperty('micro')){
        element.innerHTML += "<span class='micro' data-id='"+evt.event.extendedProps.micro_id+"'>"+evt.event.extendedProps.micro+"</span>";
      }
    }  
  }

  eventClick(evt){
    if(evt.event.extendedProps.type == "game"){
      if(evt.jsEvent.target instanceof HTMLSpanElement && evt.jsEvent.target.classList.contains('micro')){
        //click on microcycle
        let micro_id = evt.jsEvent.target.getAttribute("data-id");
        let targetMicro = null;
        for (let it = 0; it < this.micros.length; it++) {
          const micro = this.micros[it];
          if(micro.id == micro_id){
            targetMicro = micro;
            break;
          } 
        }
        if(targetMicro != null){
          this.modalService.modalOpen = true;
          this.microModal = this.microModalComponent.open(MicrocycleComponent, { disableClose: true });
          let instance = this.microModal.componentInstance;
          instance.micro = targetMicro;
          instance.season_id = this.dataService.selected_project.seasons[this.dataService.selected_season].id;
          this.microModal.afterClosed().subscribe((result) => {
            this.modalService.modalOpen = false;
            if(result.action == 'success'){
              this.getSessions(false);
            }
          });
        }
      } else {
        //click in the game
        let id = evt.event.id;
        let game = this.getGameById(id);
        if(game.scores.ft_score == null){
          return;
        }
        this.gameModal = this.gameModalComponent.open(GameComponent, { disableClose: true });
        let instance = this.gameModal.componentInstance;
        instance.game = game;
        instance.season = this.dataService.selected_project.seasons[this.dataService.selected_season].id;
        instance.from = "schedule";
      }
    } else if(evt.event.extendedProps.type == "session"){
      if (evt.jsEvent.target instanceof HTMLDivElement) {
        //click on session
        if(evt.event.extendedProps.hasSummary){
          //complete background
          let id = evt.event.id;
          let session = this.getSessionById(id);
          this.modalService.modalOpen = true;
          this.trainingModal = this.trainingModalComponent.open(TrainingResumeComponent, { disableClose: true });
          let instance = this.trainingModal.componentInstance;
          instance.session = session;
          instance.evento = evt.event;
          this.trainingModal.afterClosed().subscribe((result) => {
            this.modalService.modalOpen = false;
            if(result.action == "delete"){
              this.getSessions(false);
            }
          });
        } else {
          //just marked - bordered
          let id = evt.event.id;
          let session = this.getSessionById(id);
          this.modalService.modalOpen = true;
          this.sessionModal = this.sessionModalComponent.open(SessionmodalComponent, { disableClose: true });
          let instance = this.sessionModal.componentInstance;
          instance.step = 2;
          instance.session = session;
          this.sessionModal.afterClosed().subscribe((result) => {
            this.modalService.modalOpen = false;
            if(result.action == 'success'){
              this.getSessions(false);
            }
            else if(result.action == 'delete'){
              this.getSessions(false);
            }
          });
        }
      }
      else if (evt.jsEvent.target instanceof HTMLSpanElement) {
        //click on microcycle
        let micro_id = evt.jsEvent.target.getAttribute("data-id");
        let targetMicro = null;
        for (let it = 0; it < this.micros.length; it++) {
          const micro = this.micros[it];
          if(micro.id == micro_id){
            targetMicro = micro;
            break;
          } 
        }
        if(targetMicro != null){
          this.modalService.modalOpen = true;
          this.microModal = this.microModalComponent.open(MicrocycleComponent, { disableClose: true });
          let instance = this.microModal.componentInstance;
          instance.micro = targetMicro;
          instance.season_id = this.dataService.selected_project.seasons[this.dataService.selected_season].id;
          this.microModal.afterClosed().subscribe((result) => {
            this.modalService.modalOpen = false;
            if(result.action == 'success'){
              this.getSessions(false);
            }
          });
        }
      }
      else{
        //click on rest of the div
      }   
    } else if(evt.event.extendedProps.type == "rest"){
      if(evt.jsEvent.target instanceof HTMLSpanElement){
        //click on microcycle
        let micro_id = evt.jsEvent.target.getAttribute("data-id");
        let targetMicro = null;
        for (let it = 0; it < this.micros.length; it++) {
          const micro = this.micros[it];
          if(micro.id == micro_id){
            targetMicro = micro;
            break;
          } 
        }
        if(targetMicro != null){
          this.modalService.modalOpen = true;
          this.microModal = this.microModalComponent.open(MicrocycleComponent, { disableClose: true });
          let instance = this.microModal.componentInstance;
          instance.micro = targetMicro;
          instance.season_id = this.dataService.selected_project.seasons[this.dataService.selected_season].id;
          this.microModal.afterClosed().subscribe((result) => {
            this.modalService.modalOpen = false;
            if(result.action == 'success'){
              this.getSessions(false);
            }
          });
        }
      } else {
        //click in the rest
        this.modalService.modalOpen = true;
        this.confirmDeleteModal = this.confirmDeleteModalComponent.open(ConfirmdeleteComponent, { disableClose: true });
        let instance = this.confirmDeleteModal.componentInstance;
        let title = "Warning";
        let message = "Are you sure you want to delete this Rest Day?";
        instance.title = title;
        instance.message = message;
        instance.submessage = "If you delete this rest day, you will be able to schedule a training.";
        this.confirmDeleteModal.afterClosed().subscribe((result) => {
          this.modalService.modalOpen = false;
          if(result.message == 'success'){
            let fd = new FormData();
            fd.append("rest_id", evt.event.id);
            this.sessionService.deleteRestDay(fd).subscribe(data=>{
              if(data == 'success'){
                this.getSessions(false);
              }
            });
          }
        });
      }
    } else if(evt.event.extendedProps.type == "micro"){
      if(evt.jsEvent.target instanceof HTMLSpanElement){
        //click on microcycle
        let micro_id = evt.jsEvent.target.getAttribute("data-id");
        let targetMicro = null;
        for (let it = 0; it < this.micros.length; it++) {
          const micro = this.micros[it];
          if(micro.id == micro_id){
            targetMicro = micro;
            break;
          } 
        }
        if(targetMicro != null){
          this.modalService.modalOpen = true;
          this.microModal = this.microModalComponent.open(MicrocycleComponent, { disableClose: true });
          let instance = this.microModal.componentInstance;
          instance.micro = targetMicro;
          instance.season_id = this.dataService.selected_project.seasons[this.dataService.selected_season].id;
          this.microModal.afterClosed().subscribe((result) => {
            this.modalService.modalOpen = true;
            if(result.action == 'success'){
              this.getSessions(false);
            }
          });
        }
      } else {
        //click on the rest of the div
        this.addEvent(moment(evt.event.start).format('YYYY-MM-DD'))
      }
    }
  }

  getGameById(id){
    let game = null;
    for (let it = 0; it < this.fixtures.length; it++) {
      const fixture = this.fixtures[it];
      if(fixture.id == id){
        game = fixture;
      }
    }
    return game;
  }

  getSessionById(id){
    let session = null;
    for (let it = 0; it < this.sessions.length; it++) {
      const session_temp = this.sessions[it];
      if(session_temp.id == id){
        session = session_temp;
      }
    }
    return session;
  }

}