import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.scss']
})
export class LoadComponent implements OnInit {
  template = null;
  nr_of_groups = 0;
  groups = [];
  ideal_loads = [];
  constructor(public dialogRef: MatDialogRef<LoadComponent>) { }

  ngOnInit() {
    this.processGroups();
  }

  getParam(exercise, param){
    if(exercise['gps_vars'][param] == 0){
      return 0;
    }
    if(Number(exercise.time) != NaN &&
       Number(exercise.reps) != NaN && 
       exercise['time'] != 0 &&
       exercise['reps'] != 0){
        let value_per_minute = exercise['gps_vars'][param] / exercise['gps_vars']['time'];
        return Number(value_per_minute * exercise.time * exercise.reps).toFixed(2);
    } else {
     return 0;
    }
  }

  processGroups(){

    let isAllGroup = true;
    for (let itC = 0; itC < this.template.circuits.length; itC++) {
      const circuit = this.template.circuits[itC];
      for (let itE = 0; itE < circuit.exercicios.length; itE++) {
        const exercicio = circuit.exercicios[itE];
        if(exercicio.who != 0){
          isAllGroup = false;
          break;
        }
      }
      if(!isAllGroup){
        break;
      }
    }
    if(isAllGroup){
      let total_distance = 0;
      let hi_runs = 0;
      let acc = 0;
      let decc = 0;
      let nr_sprints = 0;
      let mts_nr_sprints = 0;

      this.template.circuits.forEach(circuit => {
        circuit.exercicios.forEach(exercicio => {
          let temp_total_distance = Number(this.getParam(exercicio, 'total_distance')); 
          let temp_hi_runs = Number(this.getParam(exercicio, 'hi_runs')); 
          let temp_acc = Number(this.getParam(exercicio, 'acc'));
          let temp_decc = Number(this.getParam(exercicio, 'decc'));  
          let temp_nr_sprints = Number(this.getParam(exercicio, 'nr_sprints'));
          let temp_mts_nr_sprints = Number(this.getParam(exercicio, 'mts_sprint'));        
          total_distance += temp_total_distance;
          hi_runs += temp_hi_runs;
          acc += temp_acc;
          decc += temp_decc;
          nr_sprints += temp_nr_sprints;
          mts_nr_sprints += temp_mts_nr_sprints; 
        });
      });

      this.groups.push({
        name: "All Group",
        total_distance: total_distance.toFixed(2),
        hi_runs: hi_runs.toFixed(2),
        acc: acc.toFixed(2),
        decc: decc.toFixed(2),
        nr_sprints: nr_sprints.toFixed(2),
        mts_nr_sprints: mts_nr_sprints.toFixed(2)
      });
    } else {
      let add_group_1 = false;
      let add_group_2 = false;
      let add_group_3 = false;
      this.template.circuits.forEach(circuit => {
        circuit.exercicios.forEach(exercicio => {
          if(exercicio.who.toString().indexOf("1") !== -1){
            add_group_1 = true;
          }
          if(exercicio.who.toString().indexOf("2") !== -1){
            add_group_2 = true;
          }
          if(exercicio.who.toString().indexOf("3") !== -1){
            add_group_3 = true;
          }
        });
      });
      if(add_group_1){
        let total_distance = 0;
        let hi_runs = 0;
        let acc = 0;
        let decc = 0;
        let nr_sprints = 0;
        let mts_nr_sprints = 0;
        this.template.circuits.forEach(circuit => {
          circuit.exercicios.forEach(exercicio => {
            if(exercicio.who.toString().indexOf("1") !== -1 || exercicio.who.toString() == "0"){
              total_distance += Number(this.getParam(exercicio, 'total_distance'));
              hi_runs += Number(this.getParam(exercicio, 'hi_runs'));
              acc += Number(this.getParam(exercicio, 'acc'));
              decc += Number(this.getParam(exercicio, 'decc'));
              nr_sprints += Number(this.getParam(exercicio, 'nr_sprints'));
              mts_nr_sprints += Number(this.getParam(exercicio, 'mts_sprint'));
            }
          });
        });
        this.groups.push({
          name: "Group 1",
          total_distance: total_distance.toFixed(2),
          hi_runs: hi_runs.toFixed(2),
          acc: acc.toFixed(2),
          decc: decc.toFixed(2),
          nr_sprints: nr_sprints.toFixed(2),
          mts_nr_sprints: mts_nr_sprints.toFixed(2)
        });
        if(!add_group_2 && !add_group_3){
          let total_distance = 0;
          let hi_runs = 0;
          let acc = 0;
          let decc = 0;
          let nr_sprints = 0;
          let mts_nr_sprints = 0;
          this.template.circuits.forEach(circuit => {
            circuit.exercicios.forEach(exercicio => {
              if(exercicio.who.toString() == "0"){
                add_group_1 = true;
                total_distance += Number(this.getParam(exercicio, 'total_distance'));
                hi_runs += Number(this.getParam(exercicio, 'hi_runs'));
                acc += Number(this.getParam(exercicio, 'acc'));
                decc += Number(this.getParam(exercicio, 'decc'));
                nr_sprints += Number(this.getParam(exercicio, 'nr_sprints'));
                mts_nr_sprints +=  Number(this.getParam(exercicio, 'mts_sprint'));
              }
            });
          });
          this.groups.push({
            name: "Rest of Group",
            total_distance: total_distance.toFixed(2),
            hi_runs: hi_runs.toFixed(2),
            acc: acc.toFixed(2),
            decc: decc.toFixed(2),
            nr_sprints: nr_sprints.toFixed(2),
            mts_nr_sprints: mts_nr_sprints.toFixed(2)
          });
        }
      }
      if(add_group_2){
        let total_distance = 0;
        let hi_runs = 0;
        let acc = 0;
        let decc = 0;
        let nr_sprints = 0;
        let mts_nr_sprints = 0;
        this.template.circuits.forEach(circuit => {
          circuit.exercicios.forEach(exercicio => {
            if(exercicio.who.toString().indexOf("2") !== -1 || exercicio.who.toString() == "0"){
              total_distance +=  Number(this.getParam(exercicio, 'total_distance'));
              hi_runs +=  Number(this.getParam(exercicio, 'hi_runs'));
              acc +=  Number(this.getParam(exercicio, 'acc'));
              decc +=  Number(this.getParam(exercicio, 'decc'));
              nr_sprints +=  Number(this.getParam(exercicio, 'nr_sprints'));
              mts_nr_sprints +=  Number(this.getParam(exercicio, 'mts_sprint'));
            }
          });
        });
        this.groups.push({
          name: "Group 2",
          total_distance: total_distance.toFixed(2),
          hi_runs: hi_runs.toFixed(2),
          acc: acc.toFixed(2),
          decc: decc.toFixed(2),
          nr_sprints: nr_sprints.toFixed(2),
          mts_nr_sprints: mts_nr_sprints.toFixed(2)
        });
        if(!add_group_1 && !add_group_3){
          let total_distance = 0;
          let hi_runs = 0;
          let acc = 0;
          let decc = 0;
          let nr_sprints = 0;
          let mts_nr_sprints = 0;
          this.template.circuits.forEach(circuit => {
            circuit.exercicios.forEach(exercicio => {
              if(exercicio.who.toString() == "0"){
                total_distance +=  Number(this.getParam(exercicio, 'total_distance'));
                hi_runs +=  Number(this.getParam(exercicio, 'hi_runs'));
                acc +=  Number(this.getParam(exercicio, 'acc'));
                decc +=  Number(this.getParam(exercicio, 'decc'));
                nr_sprints +=  Number(this.getParam(exercicio, 'nr_sprints'));
                mts_nr_sprints +=  Number(this.getParam(exercicio, 'mts_sprint'));
              }
            });
          });
          this.groups.push({
            name: "Rest of Group",
            total_distance: total_distance.toFixed(2),
            hi_runs: hi_runs.toFixed(2),
            acc: acc.toFixed(2),
            decc: decc.toFixed(2),
            nr_sprints: nr_sprints.toFixed(2),
            mts_nr_sprints: mts_nr_sprints.toFixed(2)
          });
        }
      }
      if(add_group_3){
        let total_distance = 0;
        let hi_runs = 0;
        let acc = 0;
        let decc = 0;
        let nr_sprints = 0;
        let mts_nr_sprints = 0;
        this.template.circuits.forEach(circuit => {
          circuit.exercicios.forEach(exercicio => {
            if(exercicio.who.toString().indexOf("3") !== -1 || exercicio.who.toString() == "0"){
              total_distance += Number(this.getParam(exercicio, 'total_distance'));
              hi_runs += Number(this.getParam(exercicio, 'hi_runs'));
              acc += Number(this.getParam(exercicio, 'acc'));
              decc += Number(this.getParam(exercicio, 'decc'));
              nr_sprints += Number(this.getParam(exercicio, 'nr_sprints'));
              mts_nr_sprints += Number(this.getParam(exercicio, 'mts_sprint'));
            }
          });
        });
        this.groups.push({
          name: "Group 3",
          total_distance: total_distance.toFixed(2),
          hi_runs: hi_runs.toFixed(2),
          acc: acc.toFixed(2),
          decc: decc.toFixed(2),
          nr_sprints: nr_sprints.toFixed(2),
          mts_nr_sprints: mts_nr_sprints.toFixed(2)
        });
        if(!add_group_1 && !add_group_2){
          let total_distance = 0;
          let hi_runs = 0;
          let acc = 0;
          let decc = 0;
          let nr_sprints = 0;
          let mts_nr_sprints = 0;
          this.template.circuits.forEach(circuit => {
            circuit.exercicios.forEach(exercicio => {
              if(exercicio.who.toString() == "0"){
                total_distance +=  Number(this.getParam(exercicio, 'total_distance'));
                hi_runs +=  Number(this.getParam(exercicio, 'hi_runs'));
                acc +=  Number(this.getParam(exercicio, 'acc'));
                decc +=  Number(this.getParam(exercicio, 'decc'));
                nr_sprints +=  Number(this.getParam(exercicio, 'nr_sprints'));
                mts_nr_sprints +=  Number(this.getParam(exercicio, 'mts_sprint'));
              }
            });
          });
          this.groups.push({
            name: "Rest of Group",
            total_distance: total_distance.toFixed(2),
            hi_runs: hi_runs.toFixed(2),
            acc: acc.toFixed(2),
            decc: decc.toFixed(2),
            nr_sprints: nr_sprints.toFixed(2),
            mts_nr_sprints: mts_nr_sprints.toFixed(2)
          });
        }
      }
    }
  }

  close() {
    this.dialogRef.close({ action: 'cancel' });
  }

}
