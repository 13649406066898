import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-actiondate',
  templateUrl: './actiondate.component.html',
  styleUrls: ['./actiondate.component.scss']
})
export class ActiondateComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ActiondateComponent>) { }

  ngOnInit() {
  }

  action(action){
    this.dialogRef.close({ action: action });
  }
  
}
