import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-chooseexercise',
  templateUrl: './chooseexercise.component.html',
  styleUrls: ['./chooseexercise.component.scss']
})
export class ChooseexerciseComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ChooseexerciseComponent>) { }

  ngOnInit() {
  }

  feedback(event){
    if(event.action == 'cancelar'){
      this.cancel();
    } else{
      this.choose(event.exercicio);
    }
  }

  cancel() {
    this.dialogRef.close({ action: 'cancel' });
  }

  choose(exercicio) {
    this.dialogRef.close({ action: 'choose', exercicio: exercicio });
  }

}
