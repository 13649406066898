import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { StandingsService } from '../../services/standings.service';
import { ProjectsService } from "../../services/projects.service";
import { StandingsmodalComponent } from '../../modals/standingsmodal/standingsmodal.component';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-standings',
  templateUrl: './standings.component.html',
  styleUrls: ['./standings.component.scss']
})

export class StandingsComponent implements OnInit {
  standingsData = [];
  constructor(
      public dataService: DataService,
      public standingsService: StandingsService,
      public projectsService: ProjectsService,
      public standingsModalComponent: MatDialog,
              ) { }


  @Input() season: any;
  @Input() maxRows: any;
  standingModal:MatDialogRef<StandingsmodalComponent>;

  ngOnInit() {
    this.standingsService.getTeamStandings(this.dataService.getSelectedProject().seasons[this.season].project_season_id).subscribe(data => {
        let indexOfTeam = data["data"]["standings"].findIndex(x=>x.team_id == this.dataService.getSelectedProject().project_team_id);
        let self = this;
        let minIndex = 0;
        let maxIndex = this.maxRows;

        if(indexOfTeam >= 0 && indexOfTeam <= 2){
            maxIndex = 4;
        }else{
            minIndex = indexOfTeam - 1;
            maxIndex = indexOfTeam + 1;
        }
        data["data"]["standings"].forEach(function(value, index){
            if(index >= minIndex && index <= maxIndex){
                value["tablePosition"] = index + 1;
                self.standingsData.push(value);
                self.projectsService.getTeamById(value["team_id"]).subscribe(dataTeam => {
                    //value["teamLogo"] = dataTeam["data"]["img"];

                });
            }

        });
     });
  }

  openStandings(){
    this.standingModal = this.standingsModalComponent.open(StandingsmodalComponent, {disableClose: true});
  }

  
}
