import { Component, OnInit } from '@angular/core';
import { GameService } from '../../services/game.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { DataService } from '../../services/data.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from '../../services/toastr.service';
import { ConfirmdeleteComponent } from '../../modals/confirmdelete/confirmdelete.component';
import * as moment from 'moment';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {

  constructor(public dataService: DataService,
              public gameService: GameService,
              public toastrService: ToastrService,
              public dialogRef: MatDialogRef<GameComponent>,
              private fb: FormBuilder,
              public confirmDeleteModalComponent: MatDialog,
              public loadingService:LoadingService) { }
  game = null;
  homeTeam = null;
  awayTeam = null;
  events = null;
  loading = true;
  gameForm: FormGroup;
  season = null;
  from = null;
  
  gameVars = {
    home:{
      first:{},
      second:{},
      all:{}
    },
    away:{
      first:{},
      second:{},
      all:{}
    }
  };
  confirmDeleteModal: MatDialogRef<ConfirmdeleteComponent>;

  ngOnInit() {
    let game_id = this.game.id;
    this.gameService.getGameStats(game_id).subscribe(data=>{
      if(data.hasOwnProperty('data')){
        this.homeTeam = data['data'][0];
        this.awayTeam = data['data'][1];
        if(this.homeTeam == undefined){
          this.homeTeam = {};
        }
        if(this.awayTeam == undefined){
          this.awayTeam = {};
        }
        this.getLineUps(game_id);
      } else {
        this.confirmDeleteModal = this.confirmDeleteModalComponent.open(ConfirmdeleteComponent, { disableClose: true });
        let instance = this.confirmDeleteModal.componentInstance;
        let title = "Warning";
        let message = "Statistics unavailable";
        instance.title = title;
        instance.message = message;
        instance.type = "warning";
        instance.text_button = "Ok";
        let second_part_message = "";
        const date = moment(this.game.time.date);
        const now = moment();
        if( date > now){
          second_part_message = " because the game didn't ocurred yet";
          this.close();
        } else {
          second_part_message = " because they do not exist in the database";
          this.homeTeam = this.game.teams.home;
          this.awayTeam = this.game.teams.away;
          this.homeTeam['goals'] = this.game.scores.home_score;
          this.homeTeam['possessionpercent'] = '-';
          this.homeTeam['attacks'] = '-';
          this.homeTeam['goal_attempts'] = '-';
          this.homeTeam['fouls'] = '-';
          this.homeTeam['corners'] = '-';
          this.homeTeam['yellowcards'] = '-';
          this.homeTeam['redcards'] = '-';
          this.awayTeam['goals'] = this.game.scores.away_score;
          this.awayTeam['possessionpercent'] = '-';
          this.awayTeam['attacks'] = '-';
          this.awayTeam['goal_attempts'] = '-';
          this.awayTeam['fouls'] = '-';
          this.awayTeam['corners'] = '-';
          this.awayTeam['yellowcards'] = '-';
          this.awayTeam['redcards'] = '-';
          this.getLineUps(game_id);
        }
        instance.submessage = "The statistics for this game are unavailable"+ second_part_message;
      }
    });
  }

  getLineUps(game_id){
    this.gameService.getGameLineUps(game_id).subscribe(data=>{
      if(data.hasOwnProperty('data')){
        if(data['data'].hasOwnProperty('home')){
          this.homeTeam['lineUp'] = data['data']['home'];
        } else {
          this.homeTeam['lineUp'] = [];
        }
        if(data['data'].hasOwnProperty('away')){
          this.awayTeam['lineUp'] = data['data']['away'];
        } else{
          this.awayTeam['lineUp'] = [];
        }
      }
      this.processGame();
      this.gameService.getGameEvents(game_id).subscribe(data=>{
        if(data.hasOwnProperty('data')){
          let tempEvents = data['data'];
          for (let it = tempEvents.length -1; it >=0; it--) {
            const event = tempEvents[it];
            if(event.type == 'injury' || event.type == "back from injury"){
              tempEvents.splice(it, 1);
            }
          }
          this.events = tempEvents;
        }
        var fd = new FormData();
        fd.append('game_id', game_id);
        this.gameService.getGameVars(fd).subscribe(data=>{
          this.gameForm = this.fb.group({
            time: ['', [Validators.required]],
            rpe: ['', [Validators.required]],
            home_team_first_distance: ['', [Validators.required]],
            home_team_first_hi_runs: ['', [Validators.required]],
            home_team_first_acc: ['', [Validators.required]],
            home_team_first_decc: ['', [Validators.required]],
            home_team_first_sprints: ['', [Validators.required]],
            home_team_first_mts_of_sprint: ['', [Validators.required]],
            home_team_second_distance: ['', [Validators.required]],
            home_team_second_hi_runs: ['', [Validators.required]],
            home_team_second_acc: ['', [Validators.required]],
            home_team_second_decc: ['', [Validators.required]],
            home_team_second_sprints: ['', [Validators.required]],
            home_team_second_mts_of_sprint: ['', [Validators.required]],
            away_team_first_distance: ['', [Validators.required]],
            away_team_first_hi_runs: ['', [Validators.required]],
            away_team_first_acc: ['', [Validators.required]],
            away_team_first_decc: ['', [Validators.required]],
            away_team_first_sprints: ['', [Validators.required]],
            away_team_first_mts_of_sprint: ['', [Validators.required]],
            away_team_second_distance: ['', [Validators.required]],
            away_team_second_hi_runs: ['', [Validators.required]],
            away_team_second_acc: ['', [Validators.required]],
            away_team_second_decc: ['', [Validators.required]],
            away_team_second_sprints: ['', [Validators.required]],
            away_team_second_mts_of_sprint: ['', [Validators.required]],
          });
          if(data != null){
            let home_vars = JSON.parse(data['home_team_vars']);
            let away_vars = JSON.parse(data['away_team_vars']);
            this.gameForm.patchValue({
              time: data['time'],
              rpe: data['rpe'],
              home_team_first_distance: home_vars['home_team_first_distance'] ,
              home_team_first_hi_runs: home_vars['home_team_first_hi_runs'] ,
              home_team_first_acc: home_vars['home_team_first_acc'] ,
              home_team_first_decc: home_vars['home_team_first_decc'] ,
              home_team_first_sprints: home_vars['home_team_first_sprints'] ,
              home_team_first_mts_of_sprint: home_vars['home_team_first_mts_of_sprint'] ,
              home_team_second_distance: home_vars['home_team_second_distance'] ,
              home_team_second_hi_runs: home_vars['home_team_second_hi_runs'] ,
              home_team_second_acc: home_vars['home_team_second_acc'] ,
              home_team_second_decc: home_vars['home_team_second_decc'] ,
              home_team_second_sprints: home_vars['home_team_second_sprints'] ,
              home_team_second_mts_of_sprint: home_vars['home_team_second_mts_of_sprint'] ,
              away_team_first_distance: away_vars['away_team_first_distance'] ,
              away_team_first_hi_runs: away_vars['away_team_first_hi_runs'] ,
              away_team_first_acc: away_vars['away_team_first_acc'] ,
              away_team_first_decc: away_vars['away_team_first_decc'] ,
              away_team_first_sprints: away_vars['away_team_first_sprints'] ,
              away_team_first_mts_of_sprint: away_vars['away_team_first_mts_of_sprint'] ,
              away_team_second_distance: away_vars['away_team_second_distance'] ,
              away_team_second_hi_runs: away_vars['away_team_second_hi_runs'] ,
              away_team_second_acc: away_vars['away_team_second_acc'] ,
              away_team_second_decc: away_vars['away_team_second_decc'] ,
              away_team_second_sprints: away_vars['away_team_second_sprints'] ,
              away_team_second_mts_of_sprint: away_vars['away_team_second_mts_of_sprint'] 
            });
            if(this.from == "games"){
              const home_vars = JSON.parse(data['home_team_vars']);
              const away_vars = JSON.parse(data['away_team_vars']);
              this.gameVars['time'] = data['time'];
              this.gameVars['rpe'] = data['rpe'];
              //first half
              this.gameVars['home']['first']['distance'] = { value: home_vars.home_team_first_distance, color: (home_vars.home_team_first_distance > away_vars.away_team_first_distance ? '#02f3a7' : home_vars.home_team_first_distance == away_vars.away_team_first_distance ? '#FFED58' : 'red')};
              this.gameVars['away']['first']['distance'] = { value: away_vars.away_team_first_distance, color: (away_vars.away_team_first_distance > home_vars.home_team_first_distance ? '#02f3a7' : away_vars.away_team_first_distance == home_vars.home_team_first_distance ? '#FFED58' : 'red')};
              this.gameVars['home']['first']['hi_runs'] = { value: home_vars.home_team_first_hi_runs, color: (home_vars.home_team_first_hi_runs > away_vars.away_team_first_hi_runs ? '#02f3a7' : home_vars.home_team_first_hi_runs == away_vars.away_team_first_hi_runs ? '#FFED58' : 'red')};
              this.gameVars['away']['first']['hi_runs'] = { value: away_vars.away_team_first_hi_runs, color: (away_vars.away_team_first_hi_runs > home_vars.home_team_first_hi_runs ? '#02f3a7' : away_vars.away_team_first_hi_runs == home_vars.home_team_first_hi_runs ? '#FFED58' : 'red')};
              this.gameVars['home']['first']['acc'] = { value: home_vars.home_team_first_acc, color: (home_vars.home_team_first_acc > away_vars.away_team_first_acc ? '#02f3a7' : home_vars.home_team_first_acc == away_vars.away_team_first_acc ? '#FFED58' : 'red')};
              this.gameVars['away']['first']['acc'] = { value: away_vars.away_team_first_acc, color: (away_vars.away_team_first_acc > home_vars.home_team_first_acc ? '#02f3a7' : away_vars.away_team_first_acc == home_vars.home_team_first_acc ? '#FFED58' : 'red')};
              this.gameVars['home']['first']['decc'] = { value: home_vars.home_team_first_decc, color: (home_vars.home_team_first_decc > away_vars.away_team_first_decc ? '#02f3a7' : home_vars.home_team_first_decc == away_vars.away_team_first_decc ? '#FFED58' : 'red')};
              this.gameVars['away']['first']['decc'] = { value: away_vars.away_team_first_decc, color: (away_vars.away_team_first_decc > home_vars.home_team_first_decc ? '#02f3a7' : away_vars.away_team_first_decc == home_vars.home_team_first_decc ? '#FFED58' : 'red')};
              this.gameVars['home']['first']['sprints'] = { value: home_vars.home_team_first_sprints, color: (home_vars.home_team_first_sprints > away_vars.away_team_first_sprints ? '#02f3a7' : home_vars.home_team_first_sprints == away_vars.away_team_first_sprints ? '#FFED58' : 'red')};
              this.gameVars['away']['first']['sprints'] = { value: away_vars.away_team_first_sprints, color: (away_vars.away_team_first_sprints > home_vars.home_team_first_sprints ? '#02f3a7' : away_vars.away_team_first_sprints == home_vars.home_team_first_sprints ? '#FFED58' : 'red')};
              this.gameVars['home']['first']['mts_of_sprint'] = { value: home_vars.home_team_first_mts_of_sprint, color: (home_vars.home_team_first_mts_of_sprint > away_vars.away_team_first_mts_of_sprint ? '#02f3a7' : home_vars.home_team_first_mts_of_sprint == away_vars.away_team_first_mts_of_sprint ? '#FFED58' : 'red')};
              this.gameVars['away']['first']['mts_of_sprint'] = { value: away_vars.away_team_first_mts_of_sprint, color: (away_vars.away_team_first_mts_of_sprint > home_vars.home_team_first_mts_of_sprint ? '#02f3a7' : away_vars.away_team_first_mts_of_sprint == home_vars.home_team_first_mts_of_sprint ? '#FFED58' : 'red')};
              //second half
              this.gameVars['home']['second']['distance'] = { value: home_vars.home_team_second_distance, color: (home_vars.home_team_second_distance > away_vars.away_team_second_distance ? '#02f3a7' : home_vars.home_team_second_distance == away_vars.away_team_second_distance ? '#FFED58' : 'red')};
              this.gameVars['away']['second']['distance'] = { value: away_vars.away_team_second_distance, color: (away_vars.away_team_second_distance > home_vars.home_team_second_distance ? '#02f3a7' : away_vars.away_team_second_distance == home_vars.home_team_second_distance ? '#FFED58' : 'red')};
              this.gameVars['home']['second']['hi_runs'] = { value: home_vars.home_team_second_hi_runs, color: (home_vars.home_team_second_hi_runs > away_vars.away_team_second_hi_runs ? '#02f3a7' : home_vars.home_team_second_hi_runs == away_vars.away_team_second_hi_runs ? '#FFED58' : 'red')};
              this.gameVars['away']['second']['hi_runs'] = { value: away_vars.away_team_second_hi_runs, color: (away_vars.away_team_second_hi_runs > home_vars.home_team_second_hi_runs ? '#02f3a7' : away_vars.away_team_second_hi_runs == home_vars.home_team_second_hi_runs ? '#FFED58' : 'red')};
              this.gameVars['home']['second']['acc'] = { value: home_vars.home_team_second_acc, color: (home_vars.home_team_second_acc > away_vars.away_team_second_acc ? '#02f3a7' : home_vars.home_team_second_acc == away_vars.away_team_second_acc ? '#FFED58' : 'red')};
              this.gameVars['away']['second']['acc'] = { value: away_vars.away_team_second_acc, color: (away_vars.away_team_second_acc > home_vars.home_team_second_acc ? '#02f3a7' : away_vars.away_team_second_acc == home_vars.home_team_second_acc ? '#FFED58' : 'red')};
              this.gameVars['home']['second']['decc'] = { value: home_vars.home_team_second_decc, color: (home_vars.home_team_second_decc > away_vars.away_team_second_decc ? '#02f3a7' : home_vars.home_team_second_decc == away_vars.away_team_second_decc ? '#FFED58' : 'red')};
              this.gameVars['away']['second']['decc'] = { value: away_vars.away_team_second_decc, color: (away_vars.away_team_second_decc > home_vars.home_team_second_decc ? '#02f3a7' : away_vars.away_team_second_decc == home_vars.home_team_second_decc ? '#FFED58' : 'red')};
              this.gameVars['home']['second']['sprints'] = { value: home_vars.home_team_second_sprints, color: (home_vars.home_team_second_sprints > away_vars.away_team_second_sprints ? '#02f3a7' : home_vars.home_team_second_sprints == away_vars.away_team_second_sprints ? '#FFED58' : 'red')};
              this.gameVars['away']['second']['sprints'] = { value: away_vars.away_team_second_sprints, color: (away_vars.away_team_second_sprints > home_vars.home_team_second_sprints ? '#02f3a7' : away_vars.away_team_second_sprints == home_vars.home_team_second_sprints ? '#FFED58' : 'red')};
              this.gameVars['home']['second']['mts_of_sprint'] = { value: home_vars.home_team_second_mts_of_sprint, color: (home_vars.home_team_second_mts_of_sprint > away_vars.away_team_second_mts_of_sprint ? '#02f3a7' : home_vars.home_team_second_mts_of_sprint == away_vars.away_team_second_mts_of_sprint ? '#FFED58' : 'red')};
              this.gameVars['away']['second']['mts_of_sprint'] = { value: away_vars.away_team_second_mts_of_sprint, color: (away_vars.away_team_second_mts_of_sprint > home_vars.home_team_second_mts_of_sprint ? '#02f3a7' : away_vars.away_team_second_mts_of_sprint == home_vars.home_team_second_mts_of_sprint ? '#FFED58' : 'red')};
              //all game
              this.gameVars['home']['all']['distance'] = { value: Number(home_vars.home_team_first_distance) + Number(home_vars.home_team_second_distance), color: (Number(home_vars.home_team_first_distance) + Number(home_vars.home_team_second_distance) > Number(away_vars.away_team_first_distance) + Number(away_vars.away_team_second_distance) ? '#02f3a7' : Number(home_vars.home_team_first_distance) + Number(home_vars.home_team_second_distance) == Number(away_vars.away_team_first_distance) + Number(away_vars.away_team_second_distance) ? '#FFED58' : 'red')};
              this.gameVars['away']['all']['distance'] = { value: Number(away_vars.away_team_first_distance) + Number(away_vars.away_team_second_distance), color: (Number(home_vars.home_team_first_distance) + Number(home_vars.home_team_second_distance) < Number(away_vars.away_team_first_distance) + Number(away_vars.away_team_second_distance) ? '#02f3a7' : Number(home_vars.home_team_first_distance) + Number(home_vars.home_team_second_distance) == Number(away_vars.away_team_first_distance) + Number(away_vars.away_team_second_distance) ? '#FFED58' : 'red')};
              this.gameVars['home']['all']['hi_runs'] = { value: Number(home_vars.home_team_first_hi_runs) + Number(home_vars.home_team_second_hi_runs), color: (Number(home_vars.home_team_first_hi_runs) + Number(home_vars.home_team_second_hi_runs) > Number(away_vars.away_team_first_hi_runs) + Number(away_vars.away_team_second_hi_runs) ? '#02f3a7' : Number(home_vars.home_team_first_hi_runs) + Number(home_vars.home_team_second_hi_runs) == Number(away_vars.away_team_first_hi_runs) + Number(away_vars.away_team_second_hi_runs) ? '#FFED58' : 'red')};
              this.gameVars['away']['all']['hi_runs'] = { value: Number(away_vars.away_team_first_hi_runs) + Number(away_vars.away_team_second_hi_runs), color: (Number(home_vars.home_team_first_hi_runs) + Number(home_vars.home_team_second_hi_runs) < Number(away_vars.away_team_first_hi_runs) + Number(away_vars.away_team_second_hi_runs) ? '#02f3a7' : Number(home_vars.home_team_first_hi_runs) + Number(home_vars.home_team_second_hi_runs) == Number(away_vars.away_team_first_hi_runs) + Number(away_vars.away_team_second_hi_runs) ? '#FFED58' : 'red')};
              this.gameVars['home']['all']['acc'] = { value: Number(home_vars.home_team_first_acc) + Number(home_vars.home_team_second_acc), color: (Number(home_vars.home_team_first_acc) + Number(home_vars.home_team_second_acc) > Number(away_vars.away_team_first_acc) + Number(away_vars.away_team_second_acc) ? '#02f3a7' : Number(home_vars.home_team_first_acc) + Number(home_vars.home_team_second_acc) == Number(away_vars.away_team_first_acc) + Number(away_vars.away_team_second_acc) ? '#FFED58' : 'red')};
              this.gameVars['away']['all']['acc'] = { value: Number(away_vars.away_team_first_acc) + Number(away_vars.away_team_second_acc), color: (Number(home_vars.home_team_first_acc) + Number(home_vars.home_team_second_acc) < Number(away_vars.away_team_first_acc) + Number(away_vars.away_team_second_acc) ? '#02f3a7' : Number(home_vars.home_team_first_acc) + Number(home_vars.home_team_second_acc) == Number(away_vars.away_team_first_acc) + Number(away_vars.away_team_second_acc) ? '#FFED58' : 'red')};
              this.gameVars['home']['all']['decc'] = { value: Number(home_vars.home_team_first_decc) + Number(home_vars.home_team_second_decc), color: (Number(home_vars.home_team_first_decc) + Number(home_vars.home_team_second_decc) > Number(away_vars.away_team_first_decc) + Number(away_vars.away_team_second_decc) ? '#02f3a7' : Number(away_vars.away_team_first_decc) + Number(away_vars.away_team_second_decc) == Number(home_vars.home_team_first_decc) + Number(home_vars.home_team_second_decc) ? '#FFED58' : 'red')};
              this.gameVars['away']['all']['decc'] = { value: Number(away_vars.away_team_first_decc) + Number(away_vars.away_team_second_decc), color: (Number(home_vars.home_team_first_decc) + Number(home_vars.home_team_second_decc) < Number(away_vars.away_team_first_decc) + Number(away_vars.away_team_second_decc) ? '#02f3a7' : Number(away_vars.away_team_first_decc) + Number(away_vars.away_team_second_decc) == Number(home_vars.home_team_first_decc) + Number(home_vars.home_team_second_decc) ? '#FFED58' : 'red')};
              this.gameVars['home']['all']['sprints'] = { value: Number(home_vars.home_team_first_sprints) + Number(home_vars.home_team_second_sprints), color: (Number(home_vars.home_team_first_sprints) + Number(home_vars.home_team_second_sprints) > Number(away_vars.away_team_first_sprints) + Number(away_vars.away_team_second_sprints) ? '#02f3a7' : Number(away_vars.away_team_first_sprints) + Number(away_vars.away_team_second_sprints) == Number(home_vars.home_team_first_sprints) + Number(home_vars.home_team_second_sprints) ? '#FFED58' : 'red')};
              this.gameVars['away']['all']['sprints'] = { value: Number(away_vars.away_team_first_sprints) + Number(away_vars.away_team_second_sprints), color: (Number(home_vars.home_team_first_sprints) + Number(home_vars.home_team_second_sprints) < Number(away_vars.away_team_first_sprints) + Number(away_vars.away_team_second_sprints) ? '#02f3a7' : Number(away_vars.away_team_first_sprints) + Number(away_vars.away_team_second_sprints) == Number(home_vars.home_team_first_sprints) + Number(home_vars.home_team_second_sprints) ? '#FFED58' : 'red')};
              this.gameVars['home']['all']['mts_of_sprint'] = { value: Number(home_vars.home_team_first_mts_of_sprint) + Number(home_vars.home_team_second_mts_of_sprint), color: (Number(home_vars.home_team_first_mts_of_sprint) + Number(home_vars.home_team_second_mts_of_sprint) > Number(away_vars.away_team_first_mts_of_sprint) + Number(away_vars.away_team_second_mts_of_sprint) ? '#02f3a7' : Number(away_vars.away_team_first_mts_of_sprint) + Number(away_vars.away_team_second_mts_of_sprint) == Number(home_vars.home_team_first_mts_of_sprint) + Number(home_vars.home_team_second_mts_of_sprint) ? '#FFED58' : 'red')};
              this.gameVars['away']['all']['mts_of_sprint'] = { value: Number(away_vars.away_team_first_mts_of_sprint) + Number(away_vars.away_team_second_mts_of_sprint), color: (Number(home_vars.home_team_first_mts_of_sprint) + Number(home_vars.home_team_second_mts_of_sprint) < Number(away_vars.away_team_first_mts_of_sprint) + Number(away_vars.away_team_second_mts_of_sprint) ? '#02f3a7' : Number(away_vars.away_team_first_mts_of_sprint) + Number(away_vars.away_team_second_mts_of_sprint) == Number(home_vars.home_team_first_mts_of_sprint) + Number(home_vars.home_team_second_mts_of_sprint) ? '#FFED58' : 'red')};
            }
          } else {
            if(this.from == "games"){
              this.gameVars['time'] = "-";
              this.gameVars['rpe'] = "-";
              //first half
              this.gameVars['home']['first']['distance'] = { value: "-", color: "white"};
              this.gameVars['away']['first']['distance'] = { value: "-", color: "white"};
              this.gameVars['home']['first']['hi_runs'] = { value: "-", color: "white"};
              this.gameVars['away']['first']['hi_runs'] = { value: "-", color: "white"};
              this.gameVars['home']['first']['acc'] = { value: "-", color: "white"};
              this.gameVars['away']['first']['acc'] = { value: "-", color: "white"};
              this.gameVars['home']['first']['decc'] = { value: "-", color: "white"};
              this.gameVars['away']['first']['decc'] = { value: "-", color: "white"};
              this.gameVars['home']['first']['sprints'] = { value: "-", color: "white"};
              this.gameVars['away']['first']['sprints'] = { value: "-", color: "white"};
              this.gameVars['home']['first']['mts_of_sprint'] = { value: "-", color: "white"};
              this.gameVars['away']['first']['mts_of_sprint'] = { value: "-", color: "white"};
              //second half
              this.gameVars['home']['second']['distance'] = { value: "-", color: "white"};
              this.gameVars['away']['second']['distance'] = { value: "-", color: "white"};
              this.gameVars['home']['second']['hi_runs'] = { value: "-", color: "white"};
              this.gameVars['away']['second']['hi_runs'] = { value: "-", color: "white"};
              this.gameVars['home']['second']['acc'] = { value: "-", color: "white"};
              this.gameVars['away']['second']['acc'] = { value: "-", color: "white"};
              this.gameVars['home']['second']['decc'] = { value: "-", color: "white"};
              this.gameVars['away']['second']['decc'] = { value: "-", color: "white"};
              this.gameVars['home']['second']['sprints'] = { value: "-", color: "white"};
              this.gameVars['away']['second']['sprints'] = { value: "-", color: "white"};
              this.gameVars['home']['second']['mts_of_sprint'] = { value: "-", color: "white"};
              this.gameVars['away']['second']['mts_of_sprint'] = { value: "-", color: "white"};
            }
          }
          this.loading = false;
        });
      });
    });
  }
checkPitch(){
  if(Object.keys(this.homeTeam.lines).length > 0 && Object.keys(this.awayTeam.lines).length > 0){
    return true;

  }
  return false;
}
  processGame(){
    if(this.homeTeam.lineUp.hasOwnProperty('squad')){
      let homeTeamCopy = Object.assign([], this.homeTeam.lineUp.squad);
      this.homeTeam['lines'] = [];

      if(this.homeTeam.lineUp.formation !== null){
        this.homeTeam['lines'].push([homeTeamCopy[0]]);
        homeTeamCopy.shift();
      let formation_home = this.homeTeam.lineUp.formation.split("-");
      for (let it = 0; it < formation_home.length; it++) {
        const formation_line = Number(formation_home[it]);
        let line = []; 
        for (let it2 = 0; it2 < formation_line; it2++) {
          const player = homeTeamCopy[it2];
          line.unshift(player);
        }
        this.homeTeam['lines'].push(line);
        for (let it2 = 0; it2 < formation_line; it2++) {
          homeTeamCopy.shift();
        }
      }
    }
    console.log(this.homeTeam['lines']);
    }

    if(this.awayTeam.lineUp.hasOwnProperty('squad')){
      let awayTeamCopy = Object.assign([], this.awayTeam.lineUp.squad);
      this.awayTeam['lines'] = [];
      if(this.awayTeam.lineUp.formation !== null){
        
        this.awayTeam['lines'].push([awayTeamCopy[0]]);
        awayTeamCopy.shift();
      let formation_away = this.awayTeam.lineUp.formation.split("-");
      for (let it = 0; it < formation_away.length; it++) {
        const formation_line = Number(formation_away[it]);
        let line = []; 
        for (let it2 = 0; it2 < formation_line; it2++) {
          const player = awayTeamCopy[it2];
          line.push(player);
        }
        this.awayTeam['lines'].push(line);
        for (let it2 = 0; it2 < formation_line; it2++) {
          awayTeamCopy.shift();
        }
      }
      this.awayTeam['lines'].reverse();
    }
   
  }
  }

  close(){ 
    this.dialogRef.close({ action: 'close' });
  }

  getIconFromType(type){
    if(type == 'goal'){
      return "far fa-futbol";
    } 
    else if(type == 'substitution'){
      return "fas fa-exchange-alt blue";
    } 
    else if(type == 'yellowcard'){
      return "card yellow_bg"
    } 
    else if(type == 'redcard'){
      return "card red_bg";
    }
  }

  submitGameVars(){
    let fields = [
      this.time,
      this.rpe,
      this.home_team_first_distance,
      this.home_team_first_hi_runs,
      this.home_team_first_acc,
      this.home_team_first_decc,
      this.home_team_first_sprints,
      this.home_team_first_mts_of_sprint,
      this.home_team_second_distance,
      this.home_team_second_hi_runs,
      this.home_team_second_acc,
      this.home_team_second_decc,
      this.home_team_second_sprints,
      this.home_team_second_mts_of_sprint,
      this.away_team_first_distance,
      this.away_team_first_hi_runs,
      this.away_team_first_acc,
      this.away_team_first_decc,
      this.away_team_first_sprints,
      this.away_team_first_mts_of_sprint,
      this.away_team_second_distance,
      this.away_team_second_hi_runs,
      this.away_team_second_acc,
      this.away_team_second_decc,
      this.away_team_second_sprints,
      this.away_team_second_mts_of_sprint
    ];
    let hasError = false;
    for (let it = 0; it < fields.length; it++) {
      const field = fields[it];
      if(field.value.trim() == ""){
        hasError = true;
        break;
      }
    }
    if(hasError){
      this.toastrService.addToastr("Fill all the fields", "error");
      return;
    }
    if(this.rpe.value > 10 || this.rpe.value < 0){
      this.toastrService.addToastr("RPE must be a number between 0 and 10", "error");
      return;
    }
    if(this.time.value <90){
      this.toastrService.addToastr("Game time must be a number equal or greater than 90", "error");
      return;
    }
    var fd = new FormData();
    fd.append('game_id', this.game.id);
    fd.append('project', this.dataService.selected_project.id);
    fd.append('home_team_id', this.homeTeam.team_id);
    var home = {
      id:this.homeTeam.team_id,
      home_team_first_distance: this.home_team_first_distance.value,
      home_team_first_hi_runs: this.home_team_first_hi_runs.value,
      home_team_first_acc: this.home_team_first_acc.value,
      home_team_first_decc: this.home_team_first_decc.value,
      home_team_first_sprints: this.home_team_first_sprints.value,
      home_team_first_mts_of_sprint: this.home_team_first_mts_of_sprint.value,
      home_team_second_distance: this.home_team_second_distance.value,
      home_team_second_hi_runs: this.home_team_second_hi_runs.value,
      home_team_second_acc: this.home_team_second_acc.value,
      home_team_second_decc: this.home_team_second_decc.value,
      home_team_second_sprints: this.home_team_second_sprints.value,
      home_team_second_mts_of_sprint: this.home_team_second_mts_of_sprint.value
    };
    fd.append('home_vars', JSON.stringify(home));
    fd.append('away_team_id', this.awayTeam.team_id);
    var away = {
      id:this.awayTeam.team_id,
      away_team_first_distance: this.away_team_first_distance.value,
      away_team_first_hi_runs: this.away_team_first_hi_runs.value,
      away_team_first_acc: this.away_team_first_acc.value,
      away_team_first_decc: this.away_team_first_decc.value,
      away_team_first_sprints: this.away_team_first_sprints.value,
      away_team_first_mts_of_sprint: this.away_team_first_mts_of_sprint.value,
      away_team_second_distance: this.away_team_second_distance.value,
      away_team_second_hi_runs: this.away_team_second_hi_runs.value,
      away_team_second_acc: this.away_team_second_acc.value,
      away_team_second_decc: this.away_team_second_decc.value,
      away_team_second_sprints: this.away_team_second_sprints.value,
      away_team_second_mts_of_sprint: this.away_team_second_mts_of_sprint.value
    };
    fd.append('away_vars', JSON.stringify(away));
    fd.append('season_id', this.season);
    fd.append('time', this.time.value);
    fd.append('rpe', this.rpe.value);
    fd.append('date', this.game.time.date);
    this.gameService.setGameVars(fd).subscribe(data=>{
      if(data == 'success'){
        this.toastrService.addToastr("Variables successfully submitted", "success");
      }
      else {
        this.toastrService.addToastr("Error while saving game stats", "error");
      }
    });
  }

  keepOrder = (a, b) => {
    return a;
  }

  // GETTERS
  get time(){
    return this.gameForm.get('time');
  }

  get rpe(){
    return this.gameForm.get('rpe');
  }

  get home_team_first_distance(){
    return this.gameForm.get('home_team_first_distance');
  }

  get home_team_first_hi_runs(){
    return this.gameForm.get('home_team_first_hi_runs');
  }

  get home_team_first_acc(){
    return this.gameForm.get('home_team_first_acc');
  }

  get home_team_first_decc(){
    return this.gameForm.get('home_team_first_decc');
  }

  get home_team_first_sprints(){
    return this.gameForm.get('home_team_first_sprints');
  }

  get home_team_first_mts_of_sprint(){
    return this.gameForm.get('home_team_first_mts_of_sprint');
  }

  get home_team_second_distance(){
    return this.gameForm.get('home_team_second_distance');
  }

  get home_team_second_hi_runs(){
    return this.gameForm.get('home_team_second_hi_runs');
  }

  get home_team_second_acc(){
    return this.gameForm.get('home_team_second_acc');
  }

  get home_team_second_decc(){
    return this.gameForm.get('home_team_second_decc');
  }

  get home_team_second_sprints(){
    return this.gameForm.get('home_team_second_sprints');
  }

  get home_team_second_mts_of_sprint(){
    return this.gameForm.get('home_team_second_mts_of_sprint');
  }

  get away_team_first_distance(){
    return this.gameForm.get('away_team_first_distance');
  }

  get away_team_first_hi_runs(){
    return this.gameForm.get('away_team_first_hi_runs');
  }

  get away_team_first_acc(){
    return this.gameForm.get('away_team_first_acc');
  }

  get away_team_first_decc(){
    return this.gameForm.get('away_team_first_decc');
  }

  get away_team_first_sprints(){
    return this.gameForm.get('away_team_first_sprints');
  }

  get away_team_first_mts_of_sprint(){
    return this.gameForm.get('away_team_first_mts_of_sprint');
  }

  get away_team_second_distance(){
    return this.gameForm.get('away_team_second_distance');
  }

  get away_team_second_hi_runs(){
    return this.gameForm.get('away_team_second_hi_runs');
  }

  get away_team_second_acc(){
    return this.gameForm.get('away_team_second_acc');
  }

  get away_team_second_decc(){
    return this.gameForm.get('away_team_second_decc');
  }

  get away_team_second_sprints(){
    return this.gameForm.get('away_team_second_sprints');
  }

  get away_team_second_mts_of_sprint(){
    return this.gameForm.get('away_team_second_mts_of_sprint');
  }

}
