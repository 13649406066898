import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../services/data.service';
import { GameService } from '../../services/game.service';
import { ProjectsService } from '../../services/projects.service';
import { ToastrService } from '../../services/toastr.service';
import { GameComponent } from '../../modals/game/game.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ModalServiceService } from '../../services/modal-service.service';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit {

  constructor(public dataService: DataService,
              public gameService: GameService,
              public projectsService: ProjectsService,
              public toastrService: ToastrService,
              public gameModalComponent: MatDialog,
              public modalService: ModalServiceService,
              public sanitizer: DomSanitizer,
              public loadingService: LoadingService
              ) { }
  loading = true;
  selected_season = null;
  gameModal: MatDialogRef<GameComponent>;
  @Input() season: any;

  ngOnInit() {
    this.loadingService.loading = true;
    this.selected_season = this.dataService.selected_project.seasons[this.season];
    this.getGamesForSeason(this.selected_season);
  }

  ngOnChanges(changes) {  
    this.loading = true;
    this.loadingService.loading = true;     
    this.selected_season = this.dataService.selected_project.seasons[this.season];
    this.getGamesForSeason(this.selected_season);
  }

  getGamesForSeason(season){
      var fd = new FormData();
      fd.append('season', season.id);
      fd.append('team', this.dataService.selected_project.project_team_id);
      this.gameService.getGameVarsFromSeasonAndTeam(fd).subscribe(data=>{
        Object.keys(data).forEach((key) => {
          let stat = data[key];
          for (let it = 0; it < season.games.length; it++) {
            const game = season.games[it];
            if(game.id == stat.game_id){
              game.gps_vars = data[key];
              break;
            }
          }
        });
        this.loadingService.loading = false;
        this.loading = false;
      });
  }

  getColorForResult(game){
    let my_team_id = this.dataService.selected_project.project_team_id;
    let home_team_id = game.teams.home.id;
    let away_team_id = game.teams.away.id;
    let home_score = game.scores.home_score;
    let away_score = game.scores.away_score;
    if(home_score == null || away_score == null){
      return 'adiado';
    }
    if(home_score == away_score){
      return 'draw';
    }
    if(my_team_id == home_team_id){
      if(home_score > away_score){
        return 'winner';
      } else {
        return 'loser';
      }
    }
    if(my_team_id == away_team_id){
      if(away_score > home_score){
        return 'winner';
      } else {
        return 'loser';
      } 
    }
    return '';
  }

  checkIfBigger(treshold_value, game, games, array){
    let hasBigger = false;
    for (let it = 0; it < games.length; it++) {
      const temp_game = games[it];
      if(game.id !== temp_game.id){
        let vars;
        let keys;
        if(temp_game.hasOwnProperty('gps_vars')){
          if(temp_game.gps_vars.home_team_id == this.dataService.selected_project.project_team_id){
            vars = JSON.parse(temp_game.gps_vars.home_team_vars);
            keys = [0,1];
          } else {
            vars = JSON.parse(temp_game.gps_vars.away_team_vars);
            keys = [2,3];
          }
          let value = Number(vars[array[keys[0]]]) + Number(vars[array[keys[1]]]);
          if(value > treshold_value){
            hasBigger = true; 
            break;
          }
        } else{
          hasBigger = false; 
        }
      }
    }
    return hasBigger;
  }

  getGpsVarForProperty(game, games, property){
    let gps_vars = game.gps_vars;
    let myTeamvars = ""; 
    if( gps_vars.home_team_id == this.dataService.selected_project.project_team_id){
      myTeamvars = JSON.parse(gps_vars.home_team_vars);
      if(property == 'distance'){
        let distance_value = Number(myTeamvars['home_team_first_distance']) + Number(myTeamvars['home_team_second_distance']);
        let checkIfIsBigger = this.checkIfBigger(distance_value, game, games, ['home_team_first_distance', 'home_team_second_distance', 'away_team_first_distance', 'away_team_second_distance']);
        return "<span style='"+(!checkIfIsBigger ? 'color:#02f3a7' : '')+"'>"+distance_value+"</span>";
      }
      if(property == 'hi_runs'){
        let distance_value = Number(myTeamvars['home_team_first_hi_runs']) + Number(myTeamvars['home_team_second_hi_runs']);
        let checkIfIsBigger = this.checkIfBigger(distance_value, game, games, ['home_team_first_hi_runs', 'home_team_second_hi_runs', 'away_team_first_hi_runs', 'away_team_second_hi_runs']);
        return "<span style='"+(!checkIfIsBigger ? 'color:#02f3a7' : '')+"'>"+distance_value+"</span>";
      }
      if(property == 'acc'){
        let distance_value = Number(myTeamvars['home_team_first_acc']) + Number(myTeamvars['home_team_second_acc']);
        let checkIfIsBigger = this.checkIfBigger(distance_value, game, games, ['home_team_first_acc', 'home_team_second_acc', 'away_team_first_acc', 'away_team_second_acc']);
        return "<span style='"+(!checkIfIsBigger ? 'color:#02f3a7' : '')+"'>"+distance_value+"</span>";
      }
      if(property == 'decc'){
        let distance_value = Number(myTeamvars['home_team_first_decc']) + Number(myTeamvars['home_team_second_decc']);
        let checkIfIsBigger = this.checkIfBigger(distance_value, game, games, ['home_team_first_decc', 'home_team_second_decc', 'away_team_first_decc', 'away_team_second_decc']);
        return "<span style='"+(!checkIfIsBigger ? 'color:#02f3a7' : '')+"'>"+distance_value+"</span>";
      }
      if(property == 'sprints'){
        let distance_value = Number(myTeamvars['home_team_first_sprints']) + Number(myTeamvars['home_team_second_sprints']);
        let checkIfIsBigger = this.checkIfBigger(distance_value, game, games, ['home_team_first_sprints', 'home_team_second_sprints', 'away_team_first_sprints', 'away_team_second_sprints']);
        return "<span style='"+(!checkIfIsBigger ? 'color:#02f3a7' : '')+"'>"+distance_value+"</span>";
      }
      if(property == 'mts_of_sprint'){
        let distance_value = Number(myTeamvars['home_team_first_mts_of_sprint']) + Number(myTeamvars['home_team_second_mts_of_sprint']);
        let checkIfIsBigger = this.checkIfBigger(distance_value, game, games, ['home_team_first_mts_of_sprint', 'home_team_second_mts_of_sprint', 'away_team_first_mts_of_sprint', 'away_team_second_mts_of_sprint']);
        return "<span style='"+(!checkIfIsBigger ? 'color:#02f3a7' : '')+"'>"+distance_value+"</span>";
      }
    } else {
      myTeamvars = JSON.parse(gps_vars.away_team_vars);
      if(property == 'distance'){
        let distance_value = Number(myTeamvars['away_team_first_distance']) + Number(myTeamvars['away_team_second_distance']);
        let checkIfIsBigger = this.checkIfBigger(distance_value, game, games, ['home_team_first_distance', 'home_team_second_distance', 'away_team_first_distance', 'away_team_second_distance']);
        return "<span style='"+(!checkIfIsBigger ? 'color:#02f3a7' : '')+"'>"+distance_value+"</span>";
      }
      if(property == 'hi_runs'){
        let distance_value = Number(myTeamvars['away_team_first_hi_runs']) + Number(myTeamvars['away_team_second_hi_runs']);
        let checkIfIsBigger = this.checkIfBigger(distance_value, game, games, ['home_team_first_hi_runs', 'home_team_second_hi_runs', 'away_team_first_hi_runs', 'away_team_second_hi_runs']);
        return "<span style='"+(!checkIfIsBigger ? 'color:#02f3a7' : '')+"'>"+distance_value+"</span>";
      }
      if(property == 'acc'){
        let distance_value = Number(myTeamvars['away_team_first_acc']) + Number(myTeamvars['away_team_second_acc']);
        let checkIfIsBigger = this.checkIfBigger(distance_value, game, games, ['home_team_first_acc', 'home_team_second_acc', 'away_team_first_acc', 'away_team_second_acc']);
        return "<span style='"+(!checkIfIsBigger ? 'color:#02f3a7' : '')+"'>"+distance_value+"</span>";
      }
      if(property == 'decc'){
        let distance_value = Number(myTeamvars['away_team_first_decc']) + Number(myTeamvars['away_team_second_decc']);
        let checkIfIsBigger = this.checkIfBigger(distance_value, game, games, ['home_team_first_decc', 'home_team_second_decc', 'away_team_first_decc', 'away_team_second_decc']);
        return "<span style='"+(!checkIfIsBigger ? 'color:#02f3a7' : '')+"'>"+distance_value+"</span>";
      }
      if(property == 'sprints'){
        let distance_value = Number(myTeamvars['away_team_first_sprints']) + Number(myTeamvars['away_team_second_sprints']);
        let checkIfIsBigger = this.checkIfBigger(distance_value, game, games, ['home_team_first_sprints', 'home_team_second_sprints', 'away_team_first_sprints', 'away_team_second_sprints']);
        return "<span style='"+(!checkIfIsBigger ? 'color:#02f3a7' : '')+"'>"+distance_value+"</span>";
      }
      if(property == 'mts_of_sprint'){
        let distance_value = Number(myTeamvars['away_team_first_mts_of_sprint']) + Number(myTeamvars['away_team_second_mts_of_sprint']);
        let checkIfIsBigger = this.checkIfBigger(distance_value, game, games, ['home_team_first_mts_of_sprint', 'home_team_second_mts_of_sprint', 'away_team_first_mts_of_sprint', 'away_team_second_mts_of_sprint']);
        return "<span style='"+(!checkIfIsBigger ? 'color:#02f3a7' : '')+"'>"+distance_value+"</span>";
      }
    }
    return "";
  }

  openGame(game){
    this.gameModal = this.gameModalComponent.open(GameComponent, { disableClose: true });
    this.modalService.modalOpen = true;
    let instance = this.gameModal.componentInstance;
    instance.game = game;
    instance.season = this.selected_season.id;
    instance.from = "games";
    this.gameModal.afterClosed().subscribe((result) => {
      this.modalService.modalOpen = false;
      this.getGamesForSeason(this.selected_season);
    });
  }
}
