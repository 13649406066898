import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class StandingsService {

  constructor(private http: HttpClient) { }

  getTeamStandings(season_id){
    let res = this.http.get("https://api.soccersapi.com/v2.2/leagues/?user=nuno&token=6ef325183ef9cb2b665b758d21fa861d&t=standings&season_id="+season_id.toString());
     console.log(res);
     return res;
  }

  getTeamImage(team_id, size){
    return "https://cdn.soccersapi.com/images/soccer/teams/"+size.toString()+"/"+team_id.toString()+".png";
  }
}
