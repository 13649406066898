import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from '../../services/toastr.service';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
  user = '';
  pass = '';
  constructor(
    public authService: AuthService,
    public toastrService: ToastrService,
    public dialogRef: MatDialogRef<LoginComponent>) { }

  ngOnInit() {
  }
  
  action(action){
    this.dialogRef.close();
  }

  tryLogin(){
    let fd = new FormData();
    if(this.user.trim() == ""){
      this.toastrService.addToastr('Preencha o username', 'error');
      return;
    }
    if(this.pass.trim() == ""){
      this.toastrService.addToastr('Preencha o password', 'error');
      return;
    }
    fd.append('user', this.user);
    fd.append('pass', this.pass);
    this.authService.tryLogin(fd).subscribe(data=>{
      if(data['answer'] == "error"){
        this.toastrService.addToastr(data['message'], data['answer']);
      } else {
        this.authService.updateUser(data['user']);
        this.dialogRef.close();
      }
    });
  }
  
}


